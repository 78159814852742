import React from "react"

interface IOwnProps {
  onClick: () => void,
  additionalClassName: string,
  imgSource: string | null,
  imgClassName: string,
  additionalButtonLabelClassName?: string,
  buttonText: string,
}

const IconButton: React.FC<IOwnProps> = (props) => {

  const { onClick, additionalClassName, imgSource, imgClassName, additionalButtonLabelClassName,
    buttonText } = props

  return (
    <div
      data-testid={buttonText}
      className={`${additionalClassName} button imageText`}
      onClick={onClick}
    >
      {imgSource !== null ? <img className={imgClassName} src={imgSource} alt="Icon" /> : null}
      <p className={additionalButtonLabelClassName}>{buttonText}</p>
    </div>
  )
}
export default IconButton
