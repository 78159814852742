import actionCreatorFactory from "typescript-fsa"

import { IGarment, IGarmentShort } from "./GarmentModels"

const actionCreator = actionCreatorFactory("Washing")

export const addToWash = actionCreator<{garment: IGarment & IGarmentShort}>("ADD_TO_WASH")

export const removeFromWash = actionCreator<{garment: IGarment & IGarmentShort}>("REMOVE_FROM_WASH")

export const cancelWash = actionCreator<void>("CANCEL_WASH")
