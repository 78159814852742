import React from "react"

import { ActionTypes } from "../../reducers/PassItOnReducers"

import BackButton from "../BackButton"
import SettingsItem from "./SettingsItem"

interface IOwnProps {
  history: any,
}

const SettingsDonate: React.FC<IOwnProps> = (props) => {

  const { history } = props

  const onDonations = () => {
    history.push("/settings/donateList", { type: ActionTypes.donate })
  }

  const onCharities = () => {
    history.push("/settings/charities", { type: ActionTypes.donate })
  }

  return (
    <div style={{ flex: 1, flexDirection: "column" }}>

      <BackButton goBackAction={history.goBack} additionalClassName="backButtonContainer" />

      <p className="section-upcase quiet">DONATIONS</p>
      <div style={{ flex: 1, flexDirection: "column" }}>
        <SettingsItem
          title="Your donations"
          text={undefined}
          onClick={onDonations}
          extraText={undefined}
          extraLinkText={undefined}
        />

        <SettingsItem
          title="Your charities"
          text={undefined}
          onClick={onCharities}
          extraText={undefined}
          extraLinkText={undefined}
        />
      </div>

    </div>
  )
}

export default SettingsDonate
