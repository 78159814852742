import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"
import { withRouter } from "react-router"


import "./styles/SettingsBasicInfoTop.css"

import { RootState } from "../../reducers"
import { IUser } from "../../reducers/UsersModels"
import { logout } from "../../reducers/UsersActions"
import { IGarment } from "../../reducers/GarmentModels"
import Loading from "../Loading"

interface IOwnProps {
  history: any,
  user: IUser,
}

interface IStateProps {
  closet: IGarment[],
  isLoadingLogout: boolean,
}

interface IDispatchProps {
  onLogout: () => void,
}

const SettingsBasicInfoTop: React.FC<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const { user, onLogout, closet, isLoadingLogout } = props

  useEffect(() => {
    if (!closet || (closet && closet.length === 0) ) {
      window.location.href = "https://backend.cacotec.com/mw/logout"
    }
  }, [closet])

  const { name, email, photo } = user

  return (
    <div className="gradientGreen settingsBasicInfoTopContainer">

      {isLoadingLogout && (
        <Loading text="" small={true} />
      )}

      {!isLoadingLogout &&
        <>
          <div
            onClick={onLogout}
            className="button logout"
          >
            <img src="/images/icons/logout.svg" alt="Logout" />
          </div>

          <img className="userPhoto" src={photo} alt="User" data-testid="settingsScreenUserPhoto" />
          <p className="H2" data-testid="settingsScreenUserName">{name}</p>
          <p className="small" data-testid="settingsScreenUserEmail">{email}</p>
        </>
      }

    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  closet: state.closet.closetContent,
  isLoadingLogout: state.user.isLoadingLogout,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  { onLogout: () => dispatch(logout()) }
)

const wr = withRouter(SettingsBasicInfoTop as any)

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(wr as any) as any
