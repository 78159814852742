import React from "react"
import { IPhoto } from "../reducers/GarmentModels"


interface IOwnProps {
  onClick?: () => void,
  photo?: string,
  photos?: IPhoto[],
  additionalClassName: string,
  additionalPhotoClassName: string
}

const GarmentPhoto: React.FC<IOwnProps> = (props) => {

  const {
    onClick, photo, additionalClassName, photos, additionalPhotoClassName,
  } = props

  let photoSrc = null
  let onClickEvent

  if (photos && photos.length > 0 && photos[0].url) {
    photoSrc = photos[0].url
    onClickEvent = onClick
  } else if (photo) {
    photoSrc = photo
  } else {
    photoSrc = "/images/icons/garment-placeholder.svg"
  }

  return (
    <div className={additionalClassName} onClick={onClickEvent}>
      <img className={additionalPhotoClassName} src={photoSrc} alt="Garment" data-testid="garmentPhoto" />
    </div>
  )
}
export default GarmentPhoto
