import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import "./styles/Notification.css"

import { RootState } from "../reducers"
import { clearNotification } from "../reducers/NotificationActions"
import useSwipe from "../utils/useSwipe"

interface IStateProps {
  notificationTitle?: string,
  notificationBody?: string,
}

interface IDispatchProps {
  onClearNotification: () => void,
}


const Notification: React.FC<IStateProps & IDispatchProps> = (props) => {

  const { notificationTitle, notificationBody, onClearNotification } = props

  const timer = setTimeout(() => onClearNotification(), 3000)
  const [moveX, setMoveX] = useState(0)
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    return () => {
      clearTimeout(timer)
    }
  }, [timer])

  const swipe = (swipeDirection: any, distX: any, opacityValue: any) => {
    setMoveX(distX)
    setOpacity(opacityValue)
    if (swipeDirection === "left" || swipeDirection === "right") {
      onClearNotification()
    }
  }


  return (
    <div
      data-testid="notification"
      style={
        ! notificationTitle
        ? {opacity: 0, zIndex: 1}
        : opacity === -1 ? {} : { transform: `translate(${moveX}px)`, opacity }
      }
      {...useSwipe(swipe)}
      className={`notificationContainer ${opacity === -1 ? "swipeBackNotification" : ""}`}
    >
      <div className="gradientGreen smallGradientLine" />
      <div className="notificationMainView">
        <img src="/images/icons/info-notification.svg" alt="Notification" />
        <div className="notificationText">
          <p className="notificationTitle" data-testid="notificationTitle">{notificationTitle}</p>
          <p className="body quiet" data-testid="notificationText">{notificationBody}</p>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  notificationTitle: state.notification.notificationTitle,
  notificationBody: state.notification.notificationBody,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onClearNotification: () => dispatch(clearNotification()),
  }
)

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Notification) as any

