import React, { useState } from "react"

import "../styles/GarmentBasicInfo.css"

import { IGarment } from "../../../reducers/GarmentModels"
import IconButton from "../../IconButton"
import GarmentPhoto from "../../GarmentPhoto"

import useSwipe from "../../../utils/useSwipe"


interface IOwnProps {
  garment: IGarment,
  toggleWashFlag: () => void,
  setSwiper: (swiper: boolean) => void,
}

const GarmentBasicInfoPreview: React.FC<IOwnProps> = (props) => {


  const { garment, toggleWashFlag, setSwiper } = props

  const { brand_name, name, localFlags, photos, base_photo } = garment

  let addedToWash = false
  if (localFlags) {
    ({ addedToWash } = localFlags)
  }


  const [moveX, setMoveX] = useState(0)
  const [opacity, setOpacity] = useState(1)

  const swipeMove = (swipeDirection: any, distX: any, opacityChanged: any) => {
    setMoveX(distX)
    if (opacityChanged !== -1) {
      setOpacity(opacityChanged)
    } else {
      setOpacity(1)
    }
    if (swipeDirection === "left") {
      setSwiper(true)
    }
  }

  return (
    <div
      data-testid="garmentBasicInfoPreview"
      style={{
        flexDirection: "row", flex: 1, height: "100%", transform: `translate(${moveX}px)`, opacity,
      }}
      {...useSwipe(swipeMove)}
    >
      <div className="nameAndBrandContainer">
        <p className="title" data-testid="garmentName">{name}</p>
        <p className="byline" data-testid="garmentBrandName">{brand_name}</p>

        <IconButton
          onClick={() => toggleWashFlag()}
          additionalClassName="garmentToggleWashButton"
          imgClassName="icon32"
          imgSource={! addedToWash ? "/images/icons/wash.svg" : null}
          additionalButtonLabelClassName={
            `${addedToWash ? "buttonLabel alt danger" : "buttonLabel alt"} shareWashButtonPadding`}
          buttonText={addedToWash ? "REMOVE" : "ADD TO WASH"}
        />

      </div>

      <GarmentPhoto
        onClick={() => setSwiper(true)}
        photos={photos}
        photo={base_photo}
        additionalClassName="garmentPhotoContainer"
        additionalPhotoClassName="garmentPhoto"
      />

    </div >
  )
}

export default GarmentBasicInfoPreview
