import React from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import { RootState } from "../../reducers"
import { ICharity } from "../../reducers/PassItOnModels"
import { selectCharity } from "../../reducers/PassItOnActions"

import BackButton from "../BackButton"
import SettingsCharitiesListItem from "./SettingsCharitiesListItem"

interface IOwnProps {
  history: any,
}

interface IStateProps {
  donateOptions: any,
  selectedCharityId: number,
}

interface IDispatchProps {
  onSelectCharity: (selectedCharityId: number) => void
}

const SettingsCharitiesList: React.FC<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const { history, donateOptions, selectedCharityId, onSelectCharity } = props

  return (
    <div style={{ flex: 1, flexDirection: "column" }}>

      <BackButton goBackAction={history.goBack} additionalClassName="backButtonContainer" />

      <p className="section-upcase quiet">YOUR DONATION ORGANIZATIONS</p>

      {donateOptions.length > 0 && donateOptions.map((addressObject: ICharity, index: number) => {
        return (
          <SettingsCharitiesListItem
            key={index}
            charityId={index}
            charityName={addressObject.name}
            selectedCharityId={selectedCharityId}
            onSelectCharity={onSelectCharity}
          />
        )
      })}

    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  donateOptions: state.passItOn.donateOptions,
  selectedCharityId: state.passItOn.selectedCharityId,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  { onSelectCharity: (selectedCharityId: number) => dispatch(selectCharity({ selectedCharityId })) }
)

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SettingsCharitiesList) as any


