import { Action } from "redux"
import { isType } from "typescript-fsa"

import { getReview, clearReview, saveReview } from "./ReviewActions"
import { IReviewToDo } from "./ReviewModels"

export interface IReviewState {
  getReviewFetching: boolean,
  getReviewError: boolean,
  reviewToDo?: IReviewToDo,
  answersToSave: any,
  saveReviewFetching: boolean,
  saveReviewError: boolean,
  saveReviewSuccessMessage?: string,
}

export const initialState: IReviewState = {
  getReviewFetching: false,
  getReviewError: false,
  reviewToDo: undefined,
  answersToSave: undefined,
  saveReviewFetching: false,
  saveReviewError: false,
  saveReviewSuccessMessage: undefined,
}


export default (state: IReviewState = initialState, action: Action): IReviewState => {


  if (isType(action, getReview.started)) {

    return {
      ...state,
      getReviewFetching: true,
      reviewToDo: undefined,
      getReviewError: false,
    }
  }

  if (isType(action, getReview.done as any)) {

    // @ts-ignore
    const { reviewToDo } = action.payload.result

    if (! reviewToDo) {
      return {
        ...state,
        getReviewFetching: false,
      }
    }

    return {
      ...state,
      getReviewFetching: false,
      reviewToDo,
      getReviewError: false,
    }
  }

  if (isType(action, getReview.failed as any)) {

    return {
      ...state,
      getReviewFetching: false,
      reviewToDo: undefined,
      getReviewError: true,
    }
  }

  if (isType(action, clearReview)) {
    return {
      ...state,
      reviewToDo: undefined,
    }
  }

  if (isType(action, saveReview.started)) {

    const { answersToSave } = action.payload

    return {
      ...state,
      saveReviewFetching: true,
      answersToSave,
      saveReviewError: false,
    }
  }

  if (isType(action, saveReview.done as any)) {

    // @ts-ignore
    const { saveReviewSuccessMessage } = action.payload.result

    return {
      ...state,
      saveReviewFetching: false,
      reviewToDo: undefined,
      answersToSave: undefined,
      getReviewError: false,
      saveReviewSuccessMessage,
    }
  }

  if (isType(action, saveReview.failed as any)) {

    return {
      ...state,
      saveReviewFetching: false,
      answersToSave: undefined,
      saveReviewError: true,
    }
  }

  return state
}
