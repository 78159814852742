import request, { generatePostOptionsForRequest} from "../utils/request"

import { call, put, takeLatest } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"

import { getRecycleOptions, postGarmentForRecycle, unpostGarmentForRecycle, getDonateOptions } from "../reducers/PassItOnActions"


/* ************************************************************* */
function* getRecycleOptionsSaga(action: any) {
  const { garmentId } = action.payload
  try {
    const result = yield call(request, `/brands/recycleOptions/${garmentId}`)

    yield put(getRecycleOptions.done({ params: { garmentId }, result }))
  } catch (err) {
    console.log(err)
    yield put(getRecycleOptions.failed({ params: { garmentId }, error: err }))
  }
}

export function* watchGetRecycleOptions(): SagaIterator {
  yield takeLatest(getRecycleOptions.started, getRecycleOptionsSaga)
}
/* ************************************************************* */

/* ************************************************************* */
function* getDonateOptionsSaga(action: any) {
  const { garmentId } = action.payload
  try {
    const result = yield call(request, `/brands/donateOptions/${garmentId}`)

    yield put(getDonateOptions.done({ params: { garmentId }, result }))
  } catch (err) {
    console.log(err)
    yield put(getDonateOptions.failed({ params: { garmentId }, error: err }))
  }
}

export function* watchGetDonateOptions(): SagaIterator {
  yield takeLatest(getDonateOptions.started, getDonateOptionsSaga)
}
/* ************************************************************* */

/* ************************************************************* */
function* postGarmentForRecycleRequest(action: any) {
  const { postedGarmentData } = action.payload
  try {
    const postOptions = generatePostOptionsForRequest(postedGarmentData)
    const result = yield call(request, "/brands/recycledGarmentPosted", postOptions)

    const {message} = result
    yield put(postGarmentForRecycle.done({
      params: { postedGarmentData }, result: { postGarmentForRecycleSuccessMessage: message },
    }))
  } catch (err) {
    console.log(err)
    yield put(postGarmentForRecycle.failed({ params: { postedGarmentData }, error: err }))
  }
}

export function* watchPostGarmentForRecycle(): SagaIterator {
  yield takeLatest(postGarmentForRecycle.started, postGarmentForRecycleRequest)
}
/* ************************************************************* */

/* ************************************************************* */
function* unpostGarmentForRecycleRequest(action: any) {
  const { garmentCode } = action.payload
  try {
    const postOptions = generatePostOptionsForRequest({})
    const result = yield call(request, `/brands/recycledGarmentUnposted/${garmentCode}`, postOptions)

    const { message } = result
    yield put(unpostGarmentForRecycle.done({
      params: { garmentCode }, result: { unpostGarmentForRecycleSuccessMessage: message },
    }))
  } catch (err) {
    console.log(err)
    yield put(unpostGarmentForRecycle.failed({ params: { garmentCode }, error: err }))
  }
}

export function* watchUnpostGarmentForRecycle(): SagaIterator {
  yield takeLatest(unpostGarmentForRecycle.started, unpostGarmentForRecycleRequest)
}
/* ************************************************************* */
