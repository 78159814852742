import request, { generatePostOptionsForRequest, generateDeleteOptionsForRequest } from "../utils/request"

import { call, put, takeLatest } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"

import {
  getCurrentUserInfo, login, addAddress, deleteAddress, selectAddress,
} from "../reducers/UsersActions"

import { iWantIt } from "../reducers/ClosetActions"

import { getSync } from "../reducers/SyncActions"


/* ************************************************************* */
function * getCurrentUser() {
  try {
    const result = yield call(request, "/users/currentUser")

    yield put(getCurrentUserInfo.done({ result }))
  } catch (err) {
    console.log(err)
    yield put(getCurrentUserInfo.failed({ error: err }))
  }
}

export function * watchGetCurrentUser(): SagaIterator {
  yield takeLatest(getCurrentUserInfo.started, getCurrentUser)
}
/* ************************************************************* */


/* ************************************************************* */
function * loginSaga(action: any) {

  const { payload } = action.payload

  try {
    const postOptions = generatePostOptionsForRequest(payload)
    const result = yield call(request, "/auth/login", postOptions)

    yield put(login.done({ params: { payload }, result }))

  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(login.failed({ params: { payload }, error: { errorMessage: message }}))
  }
}

export function * watchLogin(): SagaIterator {
  yield takeLatest(login.started, loginSaga)
}
/* ************************************************************* */

/* ************************************************************* */
function * getSyncRequest() {
  try {
    const result = yield call(request, "/users/sync")

    yield put(getSync.done({ result }))
  } catch (err) {
    console.log(err)
    yield put(getSync.failed({ error: err }))
  }
}

export function * watchGetSync(): SagaIterator {
  yield takeLatest(getSync.started, getSyncRequest)
}
/* ************************************************************* */

/* ************************************************************* */
function * addAddressRequest(action: any) {
  const { address } = action.payload
  try {
    const postOptions = generatePostOptionsForRequest({ address })
    const result = yield call(request, "/users/address", postOptions)
    const { message, id } = result
    yield put(addAddress.done({ params: { address }, result: { addAddressResultMessage: message, addressId: id } }))
  } catch (err) {
    console.log(err)
    yield put(addAddress.failed({ params: { address }, error: err }))
  }
}

export function * watchAddAddress(): SagaIterator {
  yield takeLatest(addAddress.started, addAddressRequest)
}
/* ************************************************************* */

/* ************************************************************* */
function * deleteAddressRequest(action: any) {
  const { addressId } = action.payload
  try {
    const deleteOptions = generateDeleteOptionsForRequest({})
    const result = yield call(request, `/users/address/${addressId}`, deleteOptions)
    const { message } = result
    yield put(deleteAddress.done({ params: { addressId }, result: { deleteAddressResultMessage: message } }))
  } catch (err) {
    console.log(err)
    yield put(deleteAddress.failed({ params: { addressId }, error: err }))
  }
}

export function* watchDeleteAddress(): SagaIterator {
  yield takeLatest(deleteAddress.started, deleteAddressRequest)
}
/* ************************************************************* */

/* ************************************************************* */
function * selectAddressRequest(action: any) {
  const { addressId } = action.payload
  try {
    const postOptions = generatePostOptionsForRequest({ addressId })
    yield call(request, "/users/activeAddress", postOptions)
    yield put(selectAddress.done({ params: { addressId }, result: { addressId } }))
  } catch (err) {
    console.log(err)
    yield put(selectAddress.failed({ params: { addressId }, error: err }))
  }
}

export function* watchSelectAddress(): SagaIterator {
  yield takeLatest(selectAddress.started, selectAddressRequest)
}
/* ************************************************************* */

/* ************************************************************* */
function * iWantItRequest(action: any) {
  try {
    const postOptions = generatePostOptionsForRequest(action.payload)
    yield call(request, "/users/iWantIt", postOptions)
    yield put(iWantIt.done({params:  action.payload}))
  } catch (err) {
    console.log(err)
    yield put(iWantIt.failed({ params:  action.payload, error: err }))
  }
}

export function* watchiWantIt(): SagaIterator {
  yield takeLatest(iWantIt.started, iWantItRequest)
}
/* ************************************************************* */
