import { Action } from "redux"
import { isType } from "typescript-fsa"

import {
  recycleGarment, donateGarment, changeStatus,
  selectCharity, selectAppropriateGarments, markGarmentUnposted,
  getRecycleOptions, selectAppropriateStatus, sellGarment,
  postGarmentForRecycle, unpostGarmentForRecycle, clearRecyclingPostUnpost, getDonateOptions,
} from "./PassItOnActions"
import { getSync } from "./SyncActions"

import { IGarment, IRecycleDonateGarment, IGarmentShort } from "./GarmentModels"
import { ICharity } from "./PassItOnModels"
import { matchLocalAndServerInfo } from "../utils/utils"

export const GarmentRecycleDonateStatuses = Object.freeze({
  GarmentPosted: 1,
  CouponApproved: 2,
  CouponUsed: 3,
})

export const ActionTypes = Object.freeze({
  recycle: 1,
  donate: 2,
  sell: 3,
})

export const AuctionSellTypes = Object.freeze({
  noAuction: { id: 1, text: "No auction" },
  auction: { id: 2, text: "Auction" },
})

export const CategoryTypes = Object.freeze({
  activeWear: { id: 1, text: "Activewear" },
  coats: { id: 2, text: "Coats & Jackets" },
  jeans: { id: 3, text: "Jeans" },
  pants: { id: 4, text: "Pants & Trousers" },
  shirts: { id: 5, text: "Shirts" },
  shorts: { id: 6, text: "Shorts" },
  sleapWear: { id: 7, text: "Sleapwear & Robes" },
  socks: { id: 8, text: "Socks" },
  suits: { id: 9, text: "Suits & Suit Separates" },
  sweaters: { id: 10, text: "Sweaters" },
  swimwear: { id: 11, text: "Swimwear" },
  underwear: { id: 12, text: "Underwear" },
})

export const ConditionTypes = Object.freeze({
  used: { id: 1, text: "Used" },
  newWithTags: { id: 2, text: "New with tags" },
  newWithoutTags: { id: 3, text: "New without tags" },
  newWithDefects: { id: 4, text: "New with defects" },
})

export interface IPassItOnState {
  recycledGarments: Array<IRecycleDonateGarment & IGarmentShort>,
  donatedGarments: Array<IRecycleDonateGarment & IGarmentShort>,

  selectedCharityId: number,
  selectedCharity?: ICharity,

  recycleOptions: any,
  recycleOptionsLoading: boolean,
  recycleOptionsError: boolean,

  donateOptions: any,
  donateOptionsLoading: boolean,
  donateOptionsError: boolean,

  garmentRecycleDonateStatus: any,
  recycleDonateGarments?: Array<IRecycleDonateGarment & IGarmentShort>,
  garmentsForSell: IGarment[],

  postGarmentForRecycleLoading: boolean,
  postGarmentForRecycleSuccessMessage?: string,
  postGarmentForRecycleError: boolean,

  unpostGarmentForRecycleLoading: boolean,
  unpostGarmentForRecycleSuccessMessage?: string,
  unpostGarmentForRecycleError: boolean,

}

export const initialState: IPassItOnState = {
  recycledGarments: [],
  donatedGarments: [],
  selectedCharityId: 0,
  selectedCharity: undefined,
  garmentRecycleDonateStatus: undefined,
  recycleDonateGarments: undefined,

  recycleOptionsLoading: false,
  recycleOptions: {},
  recycleOptionsError: false,

  donateOptionsLoading: false,
  donateOptions: [],
  donateOptionsError: false,

  garmentsForSell: [],

  postGarmentForRecycleLoading: false,
  postGarmentForRecycleSuccessMessage: undefined,
  postGarmentForRecycleError: false,

  unpostGarmentForRecycleLoading: false,
  unpostGarmentForRecycleSuccessMessage: undefined,
  unpostGarmentForRecycleError: false,
}


export default (state: IPassItOnState = initialState, action: Action): IPassItOnState => {


  if (isType(action, getSync.done as any)) {

    // @ts-ignore
    const { recycledGarments: serverRecycledGarments, donatedGarments: serverDonatedGarments } = action.payload.result
    const { recycledGarments, donatedGarments } = state

    return {
      ...state,
      recycledGarments: matchLocalAndServerInfo(recycledGarments, serverRecycledGarments)
        .map((garment) => ({...garment, recycleDonateStatus: GarmentRecycleDonateStatuses.GarmentPosted})),
      donatedGarments: matchLocalAndServerInfo(donatedGarments, serverDonatedGarments)
        .map((garment) => ({...garment, recycleDonateStatus: GarmentRecycleDonateStatuses.GarmentPosted})),
    }
  }

  if (isType(action, recycleGarment)) {

    const { garment } = action.payload
    const { recycledGarments } = state

    return {
      ...state,
      garmentRecycleDonateStatus: GarmentRecycleDonateStatuses.GarmentPosted,
      recycledGarments: [
        ...recycledGarments, { ...garment, recycleDonateStatus: GarmentRecycleDonateStatuses.GarmentPosted },
      ],
    }
  }

  if (isType(action, donateGarment)) {

    const { garment } = action.payload
    const { donatedGarments } = state

    return {
      ...state,
      garmentRecycleDonateStatus: GarmentRecycleDonateStatuses.GarmentPosted,
      donatedGarments: [
        ...donatedGarments, { ...garment, recycleDonateStatus: GarmentRecycleDonateStatuses.GarmentPosted },
      ],
    }
  }

  if (isType(action, changeStatus)) {

    const { garmentCode, newStatus, recycleDonateType } = action.payload

    if (recycleDonateType === ActionTypes.recycle) {
      const { recycledGarments } = state
      return {
        ...state,
        garmentRecycleDonateStatus: newStatus,
        recycledGarments: recycledGarments.map((garment) => garment && garment.garment_code === garmentCode
          ? { ...garment, recycleDonateStatus: newStatus }
          : garment),
      }
    } else if (recycleDonateType === ActionTypes.donate) {
      const { donatedGarments } = state
      return {
        ...state,
        garmentRecycleDonateStatus: newStatus,
        donatedGarments: donatedGarments.map((garment) => garment && garment.garment_code === garmentCode
          ? { ...garment, recycleDonateStatus: newStatus }
          : garment),
      }
    }

    return state
  }

  if (isType(action, selectCharity)) {

    const { selectedCharityId } = action.payload
    const { donateOptions } = state

    return {
      ...state,
      selectedCharityId,
      selectedCharity: { ...donateOptions[selectedCharityId], id: selectedCharityId },
    }
  }

  if (isType(action, selectAppropriateStatus)) {

    const { recycleDonateType, garment } = action.payload
    if (recycleDonateType === ActionTypes.recycle) {
      const { recycledGarments } = state
      const recycledGarment = recycledGarments.find(
        (chosenGarment: IGarment) => chosenGarment.garment_code === garment.garment_code,
      )
      if (recycledGarment) {
        return {
          ...state,
          garmentRecycleDonateStatus: recycledGarment.recycleDonateStatus,
        }
      }
    } else if (recycleDonateType === ActionTypes.donate) {
      const { donatedGarments } = state
      const donatedGarment = donatedGarments.find(
        (chosenGarment: IGarment) => chosenGarment.garment_code === garment.garment_code,
      )
      if (donatedGarment) {
        return {
          ...state,
          garmentRecycleDonateStatus: donatedGarment.recycleDonateStatus,
        }
      }
    }

    return {
      ...state,
      garmentRecycleDonateStatus: undefined,
    }
  }

  if (isType(action, selectAppropriateGarments)) {

    const { recycleDonateType } = action.payload
    const { recycledGarments, donatedGarments } = state
    let recycleDonateGarments = state.recycleDonateGarments

    if (recycleDonateType === ActionTypes.recycle) {
      recycleDonateGarments = recycledGarments
    } else if (recycleDonateType === ActionTypes.donate) {
      recycleDonateGarments = donatedGarments
    }

    return {
      ...state,
      recycleDonateGarments,
    }
  }

  if (isType(action, markGarmentUnposted)) {

    const { garmentCode, recycleDonateType } = action.payload

    if (recycleDonateType === ActionTypes.recycle) {
      const { recycledGarments } = state
      return {
        ...state,
        garmentRecycleDonateStatus: undefined,
        recycledGarments: recycledGarments.length > 0
          ? [...recycledGarments.filter((item) => item.garment_code !== garmentCode)]
          : recycledGarments,
      }
    }
    if (recycleDonateType === ActionTypes.donate) {
      const { donatedGarments } = state
      return {
        ...state,
        garmentRecycleDonateStatus: undefined,
        donatedGarments: donatedGarments.length > 0
        ? [...donatedGarments.filter((item) => item.garment_code !== garmentCode)]
        : donatedGarments,
      }
    }

    return state
  }

  if (isType(action, getRecycleOptions.started)) {
    return {
      ...state,
      recycleOptionsLoading: true,
      recycleOptionsError: false,
    }
  }

  if (isType(action, getRecycleOptions.failed as any)) {
    return {
      ...state,
      recycleOptionsLoading: false,
      recycleOptionsError: true,
    }
  }

  if (isType(action, getRecycleOptions.done as any)) {

    // TODO - remove ts-ignore
    // @ts-ignore
    const { recycleOptions } = action.payload.result

    return {
      ...state,
      recycleOptionsLoading: false,
      recycleOptionsError: false,
      recycleOptions,
    }
  }

  if (isType(action, getDonateOptions.started)) {
    return {
      ...state,
      donateOptionsLoading: true,
      donateOptionsError: false,
    }
  }

  if (isType(action, getDonateOptions.failed as any)) {
    return {
      ...state,
      donateOptionsLoading: false,
      donateOptionsError: true,
    }
  }

  if (isType(action, getDonateOptions.done as any)) {

    // TODO - remove ts-ignore
    // @ts-ignore
    const { donateOptions } = action.payload.result
    const { selectedCharity } = state

    return {
      ...state,
      donateOptionsLoading: false,
      donateOptionsError: false,
      donateOptions,
      selectedCharity: selectedCharity ? selectedCharity : { ...donateOptions[0], id: 0 },
    }
  }

  if (isType(action, sellGarment)) {

    const { garment } = action.payload
    const { garmentsForSell } = state


    return {
      ...state,
      garmentsForSell: [
        ...garmentsForSell.filter((item) => item && item.garment_code !== garment.garment_code), { ...garment },
      ],
    }
  }

  if (isType(action, postGarmentForRecycle.started)) {
    return {
      ...state,
      postGarmentForRecycleLoading: true,
      postGarmentForRecycleError: false,
      postGarmentForRecycleSuccessMessage: undefined,
    }
  }

  if (isType(action, postGarmentForRecycle.failed as any)) {
    return {
      ...state,
      postGarmentForRecycleLoading: false,
      postGarmentForRecycleError: true,
      postGarmentForRecycleSuccessMessage: undefined,
    }
  }

  if (isType(action, postGarmentForRecycle.done as any)) {

    // TODO - remove ts-ignore
    // @ts-ignore
    const { postGarmentForRecycleSuccessMessage } = action.payload.result
    console.log(action)
    return {
      ...state,
      postGarmentForRecycleLoading: false,
      postGarmentForRecycleError: false,
      postGarmentForRecycleSuccessMessage,
      unpostGarmentForRecycleSuccessMessage: undefined,
    }
  }

  if (isType(action, unpostGarmentForRecycle.started)) {
    return {
      ...state,
      unpostGarmentForRecycleLoading: true,
      unpostGarmentForRecycleError: false,
      unpostGarmentForRecycleSuccessMessage: undefined,
    }
  }

  if (isType(action, unpostGarmentForRecycle.failed as any)) {
    return {
      ...state,
      unpostGarmentForRecycleLoading: false,
      unpostGarmentForRecycleError: true,
      unpostGarmentForRecycleSuccessMessage: undefined,
    }
  }

  if (isType(action, unpostGarmentForRecycle.done as any)) {

    // TODO - remove ts-ignore
    // @ts-ignore
    const { unpostGarmentForRecycleSuccessMessage } = action.payload.result

    return {
      ...state,
      unpostGarmentForRecycleLoading: false,
      unpostGarmentForRecycleError: false,
      unpostGarmentForRecycleSuccessMessage,
      postGarmentForRecycleSuccessMessage: undefined,
    }
  }

  if (isType(action, clearRecyclingPostUnpost)) {

    return {
      ...state,
      postGarmentForRecycleLoading: false,
      postGarmentForRecycleSuccessMessage: undefined,
      postGarmentForRecycleError: false,
      unpostGarmentForRecycleLoading: false,
      unpostGarmentForRecycleSuccessMessage: undefined,
      unpostGarmentForRecycleError: false,
    }
  }


  return state
}
