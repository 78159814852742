import { call, put, takeLatest } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"

import request from "../utils/request"

import {
  getGarment, getLabelImages
 } from "../reducers/GarmentActions"
import { updateGarment } from "../reducers/ClosetActions"

import { getLanguageCode } from "../utils/utils"


/* ************************************************************* */
function * getGarmentSaga(action: any) {

  const { garmentCode, localFlags } = action.payload
  try {

    const languageCode = getLanguageCode()

    const result = yield call(request, `/garments/${garmentCode}/${languageCode}`)
    const { garment } = result

    yield put(getGarment.done({ params: { garmentCode, localFlags },
      result: {garment: {...garment, localFlags}} }))
    if (localFlags) {
      const { inCloset } = localFlags
      if (inCloset) {
        yield put(updateGarment({garment: {...garment, localFlags}}))
      }
    }
  } catch (err) {
    console.log(err)
    yield put(getGarment.failed({ params: { garmentCode, localFlags }, error: err }))
  }
}

export function * watchGetGarment(): SagaIterator {
  yield takeLatest(getGarment.started, getGarmentSaga)
}
/* ************************************************************* */

/* ************************************************************* */
function * getLabelImagesSaga(action: any) {

  const { orderItemId, size } = action.payload
  try {

    const languageCode = getLanguageCode()

    const sizePart = size ? `&size=${size}` : ''
    const result = yield call(request, `/garments/garmentLabels?orderItemId=${orderItemId}${sizePart}`)
    const { labelImages } = result

    yield put(getLabelImages.done({
      params: { orderItemId, size },
      result: {labelImages},
    }))
  } catch (err) {
    console.log(err)
    yield put(getLabelImages.failed({ params: { orderItemId, size }, error: err }))
  }
}

export function * watchGetLabelImages(): SagaIterator {
  yield takeLatest(getLabelImages.started, getLabelImagesSaga)
}
/* ************************************************************* */