import React from "react"

import "./styles/GarmentInformation.css"

import { IUser } from "../../reducers/UsersModels"
import GradientButton from "../GradientButton"
import Loading from "../Loading"

interface IOwnProps {
  user?: IUser,
  garmentId: number,
  garmentCode: string,
  onGetReview: (garmentId: number) => void,
  getReviewFetching: boolean,
  getReviewError: boolean,
}

const GarmentInformationReview: React.FC<IOwnProps> = (props) => {

  const { user, garmentId, garmentCode, onGetReview, getReviewFetching, getReviewError } = props

  return (
    <div className="garmentInfoBottom">

      {getReviewFetching && <Loading text="Getting questions..." small={true} />}
      {getReviewError && <p className="danger">Unable to get review questions.</p>}

      {! getReviewFetching && ! getReviewError && (
        <>
          <div style={{ flexDirection: "column", flex: 3 }}>
            <p className="small buttonText">
              Give us your feedback and earn our rewards.
            </p>
          </div>

          <GradientButton
            buttonText="FEEDBACK"
            onClick={() => user ? onGetReview(garmentId) : window.open(`/garment/${garmentCode}/r`, "_self")}
            additionalClassName=""
            gradientClassName="gradientGreen"
            sizeClassName="gradientButtonSmall"
            additionalButtonLabelClassName="alt"
          />
        </>
      )}

    </div>
  )
}

export default GarmentInformationReview
