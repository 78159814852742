import { combineReducers } from "redux"

import UsersReducers, { IUsersState } from "./UsersReducers"
import ClosetReducers, { IClosetState } from "./ClosetReducers"
import GarmentReducers, { IGarmentState } from "./GarmentReducers"
import WashingReducers, { IWashingState } from "./WashingReducers"
import NotificationReducers, { INotificationState } from "./NotificationReducers"
import ReviewReducers, { IReviewState } from "./ReviewReducers"
import PassItOnReducers, { IPassItOnState } from "./PassItOnReducers"


const rootReducer = combineReducers({
  user: UsersReducers,
  closet: ClosetReducers,
  garment: GarmentReducers,
  washing: WashingReducers,
  notification: NotificationReducers,
  review: ReviewReducers,
  passItOn: PassItOnReducers,
})


export type RootState = {
  user: IUsersState,
  closet: IClosetState,
  garment: IGarmentState,
  washing: IWashingState,
  notification: INotificationState,
  review: IReviewState,
  passItOn: IPassItOnState,
}

export default rootReducer
