import React, { useState } from "react"
import { Dispatch, Action } from "redux"
import { connect } from "react-redux"

import Loading from "../components/Loading"
import { ICredentials } from "../reducers/UsersModels"
import { RootState } from "../reducers"
import { login } from "../reducers/UsersActions"


interface IStateProps {
  loginLoading: boolean,
  errorLoggingIn?: string,
}

interface IDispatchProps {
  onLogin: (payload: ICredentials) => void,
}


const Login2: React.FC<IStateProps & IDispatchProps> = (props) => {

  const [email, setEmail] = useState("")

  const { errorLoggingIn, loginLoading } = props


  const doLogin = () => {
    const { onLogin } = props

    onLogin({email, password: "tst"})
  }

  if (errorLoggingIn) {
    return (<div>Error while logging in</div>)
  }

  if(loginLoading) {
    return (
      <Loading text="Getting garment information" small={false} />
    )
  }

  return (
    <div>
      <input
        type="text"
        name="email"
        placeholder="email"
        onChange={(e) => setEmail(e.target.value)}
      />

      <button data-testid="loginButton2" onClick={doLogin}>Login</button>
    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  loginLoading: state.user.isLoadingLogin,
  errorLoggingIn: state.user.errorLogin,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onLogin: (payload: ICredentials) => dispatch(login.started({payload})),
  }
)

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Login2) as any
