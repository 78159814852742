import React from "react"

import "./styles/GradientButton.css"

interface IOwnProps {
  onClick: () => void,
  additionalClassName: string,
  gradientClassName: string,
  sizeClassName: string,
  buttonText: string,
  additionalButtonLabelClassName: string,
}

const GradientButton: React.FC<IOwnProps> = (props) => {

  const {
    onClick, additionalClassName, gradientClassName, sizeClassName,
    buttonText, additionalButtonLabelClassName,
  } = props

  return (
    <div
      data-testid={buttonText}
      className={`button gradientButton ${additionalClassName} ${sizeClassName} ${gradientClassName}`}
      onClick={onClick}
    >
      <p className={`buttonLabel ${additionalButtonLabelClassName}`}>{buttonText.toUpperCase()}</p>
    </div>
  )
}
export default GradientButton
