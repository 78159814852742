import { Action } from "redux"
import { isType } from "typescript-fsa"

import {
  getGarment, getLabelImages
} from "./GarmentActions"

import { IGarment } from "./GarmentModels"

export interface IGarmentState {
  garmentFetching: boolean,
  garmentError: boolean,
  garment?: IGarment,

  labelImagesFetching: boolean,
  labelImagesError: boolean,
  labelImages?: string[],
}

export const initialState: IGarmentState = {
  garmentFetching: false,
  garmentError: false,
  garment: undefined,

  labelImagesFetching: false,
  labelImagesError: false,
  labelImages: [],
}

export default (state: IGarmentState = initialState, action: Action): IGarmentState => {

  if (isType(action, getGarment.started)) {

    return {
      ...state,
      garmentFetching: true,
      garmentError: false,
      garment: undefined,
    }
  }

  if (isType(action, getGarment.done as any)) {

    // @ts-ignore
    const { garment } = action.payload.result

    return {
      ...state,
      garmentFetching: false,
      garmentError: false,
      garment,
    }
  }

  if (isType(action, getGarment.failed as any)) {

    return {
      ...state,
      garmentFetching: false,
      garmentError: true,
      garment: undefined,
    }
  }



  if (isType(action, getLabelImages.started)) {

    return {
      ...state,
      labelImagesFetching: true,
      labelImagesError: false,
      labelImages: undefined,
    }
  }

  if (isType(action, getLabelImages.done as any)) {

    // @ts-ignore
    const { labelImages } = action.payload.result

    return {
      ...state,
      labelImagesFetching: false,
      labelImagesError: false,
      labelImages,
    }
  }

  if (isType(action, getLabelImages.failed as any)) {

    return {
      ...state,
      labelImagesFetching: false,
      labelImagesError: true,
      labelImages: undefined,
    }
  }
  return state

}
