import React from "react"

import "./styles/ClosetListItem.css"

import { IGarment, IGarmentShort } from "../../reducers/GarmentModels"

// import useLongPress from "../../utils/useLongPress"
import IconButton from "../IconButton"
import GarmentPhoto from "../GarmentPhoto"

interface IOwnProps {
  garment: IGarment & IGarmentShort,
  toggleWashFlag: (garment: IGarment & IGarmentShort) => void,
  goToGarmentScreen: () => void,
}

const ClosetListItem: React.FC<IOwnProps> = (props) => {

  const { garment, toggleWashFlag, goToGarmentScreen } = props

  // const onLongPress = useLongPress(() => toggleWashFlag(garment), 500)

  if (garment === null) {
    return null
  }

  const { name, brand_name, photos, localFlags, base_photo } = garment

  let addedToWash = false
  if (localFlags) {
    ({ addedToWash } = localFlags)
  }


  return (
    <div className={`closetListItemContainer ${addedToWash ? "gradientBlue" : ""}`} data-testid="closetListItem">

        <div className="closetListItemMainContent" onClick={goToGarmentScreen} data-testid={name}>
          <div className="withoutFeedback button" style={{ flex: 1, padding: 0 }}>
            <GarmentPhoto
              onClick={undefined}
              photos={photos}
              photo={base_photo}
              additionalClassName="closetListItemGarmentPhotoContainer"
              additionalPhotoClassName="closetListItemGarmentPhoto"
            />
            <div className="closetListItemGarmentHeadline">
              <p data-testid="closetListItemName" className="title">{name}</p>
              <p data-testid="closetListItemBrand" className="byline">{brand_name}</p>
            </div>
          </div>
        </div>
      <div className="closetListItemActions">
        <IconButton
          onClick={() => { }}
          additionalClassName="closetListItemAction"
          imgClassName="icon32"
          imgSource="/images/icons/share.svg"
          additionalButtonLabelClassName="buttonLabel alt shareWashButtonPadding"
          buttonText={"SHARE"}
        />
        <IconButton
          onClick={() => toggleWashFlag(garment)}
          additionalClassName="closetListItemAction"
          imgClassName="icon32"
          imgSource={! addedToWash ? "/images/icons/wash.svg" : null}
          additionalButtonLabelClassName={
            `${addedToWash ? "buttonLabel alt danger" : "buttonLabel alt"} shareWashButtonPadding`}
          buttonText={addedToWash ? "REMOVE" : "WASH"}
        />
      </div>

    </div>

  )
}

export default ClosetListItem
