export function getSupplyChainPhotoPlaceholder(photo: string | null, shortType: string) {

  let supplyChainPlaceholder = "/images/icons/supply-chain-icons/fabric.svg"
  if (! photo) {
    if (shortType === "garment") {
      supplyChainPlaceholder = "/images/icons/supply-chain-icons/garment.svg"
    } else if (shortType === "shipper") {
      supplyChainPlaceholder = "/images/icons/supply-chain-icons/shipper.svg"
    } else if (shortType === "retail") {
      supplyChainPlaceholder = "/images/icons/supply-chain-icons/retail.svg"
    } else if (shortType === "miscellaneous") {
      supplyChainPlaceholder = "/images/icons/supply-chain-icons/misc.svg"
    }

    return supplyChainPlaceholder
  }
}
