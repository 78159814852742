import React from "react"

import "./styles/SearchInput.css"

import GradientButton from "../GradientButton"

interface IOwnProps {
  history: any,
  currentText: string,
  setCurrentText: (text: string) => void,
  setShowResult: (show: boolean) => void
}

const SearchInput: React.FC<IOwnProps> = (props) => {

  const { history, currentText, setCurrentText, setShowResult } = props

  const closeSearch = () => {
    setCurrentText("")
    history.goBack()
  }

  return (
    <div className="searchScreenContainer" data-testid="searchScreen">
      <div className="button" style={{ justifyContent: "flex-end" }} onClick={closeSearch} data-testid="closeSearch">
        <img src="/images/icons/close.svg" alt="Close" />
      </div>
      <div style={{ justifyContent: "space-around" }}>
        <p data-testid="searchHeading" className="title">Search your closet</p>
      </div>

      <div className="searchInputContainer" style={currentText.length > 0 ? { height: "95px" } : {}}>
        <img src="/images/icons/search-quiet.svg" alt="Search" />
        <input
          data-testid="searchInput"
          type="text"
          className="H2 searchInput"
          onChange={(e) => setCurrentText(e.target.value)}
          autoFocus
          placeholder="Type here..."
          value={currentText}
        />
      </div>

      {currentText.length > 0 && (
        <GradientButton
          additionalClassName="searchButton"
          buttonText="Search closet"
          onClick={() => setShowResult(true)}
          gradientClassName="gradientGreen"
          sizeClassName="gradientButtonSmall"
          additionalButtonLabelClassName=""
        />
      )}

    </div>
  )
}

export default SearchInput
