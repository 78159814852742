import { Action } from "redux"
import { isType } from "typescript-fsa"

import { cancelWash, addToWash, removeFromWash } from "./WashingActions"
import { IGarment, IGarmentShort } from "./GarmentModels"

export interface IWashingState {
  garmentsForWashing: Array<IGarment & IGarmentShort>
}

export const initialState: IWashingState = {
  garmentsForWashing: [],
}

export default (state: IWashingState = initialState, action: Action): IWashingState => {

  if (isType(action, addToWash)) {
    const { garment } = action.payload
    let { garmentsForWashing } = state
    if (! garmentsForWashing) {
      garmentsForWashing = []
    }
    return {
      ...state,
      garmentsForWashing: [...garmentsForWashing, garment],
    }
  }

  if (isType(action, removeFromWash)) {
    const { garment: { garment_code } } = action.payload
    let { garmentsForWashing } = state
    if (! garmentsForWashing) {
      garmentsForWashing = []
    }
    return {
      ...state,
      garmentsForWashing: garmentsForWashing.filter((garment) => garment && garment.garment_code !== garment_code)
      ,
    }
  }

  if (isType(action, cancelWash)) {
    return {
      ...state,
      garmentsForWashing: [],
    }
  }

  return state

}
