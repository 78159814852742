import { createStore, applyMiddleware, Store as ReduxStore } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web

// import createHistory from "history/createBrowserHistory"

import rootReducer, { RootState } from "./reducers"
import rootSaga from "./sagas"
import createSagaMiddleware from "redux-saga"

// export const history = createHistory()
const initialState = {}
const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware]

const composedEnhancers = applyMiddleware(...middleware)

// redux persist config
const persistConfig = {
  key: "root",
  whitelist: ["closet"],
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


const store: ReduxStore<RootState> = createStore(
  persistedReducer,
  initialState,
  composedEnhancers,
)

export const persistor = persistStore(store)


sagaMiddleware.run(rootSaga)

export default store
