import React from "react"

import "./styles/WashingListItem.css"

import { IGarment, IGarmentShort } from "../../reducers/GarmentModels"
import RegularButton from "../RegularButton"
import GarmentPhoto from "../GarmentPhoto"


interface IOwnProps {
  garment: IGarment & IGarmentShort,
  onGarmentClicked: (garment: IGarment & IGarmentShort) => void,
  onRemoveGarmentFromWash: (garment: IGarment & IGarmentShort) => void,
}

const WashingListItem: React.FC<IOwnProps> = (props) => {

  const { garment, onGarmentClicked, onRemoveGarmentFromWash } = props

  if (garment === null) {
    return null
  }

  const { name, brand_name, photos, base_photo } = garment

  return (
    <div className="listItemMainContainer">

      <GarmentPhoto
        onClick={() => onGarmentClicked(garment)}
        photos={photos}
        photo={base_photo}
        additionalClassName="button withoutFeedback"
        additionalPhotoClassName="listItemGarmentPhoto"
      />

      <div style={{ justifyContent: "space-between", flexDirection: "column" }}>

        <div className="listItemContent">
          <div className="button withoutFeedback" onClick={() => onGarmentClicked(garment)}>
            <div className="listItemText">
              <p className="H2">{name}</p>
              <p className="small quiet">{brand_name}</p>
            </div>
          </div>
        </div>

        <div className="removeGarmentContainer">
          <RegularButton
            onClick={() => onRemoveGarmentFromWash(garment)}
            sizeClassName="regularButton"
            buttonText="Remove"
            additionalClassName=""
            lowerCase={false}
            additionalButtonLabelClassName="removeButton"
          />
        </div>

      </div>

    </div>
  )
}
export default WashingListItem
