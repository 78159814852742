import React from "react"

import "./styles/SettingsCharitiesListItem.css"

interface IOwnProps {
  selectedCharityId: number,
  onSelectCharity: (selectedCharityId: number) => void,
  charityName: string,
  charityId: number,
}

const SettingsCharitiesListItem: React.FC<IOwnProps> = (props) => {

  const { selectedCharityId, onSelectCharity, charityName, charityId } = props

  return (
    <div
      className="button settingsCharitiesListItemContainer"
      onClick={() => onSelectCharity(charityId)}
      data-testid={`charitiesListItem${charityId}`}
    >
      <p className="body">{charityName}</p>
      <img
        src={selectedCharityId === charityId ? "/images/icons/ok-tertiary.svg" : "/images/icons/ok-quiet.svg"}
        alt={"Select charity"}
      />
    </div>
  )
}

export default SettingsCharitiesListItem
