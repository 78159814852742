import React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"

import store, { persistor } from "./store"
import App from "./containers/App"
import Loading from "./components/Loading"

import * as serviceWorker from "./serviceWorker"


import "./index.css"


const target = document.querySelector("#root")

render(
  <Provider store={store}>
    <PersistGate loading={<Loading text={""} small={false} />} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  target,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

