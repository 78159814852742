import React from "react"
import { withRouter } from "react-router"

import "./styles/Login.css"

import GradientButton from "../components/GradientButton"
import IconText from "../components/IconText"

const Login: React.FC = (props) => {

  const querystring = (name: string, url = window.location.href) => {
    name = name.replace(/[[]]/g, "\\$&")

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i")
    const results = regex.exec(url)

    if (! results) {
      return null
    }

    if (! results[2]) {
      return ""
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "))
  }

  const redirect = querystring("redirect")

  return (
    <div className="loginContainer" data-testid="loginScreen">

      <div className="emptyScreenContainer" style={{ justifyContent: "space-between", paddingBottom: "40px" }}>

        <div className="loginImageContainer">
          <img src="/images/account-empty.svg" alt="Login" style={{ width: "100%" }} data-testid="loginScreenPhoto" />
        </div>

        <p className="title" style={{ textAlign: "center" }} data-testid="loginScreenHeading">Create an Account</p>

        <div>
          <div style={{ alignItems: "flex-start", width: "100%", flexDirection: "column", paddingTop: "32px" }}>

            <IconText
              additionalClassName=""
              imgSource="/images/icons/ok-tertiary.svg"
              imgClassName="createAccountImage"
              text="Sync your closet accross devices"
              additionalTextClassName="body"
            />

            <IconText
              additionalClassName=""
              imgSource="/images/icons/ok-tertiary.svg"
              imgClassName="createAccountImage"
              text="Enable ordering drycleaning"
              additionalTextClassName="body"
            />

            <IconText
              additionalClassName=""
              imgSource="/images/icons/ok-tertiary.svg"
              imgClassName="createAccountImage"
              text="Receive exclusive deals on washing supplies"
              additionalTextClassName="body"
            />

          </div>
        </div>
      </div>

      <div style={{ marginBottom: "16px", flexDirection: "column", alignItems: "center" }}>
        <GradientButton
          additionalClassName="signInButton"
          buttonText="SIGN IN"
          onClick={() => window.location.href = `https://backend.cacotec.com/mw/auth/google?redirect=${redirect}`}
          gradientClassName="gradientGreen"
          sizeClassName="gradientButtonBig"
          additionalButtonLabelClassName="black"
        />
        <p className="small" style={{ textAlign: "center", marginTop: "8px" }}>
          Cacotec uses your Google account for logging in
          </p>
      </div>
    </div>
  )
}

export default withRouter(Login as any)
