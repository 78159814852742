import React, { useRef, useEffect } from "react"

import "./styles/GarmentCare.css"

import { IBin } from "../../reducers/GarmentModels"

import binsData from "../../data/bins"

interface IOwnProps {
  bins: IBin[],
  onSetTabsHeight: (height: number) => void
}

const GarmentCare: React.FC<IOwnProps> = (props) => {

  const ref = useRef(null) as any

  const { onSetTabsHeight } = props

  useEffect(() => {
    onSetTabsHeight(ref.current.clientHeight)
  }, [onSetTabsHeight, ref])

  const allBins = binsData() as any
  let { bins } = props

  if (! bins) {
    bins = []
  }

  return (
    <div style={{ flex: 1, flexDirection: "column" }} ref={ref} data-testid="garmentCare">

      {bins.map((bin, index) => {
        let binIcon = null
        binIcon = allBins.map(
          (binType: any) => binType.bins.find((oneBin: any) => oneBin.id === bin.id))
          .find((currentBin: any) => currentBin !== undefined).icon
        const { name, id, additionalInfo } = bin
        return (
          <div key={id} className="imageText garmentCareSection">
            <img src={binIcon} className="binIcon" alt={name} />
            <div className="garmentCareSectionText">
              <p className="H2" data-testid={`garmentCareName${index}`}>{name}</p>
              <p className="body" data-testid={`garmentCareAdditionalInfo${index}`}>{additionalInfo}</p>
            </div>
          </div>
        )
      })}

    </div>
  )
}

export default GarmentCare
