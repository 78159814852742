import React, { useEffect, useRef, useCallback } from "react"

import "./styles/ReviewSendButton.css"

import GradientButton from "../GradientButton"
import Loading from "../Loading"

interface IOwnProps {
  fetching?: boolean,
  buttonText: string,
  onClick: () => void,
  onShowButton: (height: number) => void,
  error?: boolean,
}

const ReviewSendButton: React.FC<IOwnProps> = (props) => {

  const { fetching, buttonText, onClick, onShowButton, error } = props

  const ref = useRef(null) as any

  const onShowButtonCallback = useCallback(() => {
    onShowButton(ref.current.clientHeight)
  }, [ref, onShowButton])

  useEffect(() => {
    onShowButtonCallback()
  }, [onShowButtonCallback])

  return (
    <div className="sendReviewContainer" ref={ref}>

      <div className="sendReviewButtonContainer">

        {! fetching && (
          <div style={{ flexDirection: "column" }}>
            <p className="small sendReviewText">
              {! error && "That’s it! All that’s left is to do is submit the survey."}
              {error && "Something went wrong. Please try again."}
            </p>
            <GradientButton
              additionalClassName=""
              buttonText={buttonText}
              onClick={onClick}
              gradientClassName="gradientGreen"
              sizeClassName="gradientButtonBig"
              additionalButtonLabelClassName="alt textAlignCenter"
            />
          </div>
        )}

      </div>
      {fetching && <Loading text="Saving your answers..." small={true} />}
    </div>
  )
}

export default ReviewSendButton
