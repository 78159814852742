import React from "react"

import "./styles/SettingsSelectItem.css"
import Loading from "../Loading"

interface IOwnProps {
  isActive?: boolean,
  text?: string,
  onSelectActiveAddress: () => void,
  onEditAddress?: () => void,
  activeAddressNameLoading: boolean,
}

const SettingsSelectAddressItem: React.FC<IOwnProps> = (props) => {

  const { isActive, text, onEditAddress, onSelectActiveAddress, activeAddressNameLoading } = props

  return (
    <div className="settingsItemMain">
      <p
        className={isActive ? "body" : "body quiet"}
        style={onEditAddress ? { cursor: "pointer" } : {cursor: "default"}}
        onClick={onEditAddress}
        data-testid={text}
      >
        {text}
      </p>

        {activeAddressNameLoading
        ? <div className="icon32" style={{ justifyContent: "right"}}>
            <Loading text={undefined} small={true} />
          </div>
        : <div className="button" onClick={onSelectActiveAddress} data-testid={isActive ? `${text}Active` : `${text}NotActive`}>
            <img
              className="icon32"
              src={isActive ? "/images/icons/ok-tertiary.svg" : "/images/icons/ok-quiet.svg"}
              alt={isActive ? "Selected" : "Select"}
            />
          </div>
        }
    </div>
  )
}

export default SettingsSelectAddressItem
