import { Action } from "redux"
import { isType } from "typescript-fsa"

import { showNotification, clearNotification, showAlert, clearAlert, clearAll } from "./NotificationActions"

export interface INotificationState {
  notificationTitle?: string,
  notificationBody?: string,

  alertTitle?: string,
  alertBody?: string,
  onAccept?: () => void,
  deleteFlag: boolean,
}

export const initialState: INotificationState = {
  notificationTitle: undefined,
  notificationBody: undefined,

  alertTitle: undefined,
  alertBody: undefined,
  onAccept: undefined,
  deleteFlag: false,
}

export default (state: INotificationState = initialState, action: Action): INotificationState => {


  if (isType(action, showNotification)) {

    const { notificationTitle, notificationBody } = action.payload
    return {
      ...state,
      notificationTitle,
      notificationBody,
    }
  }

  if (isType(action, clearNotification)) {

    return {
      ...state,
      notificationTitle: undefined,
      notificationBody: undefined,
    }
  }

  if (isType(action, showAlert)) {

    const { alertTitle, alertBody, onAccept, deleteFlag } = action.payload
    return {
      ...state,
      alertTitle,
      alertBody,
      onAccept,
      deleteFlag,
    }
  }

  if (isType(action, clearAlert)) {

    return {
      ...state,
      alertTitle: undefined,
      alertBody: undefined,
      onAccept: undefined,
      deleteFlag: false,
    }
  }

  if (isType(action, clearAll)) {

    return {
      ...initialState,
    }
  }

  return state
}
