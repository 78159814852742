import React, { useEffect, useCallback } from "react"
import { connect } from "react-redux"

import { RootState } from "../reducers"
import { IUser } from "../reducers/UsersModels"


interface IOwnProps {
  history: any,
}


interface IStateProps {
  user?: IUser,
}

const HelpScreen: React.FC<IOwnProps & IStateProps> = (props) => {

  const { user, history } = props

  const intercomCallback = useCallback(() => {
    if (user) {
      const { id, name, email } = user;

      (window as any).Intercom("boot", {
        app_id: "oe2r3uqb",
        email,
        name,
        user_id: id,
      })
    }
  }, [user])

  useEffect(() => {
    intercomCallback()
  }, [intercomCallback])

  let content = null
  if (user === undefined) {
    content = "Not logged in."
  } else {

    (window as any).Intercom("show");

    (window as any).Intercom("onHide", () => { history.push("/closet") });
  }


  return (
    <>
      <div>
        {content}
      </div>
    </>
  )
}


const mapStateToProps = (state: RootState): IStateProps => ({
  user: state.user.user,
})

export default
  connect(
    mapStateToProps,
    null,
  )(HelpScreen) as any

