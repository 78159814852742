import React from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import "./styles/SettingsScreen.css"

import { RootState } from "../reducers"
import { toggleSettingsState, removeCurrentUserInfo } from "../reducers/UsersActions"
import { showAlert } from "../reducers/NotificationActions"
import { IUser } from "../reducers/UsersModels"
import { ActionTypes } from "../reducers/PassItOnReducers"

import SettingsBasicInfoTop from "../components/settings/SettingsBasicInfoTop"
import SettingsSwitchItem from "../components/settings/SettingsSwitchItem"
import RegularButton from "../components/RegularButton"
import SettingsItem from "../components/settings/SettingsItem"

interface IOwnProps {
  history: any,
}

interface IStateProps {
  user?: IUser,
  shareInformation: boolean,
  activeAddress: any,
}

interface IDispatchProps {
  onToggleSettingsState: (name: string) => void,
  onShowAlert: (alertTitle: string, alertBody: string, deleteFlag: boolean, onAccept?: () => void) => void,
  onRemoveCurrentUserInfo: () => void,
}

const SettingsScreen: React.FC<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const {
    user, shareInformation, onToggleSettingsState, onShowAlert, onRemoveCurrentUserInfo, history, activeAddress,
  } = props

  if (! user) { return null }

  const onShowRecycled = () => {
    history.push("/settings/recycleList", { type: ActionTypes.recycle })
  }

  const onShowDonated = () => {
    history.push("/settings/donate")
  }

  const onAddress = () => {
    history.push("/settings/addresses", {})
  }

  return (
    <div style={{ flex: 1, flexDirection: "column" }}>

      <SettingsBasicInfoTop user={user} />

      <p className="section-upcase quiet">CONTACT INFORMATION</p>
      <SettingsItem
        title="Delivery address"
        text={activeAddress && activeAddress.name}
        onClick={onAddress}
      />

      <p className="section-upcase quiet">PRIVACY</p>
      <SettingsSwitchItem
        text="Share information with Cacotec"
        initialState={shareInformation}
        onSwitchChanged={() => onToggleSettingsState("shareInfo")}
      />

      <p className="section-upcase quiet">RECYCLING & DONATIONS</p>
      <SettingsItem
        title="Recycling"
        text={undefined}
        onClick={onShowRecycled}
        extraText={undefined}
        extraLinkText={undefined}
      />
      <SettingsItem
        title="Donations"
        text={undefined}
        onClick={onShowDonated}
        extraText={undefined}
        extraLinkText={undefined}
      />

      <p className="small quiet" style={{ padding: "16px" }}>
        If you choose to share your closet information with Cacotec, you will be doing it anonymously.
      </p>

      <div className="deleteAccountContainer">
        <RegularButton
          onClick={() => onShowAlert(
            "Delete account",
            "Are you sure you want to delete your account?",
            true,
            onRemoveCurrentUserInfo,
          )}
          sizeClassName="regularButtonSmall"
          buttonText="Delete account"
          additionalClassName="deleteAccountButton"
          lowerCase={false}
          additionalButtonLabelClassName="buttonLabel danger"
        />

        <p className="small quiet" style={{ marginTop: "3px" }}>
          There is no undo.
          If you delete your account there will be no way of getting your closet, history, or settings back.
        </p>
      </div>

    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  user: state.user.user,
  shareInformation: state.user.shareInformation,
  activeAddress: state.user.activeAddress,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onToggleSettingsState: (name: string) => dispatch(toggleSettingsState({ name })),
    onShowAlert: (alertTitle: string, alertBody: string, deleteFlag: boolean, onAccept?: () => void) => dispatch(
      showAlert({ alertTitle, alertBody, deleteFlag, onAccept })),
    onRemoveCurrentUserInfo: () => dispatch(removeCurrentUserInfo.done({})),
  }
)

// const wr = withRouter(SettingsScreen as any)

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SettingsScreen) as any
