import React, { useState, useEffect, useCallback } from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import "./styles/GarmentScreen.css"

import { RootState } from "../reducers"
import { iWantIt, addGarment, removeGarment } from "../reducers/ClosetActions"
import { showAlert } from "../reducers/NotificationActions"
import { toggleWash } from "../reducers/ClosetActions"
import { getGarment } from "../reducers/GarmentActions"
import { addToWash, removeFromWash } from "../reducers/WashingActions"
import { showNotification } from "../reducers/NotificationActions"
import { IUser } from "../reducers/UsersModels"
import { IGarment, IGarmentShort, ILocalFlags } from "../reducers/GarmentModels"
import { getReview } from "../reducers/ReviewActions"

import Empty from "../components/Empty"
import Loading from "../components/Loading"
import GarmentBasicInfo from "../components/garment/garmentBasicInfo/GarmentBasicInfo"
import GarmentScreenTabButtons, {
  INFORMATION_TAB, CARE_TAB, SUPPLY_CHAIN_TAB,
} from "../components/garment/GarmentScreenTabButtons"
import GarmentInformation from "../components/garment/GarmentInformation"
import GarmentCare from "../components/garment/GarmentCare"
import GarmentSupplyChain from "../components/garment/GarmentSupplyChain"
import RegularButton from "../components/RegularButton"



interface IStateProps {
  user?: IUser,
  loadedGarment?: IGarment,
  garmentLoading: boolean,
  getReviewFetching: boolean,
  getReviewError: boolean,
  closetContent: Array<IGarment & IGarmentShort>,
}

interface IOwnProps {
  location: any,
  match: any,
  history: any,
}

interface IDispatchProps {
  onAddToWash: (garment: IGarment & IGarmentShort) => void,
  onRemoveFromWash: (garment: IGarment & IGarmentShort) => void,
  onToggleWash: (garmentCode: string, added: boolean) => void,
  onGetGarment: (garmentCode: string, localFlags: ILocalFlags) => void,
  onShowNotification: (notificationTitle: string, notificationBody: string) => void,
  onIWantIt: (garmentCode: string, upc: string) => void,
  onShowAlert: (alertTitle: string, alertBody: string, deleteFlag: boolean, onAccept?: () => void) => void,
  onAddGarment: (garment: IGarment) => void,
  onRemoveGarment: (garmentCode: string, isLocal: boolean) => void,
  onGetReview: (garmentId: number) => void,
}

const SCANNED_GARMENT_PATHNAME = "/g/"

const Garment: React.FC<IStateProps & IOwnProps & IDispatchProps> = (props) => {

  const {
    user, location,
    onGetGarment, loadedGarment, garmentLoading,
    onIWantIt, onShowAlert, onShowNotification,
    onAddToWash, onRemoveFromWash, onToggleWash,
    onAddGarment, onRemoveGarment,
    onGetReview, getReviewFetching, getReviewError,
    closetContent, history,
  } = props

  const { state } = location

  const [watchVideo, setWatchVideo] = useState(false)
  const [activeTab, setActiveTab] = useState(INFORMATION_TAB)
  const [garment, setGarment] = useState(state ? state.garment : null)
  const [tabsHeight, setTabsHeight] = useState(48)
  const [showBackButton, setShowBackButton] = useState(true)

  const { garmentCode, action } = props.match.params

  const onGetGarmentCallback = useCallback(() => {
    if (state && state.garment && ! state.garment.style_number) {
      onGetGarment(state.garment.garment_code, state.garment.localFlags)
    } else if ((! state || ! state.garment) && garmentCode) {
      onGetGarment(garmentCode, { inCloset: false, addedToWash: false })
    }
    if (location.pathname.startsWith(SCANNED_GARMENT_PATHNAME)) {
      setShowBackButton(false)
    }
  }, [state, garmentCode, onGetGarment, location.pathname])

  const setGarmentCallback = useCallback(() => {
    if (state && state.garment && state.garment.style_number) {
      setGarment(state.garment)
    } else if (loadedGarment) {
      const garmentInCloset = closetContent.find(
        (closetGarment) => closetGarment != null && closetGarment.garment_code === loadedGarment.garment_code,
      ) as any

      if (garmentInCloset) {
        setGarment({...loadedGarment, localFlags: { ...garmentInCloset.localFlags, inCloset: true }})
        if (! showBackButton) {
          onAddGarment({...loadedGarment, localFlags: { ...garmentInCloset.localFlags, inCloset: true }})
        }
      } else {
        setGarment(loadedGarment)
      }
    }
  }, [loadedGarment])

  useEffect(() => {
    window.scrollTo(0, 0)
    onGetGarmentCallback()
  }, [onGetGarmentCallback])

  useEffect(() => {
    setGarmentCallback()
  }, [setGarmentCallback])


  // if (state === undefined) {
  //   return (
  //     <Empty image={undefined} title="" text="Garment info not forwarded" small={false} marginBottom={0} />
  //   )
  // }

  if (garmentLoading) {
    return (
      <Loading text="Getting garment information" small={false} />
    )
  }


  if (! garment) {
    return (
      <Empty image={undefined} title="" text="" small={false} marginBottom={0} />
    )
  }

  const { garment_code, name, bins, supply_chain: supplyChain, localFlags, video, upc } = garment

  let addedToWash = false
  let inCloset = false
  if (localFlags) {
    ({ inCloset, addedToWash } = localFlags)
  }


  const toggleWashFlag = () => {

    if (addedToWash) {
      onRemoveFromWash(garment)
      onShowNotification(name, "Removed from wash")
    } else {
      onAddToWash(garment)
      onShowNotification(name, "Added to wash")
    }

    onToggleWash(garment_code, addedToWash)
    setGarment({ ...garment, localFlags: { ...localFlags, addedToWash: !addedToWash }})
  }

  const addGarmentToCloset = () => {
    const addedGarment = { ...garment, localFlags: { ...localFlags, inCloset: true } }
    onAddGarment(addedGarment)
    setGarment(addedGarment)
  }

  const removeGarmentFromCloset = () => {
    onRemoveGarment(garment_code, false)
    setGarment({ ...garment, localFlags: { ...localFlags, inCloset: false } })
  }

  const onSetTabsHeight = (height: number) => {
    if (height > tabsHeight) {
      setTabsHeight(height)
    }
  }

  const changeTab = (newActiveTab: number) => {
    setActiveTab(newActiveTab)
    const screenHeightWithoutTabNav = window.innerHeight - 62
    if (window.scrollY > screenHeightWithoutTabNav) {
      window.scrollTo(0, screenHeightWithoutTabNav + 3)
    }
  }

  const iWantItGarment = () => {
    onIWantIt(garment_code, upc)
    setGarment({ ...garment, iWantItClicked: true })
  }

  return (
    <div className="mainContent">

      <GarmentBasicInfo
        user={user}
        garment={garment}
        action={action}
        toggleWashFlag={toggleWashFlag}
        setWatchVideo={setWatchVideo}
        addGarmentToCloset={addGarmentToCloset}
        showBackButton={showBackButton}
      />

      <GarmentScreenTabButtons
        activeTab={activeTab}
        setActiveTab={(tab) => changeTab(tab)}
      />

      <div style={{ minHeight: tabsHeight }} >

        {activeTab === INFORMATION_TAB && (
          <GarmentInformation
            user={user}
            garment={garment}
            action={action}
            iWantIt={iWantItGarment}
            onSetTabsHeight={onSetTabsHeight}
            onGetReview={onGetReview}
            getReviewFetching={getReviewFetching}
            getReviewError={getReviewError}
            history={history}
          />
        )}

        {activeTab === CARE_TAB && (
          <GarmentCare bins={bins} onSetTabsHeight={onSetTabsHeight} />
        )}

        {activeTab === SUPPLY_CHAIN_TAB && (
          <GarmentSupplyChain supplyChain={supplyChain} onSetTabsHeight={onSetTabsHeight} />
        )}

      </div>

      {inCloset && (
        <RegularButton
          onClick={() => onShowAlert(
            "Remove garment",
            "Are you sure you want to delete this garment?",
            true,
            removeGarmentFromCloset,
          )}
          sizeClassName="regularButtonExtraSmall"
          buttonText="Remove garment"
          additionalClassName="removeGarmentButton"
          lowerCase={false}
          additionalButtonLabelClassName="buttonLabel danger"
        />
      )}

      {watchVideo && (
        <div className="modalVideoView">
          <div style={{ height: "30%" }} onClick={() => setWatchVideo(false)}></div>
          <iframe
            title="youTube video"
            style={{
              width: "100%",
              height: "40%",
            }}
            src={`https://www.youtube.com/embed/${video}?rel=0`}
            frameBorder="0"
          />
          <div style={{ height: "30%" }} onClick={() => setWatchVideo(false)}></div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  user: state.user.user,
  loadedGarment: state.garment.garment,
  garmentLoading: state.garment.garmentFetching,
  getReviewFetching: state.review.getReviewFetching,
  getReviewError: state.review.getReviewError,
  closetContent: state.closet.closetContent,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onAddToWash: (garment: IGarment & IGarmentShort) => dispatch(addToWash({ garment })),
    onRemoveFromWash: (garment: IGarment & IGarmentShort) => dispatch(removeFromWash({ garment })),
    onToggleWash: (garmentCode: string, added: boolean) => dispatch(
      toggleWash({ garment_code: garmentCode , added }),
    ),
    onGetGarment: (garmentCode: string, localFlags: ILocalFlags) => dispatch(
      getGarment.started({ garmentCode, localFlags }),
    ),
    onShowNotification: (notificationTitle: string, notificationBody: string) => dispatch(
      showNotification({ notificationTitle, notificationBody }),
    ),
    onIWantIt: (garmentCode: string, upc: string) => dispatch(iWantIt.started({ garmentCode, upc })),
    onShowAlert: (alertTitle: string, alertBody: string, deleteFlag: boolean, onAccept?: () => void) => dispatch(
      showAlert({ alertTitle, alertBody, deleteFlag, onAccept })),
    onAddGarment: (garment: IGarment) => dispatch(addGarment.started({ garment })),
    onRemoveGarment: (garmentCode: string, isLocal: boolean) => dispatch(
      removeGarment.started({ garment_code: garmentCode, isLocal })),
    onGetReview: (garmentId: number) => dispatch(getReview.started({garmentId})),
  }
)

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Garment) as any

