import React, { useState, useRef } from "react"

import "./styles/TextQuestion.css"

import { IAnswerSettings } from "../../reducers/ReviewModels"

interface IOwnProps {
  answerSettings: IAnswerSettings,
  onAnswer: (question: string, answer: any) => void,
  question: string,
  dataId: number,
}

const TextQuestion: React.FC<IOwnProps> = (props) => {

  const ref = useRef(null) as any

  const { answerSettings, onAnswer, question, dataId } = props

  const [answer, setAnswer] = useState("")
  const [maxNumOfLetter] = useState(answerSettings && answerSettings.maxValue ? answerSettings.maxValue : 500)

  return (
    <div className="textQuestionContainer" data-testid={`textQuestion${dataId}`}>
      <textarea
        data-testid={`textQuestionTextarea${dataId}`}
        ref={ref}
        onChange={(e) => setAnswer(e.target.value)}
        className="textQuestionInput"
        style={{ flex: 1, border: "none", width: "100%" }}
        autoFocus={false}
        placeholder="Enter your comment here"
        maxLength={maxNumOfLetter}
        value={answer}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            onAnswer(question, answer)
            ref.current.blur()
          }
        }}
        rows={4}
      />
    </div>
  )
}

export default TextQuestion
