import { call, all } from "redux-saga/effects"

/* ------------- Sagas ------------- */

import {
  watchGetCurrentUser,
  watchLogin,
  watchGetSync,
  watchAddAddress,
  watchDeleteAddress,
  watchSelectAddress,
  watchiWantIt,
} from "./UserSagas"

import {
  watchGetGarment,
  watchGetLabelImages,
} from "./GarmentSagas"

import {
  watchGetReview,
  watchSaveReview,
} from "./ReviewSagas"

import {
  watchGetRecycleOptions,
  watchGetDonateOptions,
  watchPostGarmentForRecycle,
  watchUnpostGarmentForRecycle,
} from "./PassItOnSagas"

import {
  watchAddGarment,
  watchRemoveGarment,
} from "./ClosetSagas"

/* ------------- Connect Types To Sagas ------------- */

export default function* rootSaga() {
  try {
    yield all([
      call(watchGetCurrentUser),
      call(watchLogin),
      call(watchGetSync),
      call(watchGetGarment),
      call(watchGetLabelImages),
      call(watchGetReview),
      call(watchSaveReview),
      call(watchGetRecycleOptions),
      call(watchGetDonateOptions),
      call(watchPostGarmentForRecycle),
      call(watchUnpostGarmentForRecycle),
      call(watchAddGarment),
      call(watchRemoveGarment),
      call(watchAddAddress),
      call(watchDeleteAddress),
      call(watchSelectAddress),
      call(watchiWantIt),
    ])
  } catch (err) {
    console.log("==============================")
    console.log(err)
    console.log("==============================")
  }
}

