import React from "react"

import "./styles/PassItOnGarmentView.css"

import { IGarment, IGarmentShort } from "../../reducers/GarmentModels"
import { GarmentRecycleDonateStatuses } from "../../reducers/PassItOnReducers"

import GarmentPhoto from "../GarmentPhoto"

interface IOwnProps {
  garment: IGarment & IGarmentShort,
  passItOnPhoto?: string,
  passItOnText?: string,
  garmentStatus?: number,
}

const PassItOnGarmentView: React.FC<IOwnProps> = (props) => {

  const { garment, passItOnPhoto, passItOnText, garmentStatus } = props
  const { GarmentPosted, CouponApproved, CouponUsed } = GarmentRecycleDonateStatuses

  const { name, brand_name, photos, base_photo } = garment

  return (
    <div className="passItOnGarmentContainer">
      <div className="passItOnGarmentPhotoContainer">
        <GarmentPhoto
          onClick={undefined}
          photos={photos}
          photo={base_photo}
          additionalClassName=""
          additionalPhotoClassName="passItOnGarmentPhoto"
        />
        {garmentStatus === CouponApproved && (
          <img className="passItOnStatusPhoto" src="/images/icons/coupon-available.svg" alt="Coupon Available" />
        )}
        {garmentStatus === CouponUsed && (
          <img className="passItOnStatusPhoto" src="/images/icons/coupon-used.svg" alt="Coupon Used" />
        )}
      </div>

      <div className="listItemContent">
        <div className="listItemText" style={{ justifyContent: "space-around", height: "100%" }}>
          <div style={{ flexDirection: "column" }}>
            <p className="H2" data-testid="recycleOrDonateGarmentName">{name}</p>
            <p className="small quiet" data-testid="recycleOrDonateGarmentBrandName">{brand_name}</p>
          </div>
          <div className="listItemContent passItOnStatusContent">
            {passItOnPhoto && <img className="listItemContentPhoto" src={passItOnPhoto} alt={passItOnText} />}
            <div className="passItOnStatusText">

              {! garmentStatus && passItOnPhoto && (
                <p className="small text" data-testid="noGarmentStatus">Waiting for garment to be dispatched.</p>
              )}

              {garmentStatus === GarmentPosted && (
                <div style={{ flexDirection: "column" }}>
                  <p className="small text">{`Garment sent for ${passItOnText}.`} </p>
                  <p className="small text">Awaiting confirmation</p>
                </div>
              )}

              {garmentStatus === CouponApproved && (
                <div style={{ flexDirection: "column" }}>
                  <p className="small text">Garment received</p>
                  <p className="small text">Coupon available.</p>
                </div>
              )}

              {garmentStatus === CouponUsed && (
                <div style={{ flexDirection: "column" }}>
                  <p className="small text">Garment received</p>
                  <p className="small text">Coupon used.</p>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PassItOnGarmentView
