import React, { useEffect, useCallback } from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import { RootState } from "../../reducers"
import { ActionTypes } from "../../reducers/PassItOnReducers"
import { IGarment, IGarmentShort } from "../../reducers/GarmentModels"
import RecycleOrDonateCoupon from "./RecycleOrDonateCoupon"
import { IUser, IUserAddress } from "../../reducers/UsersModels"
import { ICharity } from "../../reducers/PassItOnModels"
import { getRecycleOptions, selectAppropriateStatus, getDonateOptions } from "../../reducers/PassItOnActions"

import PassItOnGarmentView from "./PassItOnGarmentView"
import RecycleOrDonateInstructions from "./RecycleOrDonateInstructions"
import SettingsItem from "../settings/SettingsItem"
import BackButton from "../BackButton"

interface IOwnProps {
  history: any,
  garment: IGarment & IGarmentShort,
  item: any,
}

interface IStateProps {
  userBasicInfo?: IUser,
  garmentRecycleDonateStatus: number,
  selectedCharity?: ICharity,
  activeAddress?: IUserAddress,
}

interface IDispatchProps {
  onGetRecycleOptions: (garmentId: number) => void,
  onGetDonateOptions: (garmentId: number) => void,
  onSelectAppropriateStatus: (recycleDonateType: number, garment: IGarment) => void,
}

const RecycleOrDonateContainer: React.FC<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const {
    history, garment, item, userBasicInfo,
    garmentRecycleDonateStatus, selectedCharity,
    onGetRecycleOptions, activeAddress, onGetDonateOptions, onSelectAppropriateStatus,
  } = props

  const { type } = item
  const { name, photo, text } = item

  const onGetOptionsCallback = useCallback(() => {
    onSelectAppropriateStatus(type, garment)
    if (type) {
      if (type === ActionTypes.recycle) {
        onGetRecycleOptions(garment.id)
      } else if (type === ActionTypes.donate) {
        onGetDonateOptions(garment.id)
      }
    }
  }, [onSelectAppropriateStatus, type, onGetRecycleOptions, onGetDonateOptions, garment])

  useEffect(() => {
    onGetOptionsCallback()
  }, [onGetOptionsCallback])

  return (
    <>
      <BackButton goBackAction={history.goBack} additionalClassName="backButtonContainer withoutBottomPadding" />
      <div className="passItOnContainer" data-testid="passItOnContainer">
        <div className="passItOnContent">
          <p className="title introText" style={{ textAlign: "left", paddingRight: "16px" }}>
            Thank you for choosing to {item.name}!
          </p>
          <p className="body introText">
            All you need to do is post the garment to the address manufacturer provided, and they will take care of the
            rest.
          </p>

          <PassItOnGarmentView
            garment={garment}
            passItOnPhoto={photo}
            passItOnText={text}
            garmentStatus={garmentRecycleDonateStatus}
          />

          {text && (
            <p className="section-upcase passItOnInstructionsHeading">{text.toUpperCase()}</p>
          )}


          {name === "donate" && (
            <SettingsItem
              title="Choose a charity"
              text={selectedCharity ? selectedCharity.name : ""}
              onClick={() => history.push("/settings/charities")}
              extraText={undefined}
              extraLinkText={undefined}
            />
          )}

          <RecycleOrDonateInstructions
            type={type}
            userBasicInfo={userBasicInfo}
            garment={garment}
            userAddress={activeAddress}
            garmentStatus={garmentRecycleDonateStatus}
          />

        </div>
      </div>

      <RecycleOrDonateCoupon garmentStatus={garmentRecycleDonateStatus} garmentBrand={garment.brand_name} />
    </>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  userBasicInfo: state.user.user,
  garmentRecycleDonateStatus: state.passItOn.garmentRecycleDonateStatus,
  selectedCharity: state.passItOn.selectedCharity,
  activeAddress: state.user.activeAddress,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onGetRecycleOptions: (garmentId: number) => dispatch(getRecycleOptions.started({ garmentId })),
    onGetDonateOptions: (garmentId: number) => dispatch(getDonateOptions.started({ garmentId })),
    onSelectAppropriateStatus: (recycleDonateType: number, garment: IGarment) => dispatch(
      selectAppropriateStatus({ recycleDonateType, garment },
      )),
  }
)



export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RecycleOrDonateContainer) as any
