import React, { useState, useRef, useEffect, useCallback } from "react"

interface IOwnProps {
  title: string,
  itemId: string,
  nextItemId?: string,
  text: string,
  getTextCallback: (itemId: string, text: string) => any,
  getInputsCallback: (itemId: string, inputRef: any) => any,
  onEnterCallback: (inputRef: any, itemId: string, nextItemId?: string) => void,
}

const SettingsAddressItem: React.FC<IOwnProps> = (props) => {

  const { title, itemId, nextItemId, text, getInputsCallback, getTextCallback, onEnterCallback } = props

  const inputTextRef = useRef(null) as any

  const [currentText, setCurrentText] = useState(text)

  const onGetInputsCallback = useCallback(() => {
    if (inputTextRef.current) {
      getInputsCallback(itemId, inputTextRef.current)
    }
  }, [inputTextRef, itemId])

  useEffect(() => {
    onGetInputsCallback()
  }, [onGetInputsCallback])

  return (
    <div className="settingsItemMain" >
      <div style={{ flexDirection: "column" }}>
        <p className="small quiet">{title}</p>

        <input
          data-testid={title}
          ref={inputTextRef}
          className="body editAddressItemInput"
          type="text"
          value={currentText}
          onBlur={(e) => getTextCallback(itemId, e.target.value)}
          onChange={(e) => setCurrentText(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              onEnterCallback(inputTextRef.current, itemId, nextItemId)
            }
          }}
        />
      </div>
    </div>
  )
}

export default SettingsAddressItem
