import actionCreatorFactory from "typescript-fsa"

import { IGarment, IGarmentShort } from "./GarmentModels"

const actionCreator = actionCreatorFactory("PassItOn")

export const recycleGarment = actionCreator<{garment: IGarment & IGarmentShort}>("RECYCLE_GARMENT")

export const donateGarment = actionCreator<{garment: IGarment  & IGarmentShort}>("DONATE_GARMENT")

export const changeStatus = actionCreator<
                                          {garmentCode: string, newStatus: number, recycleDonateType: number}
                                        >("CHANGE STATUS")

export const selectCharity = actionCreator<{selectedCharityId: number}>("SELECT_CHARITY")

export const selectAppropriateStatus =
  actionCreator<{recycleDonateType: number, garment: IGarment}>("SELECT_APPROPRIATE_STATUS")

export const selectAppropriateGarments = actionCreator<{recycleDonateType: number}>("SELECT_APPROPRIATE_GARMENTS")

export const markGarmentUnposted = actionCreator<
                                                  {garmentCode: string,  recycleDonateType: number}
                                                >("MARK_GARMENT_UNPOSTED")

export const getRecycleOptions = actionCreator.async<{ garmentId: number },
                                                    { recycleOptions: string },
                                                    void
                                                    >("GET_RECYCLE_OPTIONS")

export const getDonateOptions = actionCreator.async<{ garmentId: number },
                                                   { donateOptions: string },
                                                   void
                                                   >("GET_DONATE_OPTIONS")

export const sellGarment = actionCreator<{garment: IGarment}>("SELL_GARMENT")

export const postGarmentForRecycle = actionCreator.async<{ postedGarmentData: any },
                                                        { postGarmentForRecycleSuccessMessage: string },
                                                        void
                                                        >("POST_GARMENT_FOR_RECYCLE")

export const unpostGarmentForRecycle = actionCreator.async<{ garmentCode: string },
                                                        { unpostGarmentForRecycleSuccessMessage: string },
                                                        void
                                                        >("UNPOST_GARMENT_FOR_RECYCLE")

export const clearRecyclingPostUnpost = actionCreator<void>("CLEAR_RECYCLING_POST_UNPOST")
