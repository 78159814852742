import React from "react"
// import { useState, useEffect, useCallback } from "react"
import { connect } from "react-redux"

import "./styles/PassItOnScreen.css"

import { RootState } from "../reducers"
import { ActionTypes } from "../reducers/PassItOnReducers"
import { IGarment } from "../reducers/GarmentModels"

import RecycleOrDonateContainer from "../components/passItOn/RecycleOrDonateContainer"
// import SellContainer from "../components/passItOn/SellContainer"
// import GarmentPostedForSellContainer from "../components/passItOn/GarmentPostedForSellContainer"


interface IOwnProps {
  history: any,
  location: any,
}

interface IStateProps {
  garmentsForSell: IGarment[]
}

const PassItOn: React.FC<IOwnProps & IStateProps> = (props) => {

  const {
    history, location,
    // garmentsForSell
  } = props

  const { state } = location
  // const [garmentForSell, setGarmentForSell] = useState(undefined) as any

  // const setGarmentForSellCallback = useCallback(() => {
  //   if (garmentsForSell) {
  //     setGarmentForSell(garmentsForSell.find(
  //       (itemForSell) => itemForSell.garment_code === state.garment.garment_code,
  //     ))
  //   }
  // }, [garmentsForSell, setGarmentForSell, state.garment.garment_code])

  // useEffect(() => {
  //   setGarmentForSellCallback()
  // }, [setGarmentForSellCallback])

  if (! state) {
    return null
  }

  const { type, garment } = state

  let item = null
  if (type === ActionTypes.recycle) {
    item = { name: "recycle", type, photo: "/images/icons/recycle.svg", text: "recycling" }
  } else if (type === ActionTypes.donate) {
    item = { name: "donate", type: 2, photo: "/images/icons/donate.svg", text: "donating" }
  } else if (type === ActionTypes.sell) {
    item = { name: "sell" }
  }


  return (
    <div style={{ flexDirection: "column", flex: 1 }}>

      {item && item.name !== "sell" && (
        <RecycleOrDonateContainer
          history={history}
          garment={garment}
          item={item}
        />
      )}
{/*
      {! garmentForSell && item && item.name === "sell" && (
        <SellContainer
          history={history}
          garment={garment}
        />
      )}

      {garmentForSell && (
        <GarmentPostedForSellContainer
          garmentForSell={garmentForSell}
          goBackAction={() => setGarmentForSell(undefined)}
        />
      )} */}
    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  garmentsForSell: state.passItOn.garmentsForSell,
})

export default
  connect(
    mapStateToProps,
    null,
  )(PassItOn) as any
