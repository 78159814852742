import actionCreatorFactory from "typescript-fsa"
import { IGarment } from "./GarmentModels"

const actionCreator = actionCreatorFactory("Closet")

export const clearCloset = actionCreator<void>("CLEAR_CLOSET")

export const sendClosetInfo = actionCreator.async<{ closetInfo: string[] },
                                                  void,
                                                  void
                                                  >("SEND_CLOSET_INFO")

export const updateGarment = actionCreator<{ garment: IGarment }>("UPDATE_GARMENT")

export const addGarment = actionCreator.async<{ garment: IGarment },
                                              void,
                                              void
                                              >("ADD_GARMENT")

export const removeGarment = actionCreator.async<{ garment_code: string, isLocal: boolean },
                                                void,
                                                void
                                                >("REMOVE_GARMENT")

export const searchCloset = actionCreator<{ term: string }>("SEARCH_CLOSET")
export const clearSearch = actionCreator<void>("CLEAR_SEARCH")

export const toggleWash = actionCreator<{ garment_code: string, added: boolean }>("TOGGLE_WASH")
export const clearWash = actionCreator<void>("CLEAR_WASH")

export const filterClosetWithBins = actionCreator<{ selectedBinId: number }>("FILTER_CLOSET_WITH_BINS")
export const clearFilterBins = actionCreator<void>("CLEAR_FILTER_BINS")

export const showGridView = actionCreator<{ gridView: boolean }>("SHOW_GRID_VIEW")

export const setShowSearchResults = actionCreator<{ showSearchResults: boolean }>("SHOW_SEARCH_RESULTS")
export const fullSearch = actionCreator<{ term: string }>("FULL_SEARCH")
export const clearFullSearch = actionCreator<void>("CLEAR_FULL_SEARCH")

export const iWantIt = actionCreator.async<{garmentCode: string, upc: string},
                                            void,
                                            void
                                            >("I_WANT_IT")
