import React from "react"

import "./styles/Empty.css"

interface IOwnProps {
  image?: string,
  title?: string,
  text: string,
  small: boolean,
  marginBottom?: number,
}

const Empty: React.FC<IOwnProps> = (props) => {

  const { image, title, text, small, marginBottom } = props

  return (
    <div className="emptyScreenContainer" style={small ? { paddingTop: "30px" } : { paddingTop: "80px" }}>

      {image &&
        <div className={small ? "emptyImageContainerSmall" : "emptyImageContainer"}>
          <img src={image} alt={image} style={{ width: "100%", height: "100%" }} />
        </div>
      }

      <div className="emptyTextContainer" style={{ marginBottom }}>
        <p className="H2 emptyTitle text" data-testid="emptyScreenTitle">{title}</p>
        <p data-testid="emptyText" className="body quiet" style={{ textAlign: "center" }}>{text}</p>
      </div>

    </div>
  )
}
export default Empty
