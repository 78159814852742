import React from "react"

import "./styles/MultipleChoiceItem.css"

import { IReward } from "../../reducers/ReviewModels"

interface IOwnProps {
  item: IReward,
  chooseItem: (id: number) => void,
  chosenRewardId?: number,
  setChosenRewardId: (chosenRewardId: number) => void
}

const MultipleChoiceItem: React.FC<IOwnProps> = (props) => {

  const { item, chooseItem, chosenRewardId, setChosenRewardId } = props

  const { id, reward } = item

  const onChooseItem = () => {
    setChosenRewardId(id)
    chooseItem(id)
  }

  return (
    <div
      className="button multipleChoiceItem"
      style={chosenRewardId === id ? { borderColor: "#2d9cdb" } : { borderColor: "#999999" }}
      onClick={onChooseItem}
      data-testid={`reward${id}`}
    >
      <p className="">{reward}</p>
      <img
        className="icon32"
        src={chosenRewardId === id ? "/images/icons/ok-tertiary.svg" : "/images/icons/ok-quiet.svg"}
        alt={chosenRewardId === id ? "checked" : "unchecked"}
      />
    </div>
  )
}

export default MultipleChoiceItem
