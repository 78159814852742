import React, { useEffect, useCallback } from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import "./styles/RecycleOrDonateInstructions.css"

import { RootState } from "../../reducers"
import { IUser } from "../../reducers/UsersModels"
import { addGarment, removeGarment } from "../../reducers/ClosetActions"
import { IGarment, IGarmentShort } from "../../reducers/GarmentModels"
import {
  markGarmentUnposted, changeStatus, recycleGarment, donateGarment,
  postGarmentForRecycle, unpostGarmentForRecycle, clearRecyclingPostUnpost,
} from "../../reducers/PassItOnActions"
import { ICharity } from "../../reducers/PassItOnModels"
import { ActionTypes, GarmentRecycleDonateStatuses } from "../../reducers/PassItOnReducers"

import RegularButton from "../RegularButton"
import GradientButton from "../GradientButton"
import Loading from "../Loading"

interface IOwnProps {
  type: any,
  garmentStatus?: number,
  userBasicInfo?: IUser,
  userAddress: any,
  garment: IGarment & IGarmentShort,
}

interface IStateProps {
  recycleOptions: any,
  recycleOptionsLoading: boolean,
  recycleOptionsError: boolean,
  selectedCharity?: ICharity,

  postGarmentForRecycleLoading: boolean,
  postGarmentForRecycleError: boolean,
  postGarmentForRecycleSuccessMessage?: string,

  unpostGarmentForRecycleLoading: boolean,
  unpostGarmentForRecycleError: boolean,
  unpostGarmentForRecycleSuccessMessage?: string,
}

interface IDispatchProps {
  onRecycleGarment: (garment: IGarment & IGarmentShort) => void,
  onDonateGarment: (garment: IGarment & IGarmentShort) => void,
  onRemoveGarment: (garmentCode: string, isLocal: boolean) => void,
  onAddGarment: (garment: IGarment) => void,
  onMarkGarmentUnposted: (garmentCode: string, recycleDonateType: number) => void,
  onChangeStatus: (garmentCode: string, newStatus: number, recycleDonateType: number) => void,
  onPostGarmentForRecycle: (postedGarmentData: any) => any,
  onUnpostGarmentForRecycle: (garmentCode: string) => any,
  onClearRecyclingPostUnpost: () => void,
}

const RecycleOrDonateInstructions: React.FC<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const {
    type, garmentStatus, userBasicInfo, userAddress, garment,
    recycleOptions, recycleOptionsLoading, recycleOptionsError, selectedCharity,
    onRecycleGarment, onDonateGarment, onAddGarment, onRemoveGarment, onClearRecyclingPostUnpost,
    onChangeStatus, onMarkGarmentUnposted, onPostGarmentForRecycle, onUnpostGarmentForRecycle,
    postGarmentForRecycleLoading, postGarmentForRecycleError, postGarmentForRecycleSuccessMessage,
    unpostGarmentForRecycleLoading, unpostGarmentForRecycleError, unpostGarmentForRecycleSuccessMessage,
  } = props

  const onClearRecyclingPostUnpostCallback = useCallback(() => {
    onClearRecyclingPostUnpost()
  }, [onClearRecyclingPostUnpost])

  const postGarmentForRecycleSuccessCallback = useCallback(() => {
    if (postGarmentForRecycleSuccessMessage) {
      onRecycleGarment(garment)
      onRemoveGarment(garment.garment_code, true)
    }
  }, [postGarmentForRecycleSuccessMessage, onRecycleGarment, onRemoveGarment, garment])

  const unpostGarmentForRecycleSuccessCallback = useCallback(() => {
    if (unpostGarmentForRecycleSuccessMessage
      && unpostGarmentForRecycleSuccessMessage !== "Garment already received by brand."
    ) {
      onMarkGarmentUnposted(garment.garment_code, type)
      onAddGarment(garment)
    }
  }, [unpostGarmentForRecycleSuccessMessage, onMarkGarmentUnposted, onAddGarment, garment, type])

  useEffect(() => {
    onClearRecyclingPostUnpostCallback()
  }, [onClearRecyclingPostUnpostCallback])

  useEffect(() => {
    postGarmentForRecycleSuccessCallback()
  }, [postGarmentForRecycleSuccessCallback])

  useEffect(() => {
   unpostGarmentForRecycleSuccessCallback()
  }, [unpostGarmentForRecycleSuccessCallback])

  const garmentPostedPressed = () => {

    if (type === ActionTypes.recycle) {
      onPostGarmentForRecycle({
        garmentId: garment.id,
        garmentCode: garment.garment_code,
        brandId: garment.brand_id,
        userAddress: showUserAddress(userBasicInfo, userAddress),
      })
    } else if (type === ActionTypes.donate) {
      onDonateGarment(garment)
      onRemoveGarment(garment.garment_code, true)
    }

    onRemoveGarment(garment.garment_code, true)
  }

  const showAddressView = (
    name: string, address1: string, city: string, country: string, zip: string, state: string,
  ) => {
    return (
      <>
        {name} <br />
        {address1} <br />
        {city}, {country}, {zip} <br />
        {state}
      </>
    )
  }

  const showRecycleDonateAddress = (chosenAddress: any) => {
    if (chosenAddress) {
      return (
        <p className="H2 text" style={{ whiteSpace: "pre-wrap" }}>
          {chosenAddress}
        </p>
      )
    } else {
      return null
    }
  }

  const handleRecycleOptions = () => {

    if (! recycleOptions) {
      return null
    }

    if (recycleOptionsLoading) {
      return <Loading small={true} text="" />
    } else if (recycleOptionsError) {
      return (
        <p>There has been an error while getting recycle address.</p>
      )
    }

    const { recycleAddress } = recycleOptions

    return showRecycleDonateAddress(recycleAddress)
  }

  const showUserAddress = (basicInfo: any, address: any) => {
    if (basicInfo && address) {
      const { name } = basicInfo
      const { address1, city, country, state, zip } = address
      return showAddressView(name, address1, city, country, zip, state)
    } else if (basicInfo) {
      return basicInfo.name
    }
  }

  const markUnpostedPressed = () => {

    if (type === ActionTypes.recycle) {
      onUnpostGarmentForRecycle(garment.garment_code)
    } else if (type === ActionTypes.donate) {
      onMarkGarmentUnposted(garment.garment_code, type)
      onAddGarment(garment)
    }
  }

  let donateAddress = ""
  if (selectedCharity) {
    donateAddress = selectedCharity.address
  }

  return (
    <div className="instructionsMainContainer">

      <div className="listItemMainView instructionsAddress">
        <img className="instructionsAddressPhoto" src="/images/icons/address-box.png" alt="Address to post" />
        <div className="instructionsAddressText">
          <p className="small quiet" style={{ marginBottom: "8px" }}>Post to:</p>
          <div data-testid="recycleOrDonateAddress">
            {type === ActionTypes.recycle && handleRecycleOptions()}
            {type === ActionTypes.donate && showRecycleDonateAddress(donateAddress)}
          </div>
        </div>
      </div>

      <div className="listItemMainView instructionsAddress">
        <img className="instructionsAddressPhoto" src="/images/icons/user.svg" alt="User address" />
        <div className="instructionsAddressText">

          <p className="small quiet" style={{ marginBottom: "8px" }}>Return information:</p>
          <p className="H2 text" data-testid="recycleOrDonateUserAddress">
            {showUserAddress(userBasicInfo, userAddress)}
          </p>
          <p className="small quiet" style={{ paddingTop: "8px" }}>
            You can change your return address information in settings.
          </p>

          <div className="instructionsAddressButtons">
            {/* <RegularButton
              onClick={
                () => window.location.href = "https://storage.googleapis.com/cacotec_public_photos/LabelExample.jpg"
              }
              sizeClassName="regularButtonExtraSmall"
              buttonText="Download label"
              additionalClassName="downloadLabelButton"
              lowerCase={false}
              additionalButtonLabelClassName="section-upcase linkBlue"
            /> */}

            {! garmentStatus && (
              <GradientButton
                additionalClassName=""
                buttonText="Garment posted"
                onClick={() => garmentPostedPressed()}
                gradientClassName="gradientGreen"
                sizeClassName="gradientButtonExtraSmall"
                additionalButtonLabelClassName="alt"
              />
            )}

            {garmentStatus && (
              <RegularButton
                onClick={() => markUnpostedPressed()}
                sizeClassName="regularButtonExtraSmall"
                buttonText="Mark unposted"
                additionalClassName="markUnpostedButton"
                lowerCase={false}
                additionalButtonLabelClassName="section-upcase linkBlue"
              />
            )}

          </div>

          {(postGarmentForRecycleLoading || unpostGarmentForRecycleLoading) && (
            <Loading text={undefined} small={true} />
          )}

          {! postGarmentForRecycleLoading && ! unpostGarmentForRecycleLoading && (
            <>
              {postGarmentForRecycleSuccessMessage && <p>{postGarmentForRecycleSuccessMessage}</p>}
              {postGarmentForRecycleError && <p>There has been error while posting garment.</p>}
              {unpostGarmentForRecycleSuccessMessage && <p>{unpostGarmentForRecycleSuccessMessage}</p>}
              {unpostGarmentForRecycleError && <p>There has been error while unposting garment.</p>}
            </>
          )}
        </div>
      </div>

    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  recycleOptions: state.passItOn.recycleOptions,
  recycleOptionsLoading: state.passItOn.recycleOptionsLoading,
  recycleOptionsError: state.passItOn.recycleOptionsError,
  selectedCharity: state.passItOn.selectedCharity,
  postGarmentForRecycleLoading: state.passItOn.postGarmentForRecycleLoading,
  postGarmentForRecycleError: state.passItOn.postGarmentForRecycleError,
  postGarmentForRecycleSuccessMessage: state.passItOn.postGarmentForRecycleSuccessMessage,

  unpostGarmentForRecycleLoading: state.passItOn.unpostGarmentForRecycleLoading,
  unpostGarmentForRecycleError: state.passItOn.unpostGarmentForRecycleError,
  unpostGarmentForRecycleSuccessMessage: state.passItOn.unpostGarmentForRecycleSuccessMessage,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onRecycleGarment: (garment: IGarment & IGarmentShort) => dispatch(recycleGarment({ garment })),
    onDonateGarment: (garment: IGarment & IGarmentShort) => dispatch(donateGarment({ garment })),
    onRemoveGarment: (garmentCode: string, isLocal: boolean) => dispatch(
      removeGarment.started({ garment_code: garmentCode, isLocal })),
    onAddGarment: (garment: IGarment) => dispatch(addGarment.started({ garment })),
    onMarkGarmentUnposted: (garmentCode: string, recycleDonateType: number) =>
      dispatch(markGarmentUnposted({ garmentCode, recycleDonateType })),
    onChangeStatus:
      (garmentCode: string, newStatus: number, recycleDonateType: number) => dispatch(
        changeStatus({ garmentCode, newStatus, recycleDonateType }),
      ),
    onPostGarmentForRecycle: (postedGarmentData: any) => dispatch(postGarmentForRecycle.started({ postedGarmentData })),
    onUnpostGarmentForRecycle: (garmentCode: string) => dispatch(unpostGarmentForRecycle.started({ garmentCode })),
    onClearRecyclingPostUnpost: () => dispatch(clearRecyclingPostUnpost()),
  }
)

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RecycleOrDonateInstructions) as any

