import React, { useEffect, useState, useCallback } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Route, Switch } from "react-router"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import "./styles/App.css"

import { RootState } from "../reducers"
import { IUser } from "../reducers/UsersModels"
import { getReview } from "../reducers/ReviewActions"

import { getCurrentUserInfo } from "../reducers/UsersActions"
import { getSync } from "../reducers/SyncActions"
import AuthenticatedRoute from "../components/AuthenticatedRoute"
import UnauthenticatedRoute from "../components/UnauthenticatedRoute"
import { IReviewToDo } from "../reducers/ReviewModels"

import ClosetScreen from "./ClosetScreen"
import LoginScreen from "./LoginScreen"
import LoginScreen2 from "./LoginScreen2"
import SearchScreen from "./SearchScreen"
import HelpScreen from "./HelpScreen"
import GarmentScreen from "./GarmentScreen"
import LabelImagesScreen from "./LabelImagesScreen"
import SettingsScreen from "./SettingsScreen"
import WashingListScreen from "./WashingListScreen"
import TabNav from "../nav/TabNav"
import Notification from "../components/Notification"
import Alert from "../components/Alert"
import Loading from "../components/Loading"
import Review from "../components/review/Review"
import PassItOnScreen from "./PassItOnScreen"
import ChoosePassItOn from "./ChoosePassItOnScreen"
import SettingsRecycleDonateList from "../components/settings/SettingsRecycleDonateList"
import SettingsDonate from "../components/settings/SettingsDonate"
import SettingsCharitiesList from "../components/settings/SettingsCharitiesList"
import SettingsAddressesScreen from "../components/settings/SettingsAddressesScreen"
import SettingsEditAddress from "../components/settings/SettingsEditAddress"

interface ILocationParams {
  pathname: string,
}

interface IStateProps {
  user?: IUser,
  isLoadingCurrentUser: boolean,
  reviewToDo?: IReviewToDo,
}

interface IDispatchProps {
  onGetCurrentUserInfo: () => void,
  onGetReview: (garmentId: number) => void,
  onGetSync: () => void,
}

const TRIGGER_REVIEW_ROUTE_CHANGES = Math.floor(Math.random() * 21) + 10 // random number 10 - 30

const App: React.FC<IStateProps & IDispatchProps & RouteComponentProps<ILocationParams>> = (props) => {

  const { user, isLoadingCurrentUser, location, onGetCurrentUserInfo, reviewToDo, onGetReview, onGetSync } = props

  const [numberOfRouteChanges, setNumberOfRouteChanges] = useState(0)

  // get the inner height of the viewport (for the mobile browsers)
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty("--vh", `${vh}px`)

  const getUserOrSyncCallback = useCallback(() => {
    if (user == null) {
      onGetCurrentUserInfo()
    } else {
      onGetSync()
    }
  }, [user, onGetCurrentUserInfo, onGetSync])

  const setNumberOfRouteChangesCallback = useCallback(() => {
    if (location) {
      setNumberOfRouteChanges((numberOfRoutes: number) => {
        if (numberOfRoutes > TRIGGER_REVIEW_ROUTE_CHANGES) {
          onGetReview(-1)
          return 0
        } else {
          return numberOfRoutes + 1
        }
      })
    }

  }, [setNumberOfRouteChanges, onGetReview, location])

  useEffect(() => {
    getUserOrSyncCallback()
  }, [getUserOrSyncCallback])

  useEffect(() => {
    setNumberOfRouteChangesCallback()
  }, [setNumberOfRouteChangesCallback])

  if (isLoadingCurrentUser) {
    return <Loading text={undefined} small={false} />
  }

  const { pathname } = location

  return (
    <main className="mainContent">
      <div className="content">
        <Switch>
          <Route exact path="/g/:garmentCode" component={GarmentScreen} props={props} />
          <Route exact path="/li/:orderItemId" component={LabelImagesScreen} props={props} />

          <AuthenticatedRoute exact path="/closet" component={ClosetScreen} props={props} />
          <AuthenticatedRoute exact path="/search" component={SearchScreen} props={props} />
          <AuthenticatedRoute exact path="/garment/:garmentCode" component={GarmentScreen} props={props} />
          <AuthenticatedRoute exact path="/garment/:garmentCode/:action" component={GarmentScreen} props={props} />
          <AuthenticatedRoute exact path="/garment" component={GarmentScreen} props={props} />
          <AuthenticatedRoute exact path="/settings/addresses" component={SettingsAddressesScreen} props={props} />
          <AuthenticatedRoute
            exact path="/settings/addresses/:addressName"
            component={SettingsEditAddress}
            props={props}
          />
          <AuthenticatedRoute exact path="/settings/charities" component={SettingsCharitiesList} props={props} />
          <AuthenticatedRoute exact path="/settings/donate" component={SettingsDonate} props={props} />
          <AuthenticatedRoute
            exact path="/settings/:type"
            component={SettingsRecycleDonateList}
            props={props}
          />
          <AuthenticatedRoute exact path="/settings" component={SettingsScreen} props={props} />
          <AuthenticatedRoute exact path="/washing" component={WashingListScreen} props={props} />
          <AuthenticatedRoute exact path="/passItOn/:type" component={PassItOnScreen} props={props} />
          <AuthenticatedRoute exact path="/passItOn" component={ChoosePassItOn} props={props} />

          <AuthenticatedRoute exact path="/help" component={HelpScreen} props={props} />

          <UnauthenticatedRoute exact path="/login" component={LoginScreen} props={props} />
          <UnauthenticatedRoute exact path="/login2" component={LoginScreen2} props={props} />

          <AuthenticatedRoute path="*" component={ClosetScreen} props={props} />
        </Switch>
      </div>

      <TabNav pathname={pathname}/>
      <Notification />
      {reviewToDo && <Review reviewToDo={reviewToDo} />}
      <Alert />
    </main>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  user: state.user.user,
  isLoadingCurrentUser: state.user.isLoadingCurrentUser,
  reviewToDo: state.review.reviewToDo,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onGetCurrentUserInfo: () => dispatch(getCurrentUserInfo.started()),
    onGetReview: (garmentId: number) => dispatch(getReview.started({garmentId})),
    onGetSync: () => dispatch(getSync.started()),
  }
)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App) as any,
)
