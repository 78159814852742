import React, { useState, useEffect, useRef, useCallback } from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"
import smoothscroll from "smoothscroll-polyfill"

import "./styles/Review.css"

import { RootState } from "../../reducers"
import { IGarment } from "../../reducers/GarmentModels"
import { IQuestion } from "../../reducers/ReviewModels"
import { clearReview, saveReview } from "../../reducers/ReviewActions"

import ReviewGarmentPreview from "./ReviewGarmentPreview"
import ReviewItem from "./ReviewItem"
import ReviewSendButton from "./ReviewSendButton"
import ReviewChooseReward from "./ReviewChooseReward"

const REVIEW_STATES = {
  INITIALIZED: 1,
  FINISHED_END: 2,
  FINISHED_REWARDS: 3,
  REWARDS: 4,
}

interface IOwnProps {
  reviewToDo: any,
}

interface IStateProps {
  garment?: IGarment,
  closet: IGarment[],
  saveReviewFetching: boolean,
  saveReviewError: boolean,
  saveReviewSuccessMessage?: string,
}

interface IDispatchProps {
  onClearReview: () => void,
  onSaveReview: (answersToSave: any) => void,
}

const Review: React.FC<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const {
    garment, closet, reviewToDo, onClearReview,
    saveReviewFetching, saveReviewError, onSaveReview,
  } = props

  const { questions, rewards, garmentId, reviewId } = reviewToDo

  const [answers, setAnswers] = useState({}) as any
  const [garmentToReview, setGarmentToReview] = useState(undefined) as any
  const [reviewState, setReviewState] = useState(REVIEW_STATES.INITIALIZED)
  const [questionsHeights, setQuestionsHeights] = useState({}) as any
  const [scrollHeight, setScrollHeight] = useState(0)
  const [distanceToScroll, setDistanceToScroll] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(-1)

  const [chosenRewardId, setChosenRewardId] = useState(undefined) as any

  const reviewContentRef = useRef(null) as any

  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  const setGarmentToReviewCallback = useCallback(() => {
    if (garment) {
      setGarmentToReview(garment)
    } else {
      setGarmentToReview(closet.find((garmentInCloset) => garmentInCloset.id === garmentId))
    }
  }, [garment, garmentId, setGarmentToReview, closet])

  useEffect(() => {
    setGarmentToReviewCallback()
  }, [setGarmentToReviewCallback])


  const getQuestionHeightCallback = (questionId: number, height: number) => {
    setQuestionsHeights((currentQuestionsHeights: any) => ({ ...currentQuestionsHeights, [questionId]: height }))
  }

  const getGarmentPreviewHeight = (height: number) => {
    setScrollHeight(height + questionsHeights[Object.keys(questionsHeights)[0]])
  }

  const screenHeight = window.innerHeight - 45

  const onAnswerReceived = (question: string, answer: string) => {

    // Check if it's a new answer or the user is changing the previous answer
    let isNewAnswer = false
    if (question && ! answers[question]) {
      isNewAnswer = true
    }

    // Add the new answer to the answers list
    if (question) {
      setAnswers((currentAnswers: any) => ({ ...currentAnswers, [question]: answer }))
    }

    let index = currentIndex
    if (isNewAnswer && question) {
      setCurrentIndex(currentIndex + 1)
      index = currentIndex + 1
    }

    if (questions.length === index + 1) {
      if (rewards && rewards.length > 0) {
        setReviewState(REVIEW_STATES.FINISHED_REWARDS)
      } else {
        setReviewState(REVIEW_STATES.FINISHED_END)
      }
      return
    }

    if (isNewAnswer) {
      const nextQuestionHeight = questionsHeights[questions[index + 1].id]
      const currentContainerHeight = scrollHeight + nextQuestionHeight
      setScrollHeight(currentContainerHeight)


      if (currentContainerHeight >= screenHeight) {
        // We need to translate the question container up for the height of the question
        const newDistance = distanceToScroll + (currentContainerHeight - screenHeight)
        setDistanceToScroll(newDistance)
        setScrollHeight(screenHeight)
        reviewContentRef.current.scrollTo({ top: newDistance, behavior: "smooth" })
      }
    }

  }

  const showButton = (height: number) => {
    if (scrollHeight < screenHeight) {
      setScrollHeight(scrollHeight + height)
    } else {
      reviewContentRef.current.scrollTo({ top: distanceToScroll + height, behavior: "smooth" })
    }
  }

  const submitAnswers = () => {
    const answersToSend = [] as any
    Object.keys(answers).map((question) =>
      answersToSend.push({ question, answer: answers[question] }),
    )

    if (reviewState === REVIEW_STATES.REWARDS) {
      if (chosenRewardId) {
        onSaveReview({ garmentId, answers: answersToSend, chosenRewardId, reviewId })
      } else {
        setChosenRewardId(0)
      }
    } else {
      onSaveReview({ garmentId, answers: answersToSend, reviewId })
    }
  }

  return (
    <div data-testid="review">

      {reviewState === REVIEW_STATES.REWARDS && (
        <ReviewChooseReward
          rewards={rewards}
          chooseReward={(rewardId: number) => setChosenRewardId(rewardId)}
          onSubmitAnswer={submitAnswers}
          loading={saveReviewFetching}
          chosenRewardId={chosenRewardId}
          clearReview={onClearReview}
        />
      )}

      {reviewState !== REVIEW_STATES.REWARDS && (
        <div className="gradientGreenTransparent reviewContainer">
          <div
            ref={reviewContentRef}
            className="reviewContent"
            style={{
              height: scrollHeight,
              transform: scrollHeight === screenHeight ? undefined : `translateY(-${scrollHeight}px)`,
              transition: scrollHeight === screenHeight ? undefined : "0.4s",
              bottom: scrollHeight === screenHeight ? 0 : -scrollHeight,
            }}
          >
            <div style={{ flexDirection: "column", flexShrink: 0 }}>
              <ReviewGarmentPreview
                garment={garmentToReview}
                clearReview={onClearReview}
                getGarmentPreviewHeight={getGarmentPreviewHeight}
              />

              {questions.map((question: IQuestion, dataId: number) => {
                return (
                  <ReviewItem
                    dataId={dataId}
                    key={question.id}
                    getQuestionHeightCallback={getQuestionHeightCallback}
                    onAnswerReceived={onAnswerReceived}
                    question={question}
                  />
                )
              })}


              {reviewState === REVIEW_STATES.FINISHED_END && (
                <ReviewSendButton
                  fetching={saveReviewFetching}
                  buttonText="SEND REVIEW"
                  onClick={submitAnswers}
                  onShowButton={showButton}
                  error={saveReviewError}
                />
              )}

              {reviewState === REVIEW_STATES.FINISHED_REWARDS && (
                <ReviewSendButton
                  fetching={undefined}
                  buttonText="SEND REVIEW & SELECT REWARD"
                  onClick={() => setReviewState(REVIEW_STATES.REWARDS)}
                  onShowButton={showButton}
                  error={undefined}
                />
              )}
            </div>

          </div>
        </div>
      )}

    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  garment: state.garment.garment,
  closet: state.closet.closetContent,
  saveReviewFetching: state.review.saveReviewFetching,
  saveReviewError: state.review.saveReviewError,
  saveReviewSuccessMessage: state.review.saveReviewSuccessMessage,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => ({
  onClearReview: () => dispatch(clearReview()),
  onSaveReview: (answersToSave: any) => dispatch(saveReview.started({ answersToSave })),
})

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Review) as any

