import { Action } from "redux"
import { isType } from "typescript-fsa"

import {
  sendClosetInfo, addGarment, removeGarment,
  searchCloset, clearSearch,
  toggleWash, clearWash,
  filterClosetWithBins, clearFilterBins,
  showGridView,
  fullSearch, clearFullSearch,
  iWantIt,
  updateGarment,
  setShowSearchResults,
} from "./ClosetActions"

import { getSync } from "./SyncActions"

import { ISearch } from "./ClosetModels"
import { IGarment, IGarmentShort } from "./GarmentModels"
import { matchLocalAndServerInfo } from "../utils/utils"
import { logout } from "./UsersActions"

export interface IClosetState {
  closetContent: Array<IGarment & IGarmentShort>,

  closetInfoFetching: boolean,
  closetInfoError: boolean,

  addGarmentFetching: boolean,
  addGarmentError: boolean,

  removeGarmentFetching: boolean,
  removeGarmentError: boolean,

  search: ISearch

  filtering: boolean,
  filteredClosetWithBins: Array<IGarment & IGarmentShort>,

  gridView: boolean,

  showSearchResults: boolean,

  fullSearchTerm: string,
  fullSearchResults: Array<IGarment & IGarmentShort>,
  fullSearchResultsManufacturers: any,
}

export const initialState: IClosetState = {
  closetContent: [],

  closetInfoFetching: false,
  closetInfoError: false,

  addGarmentFetching: false,
  addGarmentError: false,

  removeGarmentFetching: false,
  removeGarmentError: false,

  search: { searching: false, term: "", searchResult: [] },

  filtering: false,
  filteredClosetWithBins: [],

  showSearchResults: false,

  fullSearchTerm: "",
  fullSearchResults: [],
  fullSearchResultsManufacturers: {},

  gridView: false,
}

export default (state: IClosetState = initialState, action: Action): IClosetState => {


  if (isType(action, getSync.done as any)) {

    // @ts-ignore
    const serverCloset = action.payload.result.closet
    const { closetContent } = state

    const syncedCloset = matchLocalAndServerInfo(closetContent, serverCloset)

    return {
      ...state,
      closetContent: syncedCloset.map( (garment) => {
        return { ...garment, localFlags: { inCloset: true }}
      }),
    }
  }

  if (isType(action, sendClosetInfo.started)) {

    return {
      ...state,
      closetInfoFetching: true,
      closetInfoError: false,
    }
  }

  if (isType(action, sendClosetInfo.done as any)) {

    return {
      ...state,
      closetInfoFetching: false,
      closetInfoError: false,
    }
  }

  if (isType(action, sendClosetInfo.failed)) {

    return {
      ...state,
      closetInfoFetching: false,
      closetInfoError: true,
    }
  }

  if (isType(action, updateGarment as any)) {

    // @ts-ignore
    const { garment } = action.payload
    let { closetContent } = state as any
    let localFlags = {}
    if (!closetContent) {
      closetContent = []
    }

    return {
      ...state,
      addGarmentFetching: true,
      addGarmentError: false,
      closetContent: [
        ...closetContent.map((closetGarment: any) => {
          if (closetGarment.garment_code !== garment.garment_code) {
            return closetGarment
          } else {
            ({ localFlags } = closetGarment)
            return {...garment, localFlags}
          }
        }),
      ],
    }
  }

  if (isType(action, addGarment.started as any)) {

    // @ts-ignore
    const { garment } = action.payload
    let { closetContent } = state as any
    if (!closetContent) {
      closetContent = []
    }

    let alreadyInCloset = false
    const updatedCloset = closetContent.map((closetGarment: IGarment) => {
      if (closetGarment.garment_code !== garment.garment_code) {
        return closetGarment
      } else {
        alreadyInCloset = true
        const { localFlags } = closetGarment
        return {...garment, localFlags}
      }
    })

    return {
      ...state,
      addGarmentFetching: true,
      addGarmentError: false,
      closetContent: alreadyInCloset ? updatedCloset : [...closetContent, garment],
    }
  }

  if (isType(action, addGarment.done as any)) {

    return {
      ...state,
      addGarmentFetching: false,
      addGarmentError: false,
    }
  }

  if (isType(action, addGarment.failed)) {

    return {
      ...state,
      addGarmentFetching: false,
      addGarmentError: true,
    }
  }

  if (isType(action, removeGarment.started as any)) {

    // @ts-ignore
    const { garment_code } = action.payload

    const { search: { searching, term } } = state
    let { closetContent, search: { searchResult }, filteredClosetWithBins, fullSearchResults } = state

    if (! closetContent) { closetContent = [] }
    if (! searchResult) { searchResult = [] }
    if (! filteredClosetWithBins) { filteredClosetWithBins = [] }
    if (! fullSearchResults) { fullSearchResults = [] }

    return {
      ...state,
      removeGarmentFetching: true,
      removeGarmentError: false,
      closetContent: [...closetContent.filter((garment) => garment && garment.garment_code !== garment_code)],
      search: {
        searching,
        term,
        searchResult: [...searchResult.filter((garment) => garment && garment.garment_code !== garment_code)],
      },
      filteredClosetWithBins: [
        ...filteredClosetWithBins.filter((garment) => garment && garment.garment_code !== garment_code),
      ],
      fullSearchResults: [...fullSearchResults.filter((garment) => garment && garment.garment_code !== garment_code)],
    }
  }

  if (isType(action, removeGarment as any)) {

    return {
      ...state,
      removeGarmentFetching: false,
      removeGarmentError: false,
    }
  }

  if (isType(action, removeGarment.failed)) {

    return {
      ...state,
      removeGarmentFetching: false,
      removeGarmentError: true,
    }
  }


  if (isType(action, searchCloset)) {

    let { term } = action.payload
    if (! term) { term = "" }

    let filteredSearch = []
    const { filtering, filteredClosetWithBins } = state

    if (filtering && filteredClosetWithBins) {
      filteredSearch = filteredClosetWithBins
    } else {
      const { closetContent } = state
      filteredSearch = closetContent
    }

    return {
      ...state,
      search: {
        searching: true,
        term,
        searchResult: [
          ...filteredSearch.filter(
            (item) => item && item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1,
          ),
        ],
      },
    }
  }

  if (isType(action, clearSearch)) {

    return {
      ...state,
      search: initialState.search,
    }
  }

  if (isType(action, toggleWash)) {

    const { garment_code, added } = action.payload
    let { closetContent: closet, search: { searchResult } } = state

    const { search: { searching, term }, filteredClosetWithBins, fullSearchResults } = state

    if (! closet) { closet = [] }
    if (! searchResult) { searchResult = [] }

    return {
      ...state,
      closetContent: closet.map(
        (garment) => garment && garment.garment_code === garment_code
        ? { ...garment, localFlags: { ...garment.localFlags, addedToWash: !added }}
        : garment,
      ),
      search: {
        searching,
        term,
        searchResult: searchResult.map(
          (garment) => garment &&
          garment.garment_code === garment_code
          ? { ...garment, localFlags: { ...garment.localFlags, addedToWash: !added } }
          : garment,
        ),
      },
      filteredClosetWithBins: filteredClosetWithBins.map(
        (garment) => garment.garment_code === garment_code
        ? { ...garment, localFlags: { ...garment.localFlags, addedToWash: !added } }
        : garment,
      ),
      fullSearchResults: fullSearchResults.map(
        (garment) => garment.garment_code === garment_code
        ? { ...garment, localFlags: { ...garment.localFlags, addedToWash: !added } }
        : garment,
      ),
    }
  }

  if (isType(action, clearWash)) {

    let { closetContent: closet, search: { searchResult } } = state
    const { search: { searching, term }, filteredClosetWithBins, fullSearchResults } = state

    if (! closet) { closet = [] }
    if (! searchResult) { searchResult = [] }

    return {
      ...state,
      closetContent: closet.map(
        (garment) => ({ ...garment, localFlags: { ...garment.localFlags, addedToWash: false } }),
      ),
      search: {
        searching,
        term,
        searchResult: searchResult.map(
          (garment) => ({ ...garment, localFlags: { ...garment.localFlags, addedToWash: false } }),
        ),
      },
      filteredClosetWithBins: filteredClosetWithBins.map(
        (garment) => ({ ...garment, localFlags: { ...garment.localFlags, addedToWash: false } }),
      ),
      fullSearchResults: fullSearchResults.map(
        (garment) => ({ ...garment, localFlags: { ...garment.localFlags, addedToWash: false } }),
      ),
    }
  }

  if (isType(action, filterClosetWithBins)) {

    const { selectedBinId } = action.payload
    const { closetContent } = state

    if (! closetContent) { return state }

    return {
      ...state,
      filtering: true,
      filteredClosetWithBins:
        closetContent.filter((garment: IGarment) => garment.bins && garment.bins.filter(
          (bin) => selectedBinId === bin.id).length > 0,
        ),
    }
  }

  if (isType(action, clearFilterBins)) {

    return {
      ...state,
      filtering: false,
      filteredClosetWithBins: [],
    }
  }

  if (isType(action, showGridView)) {

    const { gridView } = action.payload
    return {
      ...state,
      gridView,
    }
  }

  if (isType(action, setShowSearchResults)) {

    const { showSearchResults } = action.payload
    return {
      ...state,
      showSearchResults,
    }
  }

  if (isType(action, fullSearch)) {

    const { term } = action.payload
    let { closetContent } = state

    const fullSearchResultsManufacturers = {} as any
    const fullSearchResults = closetContent.filter((item) => {
      const contains = item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1
      if (contains) {
        const { brand_name, brand_logo } = item
        fullSearchResultsManufacturers[brand_name] = brand_logo
      }
      return contains
    })
    if (! closetContent) { closetContent = [] }

    return {
      ...state,
      fullSearchTerm: term,
      fullSearchResults,
      fullSearchResultsManufacturers,
    }
  }

  if (isType(action, clearFullSearch)) {

    return {
      ...state,
      fullSearchTerm: "",
      fullSearchResults: [],
    }
  }

  if (isType(action, iWantIt.started as any)) {

    // @ts-ignore
    const { garmentCode } = action.payload
    const { search: { term, searching } } = state
    let { closetContent, search: { searchResult }, filteredClosetWithBins, fullSearchResults } = state

    if (! closetContent) { closetContent = [] }
    if (! searchResult) { searchResult = [] }
    if (filteredClosetWithBins) { filteredClosetWithBins = [] }
    if (fullSearchResults) { fullSearchResults = [] }

    return {
      ...state,
      closetContent: closetContent.map(
        (garment) => garment && garment.garment_code === garmentCode ? { ...garment, iWantItClicked: true } : garment,
      ),
      search: {
        term,
        searching,
        searchResult: searchResult.map(
          (garment) => garment &&
          garment.garment_code === garmentCode ? { ...garment, iWantItClicked: true } : garment,
        ),
      },
      filteredClosetWithBins: filteredClosetWithBins.map(
        (garment) => garment && garment.garment_code === garmentCode ? { ...garment, iWantItClicked: true } : garment,
      ),
      fullSearchResults: fullSearchResults.map(
        (garment) => garment && garment.garment_code === garmentCode ? { ...garment, iWantItClicked: true } : garment,
      ),
    }
  }

  if (isType(action, logout as any)) {

    return {
      ...initialState,
    }
  }

  return state

}
