import actionCreatorFactory from "typescript-fsa"
import { IReviewToDo } from "./ReviewModels"

const actionCreator = actionCreatorFactory("Review")

export const getReview = actionCreator.async< { garmentId: number },
                                              { reviewToDo: IReviewToDo },
                                              void
                                            >("GET_REVIEW")

export const clearReview = actionCreator<void>("CLEAR_REVIEW")

export const saveReview = actionCreator.async< { answersToSave: any},
                                               { saveReviewSuccessMessage: string },
                                               void
                                            >("SAVE_REVIEW")

