import React from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import "./styles/ClosetScreen.css"

import { RootState } from "../reducers"
import { ISearch } from "../reducers/ClosetModels"
import { IGarment, IGarmentShort } from "../reducers/GarmentModels"
import { toggleWash } from "../reducers/ClosetActions"
import { addToWash, removeFromWash } from "../reducers/WashingActions"
import { showNotification } from "../reducers/NotificationActions"

import ClosetItems from "../components/closet/ClosetItems"
import ClosetHeader from "../components/closet/closetHeader/ClosetHeader"
import ClosetTopMenu from "../components/closet/ClosetTopMenu"
import Empty from "../components/Empty"

interface IStateProps {
  closet: Array<IGarment & IGarmentShort>,
  gridView: boolean,
  search: ISearch,
  garmentsForWashing: Array<IGarment & IGarmentShort>,
  filtering: boolean,
  filteredClosetWithBins: Array<IGarment & IGarmentShort>,
}

interface IDispatchProps {
  onAddToWash: (garment: IGarment & IGarmentShort) => void,
  onRemoveFromWash: (garment: IGarment & IGarmentShort) => void,
  onToggleWash: (garmentCode: string, added: boolean) => void,
  onShowNotification: (notificationTitle: string, notificationBody: string) => void,
}

const Closet: React.FC<IStateProps & IDispatchProps> = (props) => {

  const {
    closet, gridView, search: { searching, term, searchResult }, garmentsForWashing, filtering, filteredClosetWithBins,
    onAddToWash, onRemoveFromWash, onToggleWash, onShowNotification,
  } = props


  const toggleWashFlag = (garment: IGarment & IGarmentShort) => {

    const { garment_code, localFlags, name } = garment

    let addedToWash = false
    if (localFlags) {
      ({ addedToWash } = localFlags)
    }


    if (addedToWash) {
      onRemoveFromWash(garment)
      onShowNotification(name, "Removed from wash")
    } else {
      onAddToWash(garment)
      onShowNotification(name, "Added to wash")
    }

    onToggleWash(garment_code, addedToWash)
  }

  let data = closet as any
  if (searching) {
    data = searchResult
  } else if (filtering) {
    data = filteredClosetWithBins
  }

  let message = null
  if (searching && term.length > 0 && searchResult && searchResult.length === 0) {
    message = term
  } else if (filtering && filteredClosetWithBins.length === 0) {
    message = "selected bins"
  }

  return (
    <div className="closetContainer" data-testid="closetScreen">

      <ClosetHeader
        data={data}
        searching={searching}
        garmentsForWashing={garmentsForWashing}
      />

      <div className="closetScrollContainer">

        <ClosetTopMenu gridView={gridView} />

        {! message && data && data.length > 0 && (
          <ClosetItems
            data={data}
            gridView={gridView}
            toggleWashFlag={(garment: IGarment & IGarmentShort) => toggleWashFlag(garment)}
          />
        )}

        {message && (
          <Empty
            image="/images/closet-empty.svg"
            title={undefined}
            text={`No garments found for ${message} in your closet.`}
            small={false}
            marginBottom={0}
          />
        )}

        {(data === null || data.length === 0) && ! message && (
          <Empty
            image="/images/closet-empty.svg"
            title="Your closet is empty"
            text="Use the in-app scanner and scan the lables to add garments to the closet, and get care information."
            small={false}
            marginBottom={0}
          />
        )}

      </div>

    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  closet: state.closet.closetContent,
  gridView: state.closet.gridView,
  search: state.closet.search,
  garmentsForWashing: state.washing.garmentsForWashing,
  filtering: state.closet.filtering,
  filteredClosetWithBins: state.closet.filteredClosetWithBins,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onAddToWash: (garment: IGarment & IGarmentShort) => dispatch(addToWash({ garment })),
    onRemoveFromWash: (garment: IGarment & IGarmentShort) => dispatch(removeFromWash({ garment })),
    onToggleWash: (garmentCode: string, added: boolean) => dispatch(toggleWash({ garment_code: garmentCode, added })),
    onShowNotification: (notificationTitle: string, notificationBody: string) => dispatch(
      showNotification({ notificationTitle, notificationBody }),
    ),
  }
)

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Closet) as any

