import React, { useState } from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import "./styles/SearchListItem.css"

import { toggleWash } from "../../reducers/ClosetActions"
import { addToWash, removeFromWash } from "../../reducers/WashingActions"
import { IGarment, IGarmentShort } from "../../reducers/GarmentModels"

import IconButton from "../IconButton"
import GarmentPhoto from "../GarmentPhoto"

interface IOwnProps {
  garment: IGarment & IGarmentShort,
  history: any,
}

interface IDispatchProps {
  onAddToWash: (garment: IGarment & IGarmentShort) => void,
  onRemoveFromWash: (garment: IGarment & IGarmentShort) => void,
  onToggleWash: (garmentCode: string, added: boolean) => void
}

const SearchListItem: React.FC<IOwnProps & IDispatchProps> = (props) => {

  const { onAddToWash, onRemoveFromWash, onToggleWash, history } = props

  const [garment, setGarment] = useState(props.garment)

  const { garment_code, name, brand_name, photos, base_photo, localFlags } = garment

  let addedToWash = false
  if (localFlags) {
    ({ addedToWash } = localFlags)
  }


  const onItemClicked = () => {
    if (garment === null) {
      return
    }
    history.push("/garment", { garment })
  }

  const toggleWashFlag = () => {

    if (addedToWash) {
      onRemoveFromWash(garment)
    } else {
      onAddToWash(garment)
    }

    onToggleWash(garment_code, addedToWash)
    setGarment({ ...garment, localFlags: { ...localFlags, addedToWash: !addedToWash } })
  }

  return (
    <div className="listItemMainContainer">

      <GarmentPhoto
        onClick={onItemClicked}
        photos={photos}
        photo={base_photo}
        additionalClassName="button withoutFeedback"
        additionalPhotoClassName="listItemGarmentPhoto"
      />

      <div style={{ justifyContent: "space-between", flexDirection: "column" }}>

        <div className="button withoutFeedback listItemContent" onClick={onItemClicked}>
          <div className="listItemText">
            <p className="H2" data-testid="searchName">{name}</p>
            <p className="small quiet" data-testid="searchBrandName">{brand_name}</p>
          </div>
        </div>

        <div className="searchListItemButtonContainer">
          <IconButton
            onClick={() => { }}
            additionalClassName="searchListItemActions"
            additionalButtonLabelClassName="buttonLabel alt paddingBetween"
            imgSource="/images/icons/share.svg"
            imgClassName="icon32"
            buttonText="SHARE"
          />

          <IconButton
            onClick={toggleWashFlag}
            additionalClassName="searchListItemActions flexEnd"
            additionalButtonLabelClassName={`buttonLabel alt paddingBetween ${addedToWash ? "danger" : ""}`}
            imgSource={addedToWash ? null : "/images/icons/wash.svg"}
            imgClassName={addedToWash ? "" : "icon32"}
            buttonText={addedToWash ? "REMOVE" : "WASH"}
          />
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onAddToWash: (garment: IGarment & IGarmentShort) => dispatch(addToWash({ garment })),
    onRemoveFromWash: (garment: IGarment & IGarmentShort) => dispatch(removeFromWash({ garment })),
    onToggleWash: (garmentCode: string, added: boolean) => dispatch(toggleWash({ garment_code: garmentCode, added })),
  }
)

const wr = withRouter(SearchListItem as any)

export default
  connect(
    null,
    mapDispatchToProps,
  )(wr as any) as any
