import React, { useEffect, useRef, useState } from "react"

import "./styles/GarmentSupplyChain.css"

import { IEntry } from "../../reducers/GarmentModels"

import RegularButton from "../RegularButton"
import { getSupplyChainPhotoPlaceholder } from "./utils"

interface IOwnProps {
  supplyChain: IEntry[],
  onSetTabsHeight: (height: number) => void
}

const GarmentSupplyChain: React.FC<IOwnProps> = (props) => {

  let { supplyChain } = props
  const { onSetTabsHeight } = props

  if (! supplyChain) {
    supplyChain = []
  }

  const lastIndex = supplyChain.length - 1

  const [lastElementHeight, setLastElementHeight] = useState(0)

  const ref = useRef(null) as any
  const refElement = useRef(null) as any

  useEffect(() => {
    if (ref.current) {
      onSetTabsHeight(ref.current.clientHeight)
    }
  }, [onSetTabsHeight, ref])

  useEffect(() => {
    if (refElement.current) {
      setLastElementHeight(refElement.current.clientHeight)
    }
  }, [refElement])

  return (
    <div className="garmentSupplyChainContainer" ref={ref}>

      <div style={{ justifyContent: "center" }}>
        <img src="/images/icons/supply-chain.svg" className="storyPhoto" alt="Supply chain" data-testid="storyPhoto" />
      </div>
      {supplyChain.length > 0 && <div className="timeline" style={{ bottom: lastElementHeight }} />}
      {supplyChain.map((entry, index) => {

        const { name, type, photo, content, id, lat, lng, readMoreUrl } = entry
        const shortType = type.split(" ")[0].toLowerCase()
        const url = `http://www.google.com/maps/place/${lat},${lng}`

        const supplyChainPlaceholder = getSupplyChainPhotoPlaceholder(photo, shortType)
        const googleMapPhotoWidth = window.innerWidth - 32

        return (
          <div
            key={id}
            style={{ flex: 1, flexDirection: "column", marginBottom: "8px" }}
            ref={(index === lastIndex) ? refElement : null}
          >
            <div className="markers" />
            <div className="button" onClick={() => window.open(url, "_blank")}>
              <img
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}
                  &zoom=15&size=${googleMapPhotoWidth}x180&markers=color:red%7Clabel:%7C${lat},${lng}
                  &key=AIzaSyD0D9EzpVGp_WyJ4aUkF05Zb3eKYseGNQ4
                  &language=en`}
                className="mapPhoto"
                style={! lat ? { height: 0 } : {}}
                alt="Map"
                data-testid={`mapPhoto${index}`}
              />
            </div>

            <div style={{ flexDirection: "column" }}>

              <div className="supplyChainPhotoContainer" style={! lat ? { top: "10px" } : { top: "-10px" }}>
                <img
                  src={photo ? photo : supplyChainPlaceholder}
                  className="supplyChainPhoto"
                  alt="Entry"
                  data-testid={photo ? `supplyChainPhoto${index}` : supplyChainPlaceholder}
                />
              </div>

              <div className="supplyChainInfo" style={{ flexDirection: "column" }}>
                <p
                  className="section-upcase quiet supplyChainType"
                  data-testid={`supplyChainType${index}`}
                >
                  {type.toUpperCase()}
                </p>
                <p className="H2" style={{ marginBottom: "8px" }} data-testid={`supplyChainName${index}`}>{name}</p>

                <div>
                  <p className="small" style={{ height: "33.4px", overflow: "hidden", width: "85%" }} data-testid={`supplyChainContent${index}`}>{content}</p>
                  <p className="small" style={{ alignSelf: "flex-end" }}>...</p>
                </div>

                {
                  readMoreUrl && (
                    <div style={{ flexDirection: "row" }} data-testid={`supplyChainReadMoreUrl${index}`}>
                      <RegularButton
                        onClick={() => window.open(readMoreUrl, "_blank")}
                        sizeClassName="regularButton"
                        buttonText="Read more..."
                        additionalClassName={"readButton"}
                        lowerCase={true}
                        additionalButtonLabelClassName="readButtonText"
                      />
                    </div>
                  )
                }
              </div>
            </div>

          </div>
        )
      })}
    </div>
  )
}


export default GarmentSupplyChain

