import React from "react"

import RegularButton from "../RegularButton"

interface IOwnProps {
  title: string,
  text?: string,
  onClick: () => void,
  extraText?: string,
  extraLinkText?: string,
}

const SettingsItem: React.FC<IOwnProps> = (props) => {

  const { title, text, onClick, extraText, extraLinkText } = props

  return (
    <div className="button settingsItemMain" onClick={onClick} data-testid={title}>

      {! text && (
        <div style={{ justifyContent: "center" }}>
          <p className="body">{title}</p>
        </div>
      )}

      {text && (
        <div style={{ justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column" }}>
          <p className="small quiet" style={{ marginBottom: "4px" }}>{title}</p>
          <p className="H2 text" data-testid={text}>{text}</p>
        </div>
      )}

      <div style={{ flexDirection: "row", alignItems: "center" }}>

        {extraText && (
          <p className="section-upcase quiet" style={{ padding: 0, paddingRight: "16px" }}>{extraText}</p>
        )}

        {extraLinkText && (
          <RegularButton
            onClick={onClick}
            sizeClassName="regularButtonExtraSmall"
            buttonText={extraLinkText}
            additionalClassName=""
            lowerCase={false}
            additionalButtonLabelClassName="section-upcase text withoutPadding"
          />
        )}

        <img src="/images/icons/arrow-right.svg" alt="Choose one" />
      </div>
    </div>
  )
}

export default SettingsItem
