import React from "react"

import "./styles/SettingsSwitchItem.css"

interface IOwnProps {
  text: string,
  initialState: boolean,
  onSwitchChanged: () => void,
}

const SettingsSwitchItem: React.FC<IOwnProps> = (props) => {

  const { text, initialState, onSwitchChanged } = props

  return (
    <div className="settingsItemMain">
      <p className="body">{text}</p>
      <label className="switch">
        <input type="checkbox" checked={initialState} onChange={onSwitchChanged} />
        <div className="sliderSwitch round"></div>
      </label>
    </div>
  )
}

export default SettingsSwitchItem
