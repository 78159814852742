import React from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"
import { withRouter } from "react-router"

import { searchCloset, clearSearch, clearWash } from "../../../reducers/ClosetActions"
import { cancelWash } from "../../../reducers/WashingActions"
import { showAlert } from "../../../reducers/NotificationActions"
import { IGarment, IGarmentShort } from "../../../reducers/GarmentModels"

import ClosetHeaderInfo from "./ClosetHeaderInfo"
import ClosetHeaderInput from "./ClosetHeaderInput"
import WashingHeaderInfo from "../../washing/WashingHeaderInfo"

interface IOwnProps {
  history: any,
  data: Array<IGarment & IGarmentShort>,
  searching: boolean,
  garmentsForWashing: Array<IGarment & IGarmentShort>
}

interface IDispatchProps {
  onSearchCloset: (value: string) => void,
  onClearSearch: () => void,
  onClearWash: () => void,
  onCancelWash: () => void,
  onShowAlert: (alertTitle: string, alertBody: string, deleteFlag: boolean, onAccept?: () => void) => void,
}

const ClosetHeader: React.FC<IOwnProps & IDispatchProps> = (props) => {
  const {
    history,
    data, searching, garmentsForWashing,
    onSearchCloset, onClearSearch, onClearWash, onCancelWash, onShowAlert,
  } = props

  const onWashCancel = () => {
    onClearWash()
    onCancelWash()
  }

  return (
    <div className="headerView" data-testid="closetHeader">

      {
        garmentsForWashing.length > 0 && (
          <WashingHeaderInfo
            numOfItems={garmentsForWashing.length}
            onBackClicked={undefined}
            onOpenListClicked={() => history.push("/washing")}
            onCloseClicked={() => onShowAlert(
              "Clear washing list",
              "Are you sure you want to clear the washing list?",
              false,
              onWashCancel,
            )}
          />
        )
      }

      {
        garmentsForWashing.length === 0 && ! searching && (
          <ClosetHeaderInfo
            numOfItems={data ? data.length : 0}
            onClick={() => onSearchCloset("")}
          />
        )
      }

      {
        garmentsForWashing.length === 0 && searching && (
          <ClosetHeaderInput
            onTextChanged={(text: string) => onSearchCloset(text)}
            onCloseInput={onClearSearch}
          />
        )
      }

    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onSearchCloset: (term: string) => dispatch(searchCloset({ term })),
    onClearSearch: () => dispatch(clearSearch()),
    onClearWash: () => dispatch(clearWash()),
    onCancelWash: () => dispatch(cancelWash()),
    onShowAlert: (alertTitle: string, alertBody: string, deleteFlag: boolean, onAccept?: () => void) => dispatch(
      showAlert({ alertTitle, alertBody, deleteFlag, onAccept })),
  }
)

const wr = withRouter(ClosetHeader as any)

export default
  connect(
    null,
    mapDispatchToProps,
  )(wr as any) as any

