import React from "react"

interface IOwnProps {
  additionalClassName: string,
  imgSource: string,
  imgClassName: string,
  additionalTextClassName?: string,
  text: string,
}

const IconText: React.FC<IOwnProps> = (props) => {

  const { additionalClassName, imgSource, imgClassName, additionalTextClassName, text } = props

  return (
    <div className={`${additionalClassName} imageText`} data-testid={text}>
      <img className={imgClassName} src={imgSource} alt="Icon" />
      <p className={additionalTextClassName}>{text}</p>
    </div>
  )
}
export default IconText
