import React from "react"

import "./styles/ClosetGridItem.css"

import { IGarment, IGarmentShort } from "../../reducers/GarmentModels"

// import useLongPress from "../../utils/useLongPress"
import IconButton from "../IconButton"
import GarmentPhoto from "../GarmentPhoto"

interface IOwnProps {
  garment: IGarment & IGarmentShort,
  toggleWashFlag: (garment: IGarment & IGarmentShort) => void
  goToGarmentScreen: () => void
}

const ClosetGridItem: React.FC<IOwnProps> = (props) => {

  const { garment, toggleWashFlag, goToGarmentScreen } = props
  // const onLongPress = useLongPress(() => toggleWashFlag(garment), 500)

  if (garment === null) {
    return null
  }

  const { name, brand_name, photos, localFlags, base_photo } = garment

  let addedToWash = false
  if (localFlags) {
    ({ addedToWash } = localFlags)
  }

  return (
    <div className="closetGridItemContainer">
      <div>

        <div style={{ flexDirection: "column", flex: 1 }}>
          <div className={addedToWash ? "gradientBlue" : ""}>

            <IconButton
              onClick={() => toggleWashFlag(garment)}
              additionalClassName="toggleWashButton"
              additionalButtonLabelClassName={addedToWash ? "buttonLabel danger" : undefined}
              imgSource={addedToWash ? null : "/images/icons/wash.svg"}
              imgClassName={addedToWash ? "" : "icon32"}
              buttonText={addedToWash ? "REMOVE" : ""}
            />
            <GarmentPhoto
              onClick={goToGarmentScreen}
              photo={base_photo}
              photos={photos}
              additionalClassName=""
              additionalPhotoClassName="gridItemPhoto"
            />


          </div>

          <div className="closetGridItemNameAndBrand" onClick={goToGarmentScreen}>
            <p data-testid="closetGridItemName" className="H2" style={{ paddingTop: "8px" }}>{name}</p>
            <p data-testid="closetGridItemBrand" className="small quiet">{brand_name}</p>
          </div>

        </div>

      </div>
    </div>
  )
}

export default ClosetGridItem
