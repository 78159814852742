import React from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import "./styles/Alert.css"

import { RootState } from "../reducers"
import { clearAlert } from "../reducers/NotificationActions"

import RegularButton from "./RegularButton"
import GradientButton from "./GradientButton"

interface IStateProps {
  alertTitle?: string,
  alertBody?: string,
  onAccept?: () => void,
  deleteFlag: boolean,
}

interface IDispatchProps {
  onClearAlert: () => void,
}

const Alert: React.FC<IStateProps & IDispatchProps> = (props) => {

  const { alertTitle, alertBody, onAccept, deleteFlag, onClearAlert } = props

  if (! alertTitle) {
    return null
  }

  const onOkClicked = () => {
    if (onAccept) {
      onAccept()
    }
    onClearAlert()
  }

  return (
    <div className="alertContainer">
      <div className={`${deleteFlag ? "gradientDanger" : "gradientGreen"} smallGradientLine`} />
      <div className={`alertMainView ${deleteFlag ? "backgroundWhite" : "backgroundAlert"}`}>
        <img
          src={deleteFlag ? "/images/icons/info-notification-red.svg" : "/images/icons/info-notification.svg"}
          alt="Alert"
        />
        <div className="alertTextView">
          <p className={`H2 ${deleteFlag ? "black" : "white"}`} data-testid="alertTitle">{alertTitle}</p>
          <p className="body quiet" data-testid="alertText">{alertBody}</p>
          <div className="alertButtonsView">
            {deleteFlag ?
              <RegularButton
                onClick={onOkClicked}
                sizeClassName="regularButtonExtraSmall"
                buttonText="Ok"
                additionalClassName="alertOkButtonDelete"
                lowerCase={false}
                additionalButtonLabelClassName="alertOkButtonDeleteText buttonLabel white"
              /> :
              <GradientButton
                additionalClassName="alertOkButtonText"
                buttonText="Ok"
                onClick={onOkClicked}
                gradientClassName="gradientGreen"
                sizeClassName="gradientButtonExtraSmall"
                additionalButtonLabelClassName=""
              />
            }
            <RegularButton
              onClick={onClearAlert}
              sizeClassName="regularButtonExtraSmall"
              buttonText="Cancel"
              additionalClassName={""}
              lowerCase={false}
              additionalButtonLabelClassName="body quiet"
            />
          </div>
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  alertTitle: state.notification.alertTitle,
  alertBody: state.notification.alertBody,
  onAccept: state.notification.onAccept,
  deleteFlag: state.notification.deleteFlag,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onClearAlert: () => dispatch(clearAlert()),
  }
)


export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Alert) as any

