import React, { useEffect } from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import "./styles/SearchResults.css"

import { fullSearch } from "../../reducers/ClosetActions"
import { RootState } from "../../reducers"
import { IGarment, IGarmentShort } from "../../reducers/GarmentModels"

import Empty from "../Empty"
import SearchResultInfo from "./SearchResultsInfo"
import SearchListItem from "./SearchListItem"

interface IOwnProps {
  searchTerm: string,
  goBackOnSearch: () => void
}

interface IStateProps {
  fullSearchTerm: string,
  fullSearchResults: Array<IGarment & IGarmentShort>,
  fullSearchResultsManufacturers: any,
  numOfGarmentsInWash: number,
}

interface IDispatchProps {
  onFullSearch: (term: string) => void,
}

const SearchResults: React.FC<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const { searchTerm, goBackOnSearch,
    fullSearchResults, fullSearchTerm, fullSearchResultsManufacturers, onFullSearch, numOfGarmentsInWash,
  } = props

  useEffect(() => {
    if (searchTerm) {
      onFullSearch(searchTerm)
    }
  }, [onFullSearch, searchTerm])


  const brandPhotos = [] as any
  if (fullSearchResults.length > 0) {
    for (const brand in fullSearchResultsManufacturers) {
      if (fullSearchResultsManufacturers.hasOwnProperty(brand)) {
        const { [brand]: photo } = fullSearchResultsManufacturers
        brandPhotos.push(photo)
      }
    }
  }

  return (
    <div className="searchResultsContainer">

      <SearchResultInfo
        numOfItems={fullSearchResults.length}
        fullSearchTerm={fullSearchTerm}
        goBackOnSearch={goBackOnSearch}
        numOfGarmentsInWash={numOfGarmentsInWash}
      />

      {(fullSearchResults == null || fullSearchResults.length === 0) && (
        <Empty
          image="/images/search-empty.svg"
          title="No results."
          text={`There were no results for "${fullSearchTerm}".`}
          small={false}
          marginBottom={0}
        />
      )}

      {fullSearchResults.length > 0 && (
        <div className="searchResultsMainContent">

          <p className="section-upcase quiet">BRANDS</p>
          <div className="brandPhotoList">
            {brandPhotos.map((photo: any, index: number) =>
              <img src={photo} key={photo} alt={photo} className="brandPhoto" data-testid={`brandPhoto${index}`} />)}
          </div>
          <div className="brandPhotoListGradient gradientTransparent"></div>

          <p className="section-upcase quiet">PRODUCTS</p>
          <div className="fullSearchResultsContainer">
            {fullSearchResults.map((garment) => {
              return (
                <SearchListItem
                  key={garment.garment_code}
                  garment={garment}
                />
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  fullSearchResults: state.closet.fullSearchResults,
  fullSearchTerm: state.closet.fullSearchTerm,
  fullSearchResultsManufacturers: state.closet.fullSearchResultsManufacturers,
  numOfGarmentsInWash: state.washing.garmentsForWashing.length,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onFullSearch: (term: string) => dispatch(fullSearch({ term })),
  }
)

const wr = withRouter(SearchResults as any)

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(wr as any) as any
