import React from "react"

import "./styles/ListAllBins.css"

import binsData from "../data/bins"

interface IOwnProps {
  filterByBins: (id: number, icon: string) => void,
}

const ListAllBins: React.FC<IOwnProps> = (props) => {

  const allBins = binsData()
  const reshufledBins = [] as any

  allBins.map((binType) => {

    const binGroup = { title: binType.name, data: [] as any }

    let { bins } = binType
    if (! bins) {
      bins = []
    }

    bins.map((bin) => {
      const { id, name, icon } = bin
      binGroup.data.push({ id, name, icon, selected: false })
      return binGroup
    })

    reshufledBins.push(binGroup)
    return reshufledBins

  })

  const { filterByBins } = props

  if (reshufledBins.length === 0) {
    return (
      <div>
        <p>Error, no bins found</p>
      </div>
    )
  }

  return (
    <div className="binsListMainView" data-testid="closetListAllBins">

      {reshufledBins.map((bin: any) => {
        return (
          <div key={bin.title} style={{ flexDirection: "column", display: "block" }}>
            <div className="section-upcase quiet">
              <p>{bin.title.toUpperCase()}</p>
            </div>

            {bin.data.map((item: any) => {
              const { id, icon, name } = item
              return (
                <div className="button binListItem" key={id} onClick={() => filterByBins(id, icon)} data-testid={name}>
                  <div style={{ flexDirection: "row", alignItems: "center" }}>
                    <img src={icon} alt={name} />
                    <p className="body text">{name}</p>
                  </div>
                </div>
              )
            })}

          </div>
        )
      })}

    </div>
  )
}
export default ListAllBins
