import React from "react"
import { NavLink } from "react-router-dom"

import "./TabNav.css"

interface IOwnProps {
  path: string,
  imageSrc: string,
}

const activeNavLink: React.FC<IOwnProps> = (props) => {

  const { path, imageSrc } = props

  return (
    <NavLink to={path} className="tabNavItem" activeClassName="tabNavActive">
      <div className="tabNavItemActive" data-testid={path}>
        <img src={`/images/icons/${imageSrc}.svg`} alt={imageSrc} />
      </div>
    </NavLink>
  )
}

export default activeNavLink
