const binsData = () => {
  return [
    {
      name: "Washing",
      bins:
        [
          {
            id: 1,
            name: "Hand wash",
            icon: "/images/icons/laundry-icons/icon-wash-hand.svg",
          },
          {
            id: 2,
            name: "Machine wash cold",
            icon: "/images/icons/laundry-icons/icon-wash-cold.svg",
          },
          {
            id: 3,
            name: "Machine wash warm",
            icon: "/images/icons/laundry-icons/icon-wash-warm.svg",
          },
          {
            id: 4,
            name: "Non-chlorine bleach",
            icon: "/images/icons/laundry-icons/icon-non-chlorine-bleach.svg",
          },
          {
            id: 5,
            name: "Non-chlorine bleach for colors",
            icon: "/images/icons/laundry-icons/icon-non-chlorine-bleach-for-colors.svg",
          },
          {
            id: 6,
            name: "Do not bleach",
            icon: "/images/icons/laundry-icons/icon-bleach-not.svg",
          },
          {
            id: 7,
            name: "Do not wash",
            icon: "/images/icons/laundry-icons/icon-wash-not.svg",
          },
        ],
    },
    {
      name: "Drycleaning",
      bins:
        [
          {
            id: 8,
            name: "Dry clean",
            icon: "/images/icons/laundry-icons/icon-dryclean.svg",
          },
          {
            id: 9,
            name: "Do not dry clean",
            icon: "/images/icons/laundry-icons/icon-dryclean-not.svg",
          },
        ],
    },
    {
      name: "Drying",
      bins:
        [
          {
            id: 10,
            name: "Tumble dry",
            icon: "/images/icons/laundry-icons/icon-tumbledry.svg",
          },
          {
            id: 11,
            name: "Tumble dry low",
            icon: "/images/icons/laundry-icons/icon-tumbledry-low.svg",
          },
          {
            id: 12,
            name: "Do not tumble dry",
            icon: "/images/icons/laundry-icons/icon-tumbledry-not.svg",
          },
          {
            id: 13,
            name: "Do not wring or twist",
            icon: "/images/icons/laundry-icons/icon-wring-not.svg",
          },
        ],
    },
    {
      name: "Ironing",
      bins:
        [
          {
            id: 14,
            name: "Warm iron",
            icon: "/images/icons/laundry-icons/icon-iron-warm.svg",
          },
          {
            id: 15,
            name: "Cool iron",
            icon: "/images/icons/laundry-icons/icon-iron-cold.svg",
          },
          {
            id: 16,
            name: "Do not iron",
            icon: "/images/icons/laundry-icons/icon-iron-not.svg",
          },
        ],
    },
  ]
}

export default binsData
