import { call, put, takeLatest } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"

import request, { generatePutOptionsForRequest, generateDeleteOptionsForRequest} from "../utils/request"

import { addGarment, removeGarment } from "../reducers/ClosetActions"

/* ************************************************************* */
function * addGarmentToClosetSaga(action: any) {

  const { garment } = action.payload

  try {
    const { garment_code: garmentCode } = garment
    const putOptions = generatePutOptionsForRequest({ garmentCode })
    const result = yield call(request, "/closet/garment", putOptions)

    yield put(addGarment.done({ params: { garment }, result }))
  } catch (err) {
    console.log(err)
    yield put(addGarment.failed({ params: { garment }, error: err }))
  }
}

export function * watchAddGarment(): SagaIterator {
  yield takeLatest(addGarment.started, addGarmentToClosetSaga)
}
/* ************************************************************* */


/* ************************************************************* */
function * removeGarmentFromClosetSaga(action: any) {

  const { garment_code, isLocal } = action.payload
  if (isLocal) {
    return
  }

  try {
    const deleteOptions = generateDeleteOptionsForRequest({ garmentCode: garment_code })
    const result = yield call(request, "/closet/garment", deleteOptions)
    yield put(removeGarment.done({ params: { garment_code, isLocal }, result }))
  } catch (err) {
    console.log(err)
    yield put(removeGarment.failed({ params: { garment_code, isLocal }, error: err }))
  }

}

export function * watchRemoveGarment(): SagaIterator {
  yield takeLatest(removeGarment.started, removeGarmentFromClosetSaga)
}
/* ************************************************************* */
