import React, { useState, useEffect, useCallback } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { Dispatch, Action } from "redux"

import { RootState } from "../../reducers"
import { SETTINGS_ADDRESS_OBJECT } from "../../reducers/UsersReducers"
import { addAddress, deleteAddress, clearAddDeleteAddress } from "../../reducers/UsersActions"

import "./styles/SettingsEditAddress.css"

import RegularButton from "../RegularButton"
import SettingsAddressItem from "./SettingsAddressItem"
import GradientButton from "../GradientButton"
import BackButton from "../BackButton"
import { IUserAddress } from "../../reducers/UsersModels"
import Loading from "../Loading"

interface IOwnProps {
  history: any,
  location: any,
}

interface IStateProps {
  addresses: any,
  addAddressResultMessage?: string,
  addAddressLoading: boolean,
  deleteAddressLoading: boolean,
  deleteAddressResultMessage?: string,
}

interface IDispatchProps {
  onAddAddress: (address: IUserAddress) => void,
  onDeleteAddress: (addressId: number) => void,
  onClearAddDeleteAddress: () => void,
}

const SettingsEditAddress: React.FC<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const {
    history, location,
    onAddAddress, addresses, addAddressResultMessage, addAddressLoading,
    onDeleteAddress, deleteAddressLoading, deleteAddressResultMessage, onClearAddDeleteAddress,
  } = props

  const { state } = location

  const [inputItems, setInputItems] = useState({}) as any
  const [showWarning, setShowWarning] = useState(false)
  const [settingsAddress, setSettingsAddress] = useState(
    state && state.address ? state.address : {
      id: undefined,
      name: "",
      address1: "",
      address2: "",
      zip: "",
      city: "",
      state: "",
      country: "",
    })

  const onClearAddDeleteAddressCallback = useCallback(() => {
    if ((addAddressResultMessage || deleteAddressResultMessage)) {
      onClearAddDeleteAddress()
      history.push("/settings/addresses", {})
    }
  }, [addAddressResultMessage, deleteAddressResultMessage, onClearAddDeleteAddress, history])

  useEffect(() => {
    onClearAddDeleteAddressCallback()
  }, [onClearAddDeleteAddressCallback])

  const getInputsCallback = (itemId: string, inputRef: any) => {
    setInputItems((items: any) => ({ ...items, [itemId]: inputRef }))
  }

  const getTextCallback = (itemId: string, text: string) => {
    setSettingsAddress((addressItems: any) => ({ ...addressItems, [itemId]: text }))

    const settingsAddressObject = {...settingsAddress, [itemId]: text}
    if (itemId === "country") {
      saveAddress(settingsAddressObject)
    }
  }

  const onEnterCallback = (inputRef: any, itemId: string, nextItemId?: string) => {
    inputRef.blur()
    if (nextItemId && inputItems[nextItemId]) {
      inputItems[nextItemId].focus()
      if (inputItems[itemId]) {
        inputItems[itemId].scrollIntoView()
       }
    } else {
      window.scrollTo(0, document.body.scrollHeight)
    }
  }

  const saveAddress = (newAddress: IUserAddress) => {
    const addressNameExist = Object.values(addresses).find((address: any) => address.name === settingsAddress.name)
    if (! settingsAddress.id && addressNameExist) {
      setShowWarning(true)
    } else {
      onAddAddress(newAddress)
      setShowWarning(false)
    }
  }

  if (deleteAddressLoading) {
    return <Loading text={undefined} small={false} />
  }

  return (
    <div className="settingsEditAddressContainer">
      <BackButton
        goBackAction={history.goBack} additionalClassName="backButtonContainer withoutBottomPadding"
      />

      <div>
        <div className="addressHeaderView">
          <p className="section-upcase quiet" data-testid="addressName">
            {settingsAddress.name.length > 0 ? settingsAddress.name : "NEW ADDRESS"}
          </p>
          <RegularButton
            onClick={() => onDeleteAddress(settingsAddress.id)}
            sizeClassName="regularButtonExtraSmall"
            buttonText="DELETE ADDRESS"
            additionalClassName="deleteAddressButton"
            lowerCase={false}
            additionalButtonLabelClassName="deleteAddressButtonLabel buttonLabel"
          />
        </div>
      </div>

      {Object.values(SETTINGS_ADDRESS_OBJECT).map((addressItem) => {
        return (
          <SettingsAddressItem
            key={addressItem.itemId}
            title={addressItem.title}
            itemId={addressItem.itemId}
            nextItemId={addressItem.nextItemId}
            text={settingsAddress && settingsAddress[addressItem.itemId] ? settingsAddress[addressItem.itemId] : ""}
            getTextCallback={getTextCallback}
            getInputsCallback={getInputsCallback}
            onEnterCallback={onEnterCallback}
          />
        )
      })}

      <div className="editAddressSaveButtonContainer">

        {addAddressLoading && (
          <Loading text={undefined} small={true} />
        )}

        {showWarning && (
          <p className="editAddressSaveButtonWarning" data-testid="saveAddressWarning">
            Address name already taken.
          </p>
        )}

        <GradientButton
          additionalClassName=""
          buttonText="SAVE"
          onClick={() => saveAddress(settingsAddress)}
          gradientClassName="gradientGreen"
          sizeClassName="gradientButtonBig"
          additionalButtonLabelClassName="black"
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  addresses: state.user.addresses,
  addAddressResultMessage: state.user.addAddressResultMessage,
  addAddressLoading: state.user.addAddressLoading,
  deleteAddressLoading: state.user.deleteAddressLoading,
  deleteAddressResultMessage: state.user.deleteAddressResultMessage,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onAddAddress: (address: IUserAddress) => dispatch(addAddress.started({ address })),
    onDeleteAddress: (addressId: number) => dispatch(deleteAddress.started({ addressId })),
    onClearAddDeleteAddress: () => dispatch(clearAddDeleteAddress()),
  }
)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SettingsEditAddress) as any,
)
