import React from "react"

interface IOwnProps {
  numOfItems: number,
  onClick: () => void,
}

const ClosetHeaderInfo: React.FC<IOwnProps> = (props) => {

  const { numOfItems, onClick } = props

  return (
    <div className="button headerMenuContainer gradientGreen" onClick={onClick}>
      <img src="/images/icons/filter.svg" alt="Filter" />
      <div className="headerMenuContent">
        <p className="H2" data-testid="closetHeaderHeading">Your Closet {numOfItems === 0 && "Is Empty"}</p>
        <p className="small" style={{ padding: 8 }} data-testid="closetHeaderNumOfItems">
          {numOfItems} item{numOfItems > 1 && "s"}
        </p>
      </div>
    </div>
  )
}

export default ClosetHeaderInfo
