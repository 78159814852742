import React from "react"

import "./styles/RecycleOrDonateCoupon.css"

import { GarmentRecycleDonateStatuses } from "../../reducers/PassItOnReducers"

interface IOwnProps {
  garmentStatus?: number,
  garmentBrand: string,
}

const RecycleOrDonateCoupon: React.FC<IOwnProps> = (props) => {

  const { garmentStatus, garmentBrand } = props
  const { CouponApproved, CouponUsed } = GarmentRecycleDonateStatuses

  return (
    <>
      {(garmentStatus === CouponApproved || garmentStatus === CouponUsed) && (
        <div style={{ flexDirection: "column" }}>
          <p className="section-upcase passItOnInstructionsHeading">YOUR COUPON</p>
          <div>

            <div
              style={
                garmentStatus === CouponUsed ? { opacity: 0.5, flexDirection: "column" } : { flexDirection: "column" }
              }
            >
              <img className="couponPhoto" src="/images/coupon.png" alt="Coupon" />
              <div className="gradientGreen couponTextContainer" style={{ flexDirection: "column" }}>

                <div style={{ flexDirection: "row", alignItems: "center" }}>
                  <p className="H2 couponDiscountText" style={{ fontWeight: 900, paddingRight: "2px" }}>15%OFF</p>
                  <p className="H2 couponDiscountText"> AT</p>
                </div>

                <p className="H2 couponDiscountText">{garmentBrand.toUpperCase()}</p>
                <p className="section-upcase couponDiscountNumber">A0001234</p>
                <p className="small" style={{ textAlign: "center" }}>
                  Since you’ve purchased this garment through Cacotec, you don’t need to provide proof of purchase.
                </p>

              </div>
            </div>

            {garmentStatus === CouponUsed && (
              <div className="couponUsedLabel">
                <img src="/images/icons/coupon-used.svg" alt="Coupon used" />
                <p className="section-upcase couponUsedLabelText">COUPON USED</p>
              </div>
            )}

          </div>
        </div>
      )}
    </>
  )
}

export default RecycleOrDonateCoupon
