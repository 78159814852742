import React, { useState } from "react"

import "./styles/ClosetTopMenu.css"

import { connect } from "react-redux"
import { Dispatch, Action } from "redux"
import { showGridView, filterClosetWithBins, clearFilterBins } from "../../reducers/ClosetActions"

import RegularButton from "../RegularButton"
import ListAllBins from "../ListAllBins"

interface IOwnProps {
  gridView: boolean,
}

interface IDispatchProps {
  onShowGridView: (value: boolean) => void,
  onFilterClosetWithBins: (value: number) => void,
  onClearFilterBins: () => void,
}


const ClosetTopMenu: React.FC<IOwnProps & IDispatchProps> = (props) => {

  const [showChooseBinsModal, setShowChooseBinsModal] = useState(false)
  const [selectedBinIcon, setSelectedBinIcon] = useState(undefined)

  const { onShowGridView, gridView, onFilterClosetWithBins, onClearFilterBins } = props

  const filterByBins = (filterByBinsId: any, binIcon: any) => {
    setSelectedBinIcon(binIcon)
    setShowChooseBinsModal(false)

    if (filterByBinsId) {
      onFilterClosetWithBins(filterByBinsId)
    } else {
      onClearFilterBins()
    }
  }

  return (
    <div className="closetTopMenu">

      <div className="closetLaundryContainer">

        <div style={{ marginLeft: "16px" }}>
          <RegularButton
            sizeClassName="regularButtonSmall"
            additionalClassName="regularButtonWhite"
            lowerCase={false}
            buttonText="LAUNDRY"
            additionalButtonLabelClassName={selectedBinIcon ? "closetLaundryButtonWithSelectedBin" : ""}
            onClick={() => setShowChooseBinsModal(true)}
          />
          {selectedBinIcon && <img src={selectedBinIcon} alt={selectedBinIcon} className="binIconIndicator" />}
        </div>

        {selectedBinIcon && (
          <RegularButton
            sizeClassName="small"
            additionalClassName="clearButton"
            lowerCase={false}
            buttonText="Clear"
            additionalButtonLabelClassName="body quiet"
            onClick={() => filterByBins(null, null)}
          />
        )}

      </div>

      <div className="closetListAndGridIcons">

        <div className="button" style={{ paddingRight: "10px" }} onClick={() => onShowGridView(true)}>
          <img
            data-testid="gridViewImg"
            src="/images/icons/grid-alt.svg"
            alt="Grid View"
            style={gridView ? { opacity: 0.4 } : undefined}
          />
        </div>

        <div className="button" onClick={() => onShowGridView(false)}>
          <img
            data-testid="listViewImg"
            src="/images/icons/list-alt.svg"
            alt="List View"
            style={gridView ? undefined : { opacity: 0.4 }}
          />
        </div>

      </div>

      {showChooseBinsModal && (
        <div className="modal">
          <ListAllBins
            filterByBins={filterByBins}
          />
        </div>
      )}

    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onShowGridView: (gridView: boolean) => dispatch(showGridView({ gridView })),
    onFilterClosetWithBins: (selectedBinId: number) => dispatch(filterClosetWithBins({ selectedBinId })),
    onClearFilterBins: () => dispatch(clearFilterBins()),
  }
)

export default
  connect(
    null,
    mapDispatchToProps,
  )(ClosetTopMenu) as any
