import React, { useState } from "react"

import { IReward } from "../../reducers/ReviewModels"

import MultipleChoiceItem from "./MultipleChoiceItem"

interface IOwnProps {
  items: IReward[],
  chooseItem: (id: number) => void,
}

const MultipleChoice: React.FC<IOwnProps> = (props) => {

  const { items, chooseItem } = props

  const [chosenRewardId, setChosenRewardId] = useState(undefined) as any

  return (
    <div style={{ backgroundColor: "white", flexDirection: "column" }}>

      {items.map((item) => {
        const { id } = item
        return (
          <MultipleChoiceItem
            key={id}
            item={item}
            chooseItem={chooseItem}
            chosenRewardId={chosenRewardId}
            setChosenRewardId={setChosenRewardId}
          />
        )
      })}

    </div>
  )
}

export default MultipleChoice
