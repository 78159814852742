import React from "react"

import "./styles/ChoosePassItOn.css"

import { ActionTypes } from "../reducers/PassItOnReducers"

import BackButton from "../components/BackButton"
import PassItOnButton from "../components/passItOn/PassItOnButton"

interface IOwnProps {
  history: any,
  location: any,
}

const ChoosePassItOn: React.FC<IOwnProps> = (props) => {

  const { history, location } = props

  const { state } = location

  if (! state) {
    return null
  }

  const { garment } = state

  const navigateToItemScreen = (item: string, type: number) => {
    history.push(`/passItOn/${item}`, { type, garment })
  }

  return (
    <div className="passItOnMainContainer" data-testid="passItOnMainContainer">
      <div style={{ flex: 1, flexDirection: "column" }}>

        <BackButton goBackAction={history.goBack} additionalClassName="backButtonContainer" />

        <div style={{ flexDirection: "column" }}>

          <div className="passItOnHeading">
            <p className="body" style={{ textAlign: "center", paddingBottom: "8px" }}>
              Recycle or Donate
            </p>
            <p className="body quiet" style={{ textAlign: "center", padding: "8px" }}>
              A little bit of information regarding these options.
            </p>
          </div>

        </div>

        <div className="passItOnButtons">

          <PassItOnButton
            navigateToItemScreen={() => navigateToItemScreen("recycle", ActionTypes.recycle)}
            itemHeading="recycle"
            itemBody="recycling"
            imgSrc="/images/icons/recycle.svg"
          />

          <PassItOnButton
            navigateToItemScreen={() => navigateToItemScreen("donate", ActionTypes.donate)}
            itemHeading="donate"
            itemBody="donating"
            imgSrc="/images/icons/donate.svg"
          />

          {/* <PassItOnButton
            navigateToItemScreen={() => navigateToItemScreen("sell", ActionTypes.sell)}
            itemHeading="sell"
            itemBody="selling"
            imgSrc="/images/icons/sell.svg"
          /> */}

        </div>

      </div>
    </div>
  )
}

export default ChoosePassItOn
