import actionCreatorFactory from "typescript-fsa"
import { IGarmentShort } from "./GarmentModels"

const actionCreator = actionCreatorFactory("Sync")

export const getSync = actionCreator.async<void,
                                            { data: {
                                              closet: IGarmentShort[],
                                              recycledGarments: IGarmentShort[],
                                              donatedGarments: IGarmentShort[],
                                            } },
                                            void
                                            >("GET_SYNC")
