import React from "react"

import "../styles/GarmentBasicInfo.css"

import { IUser } from "../../../reducers/UsersModels"
import GradientButton from "../../GradientButton"
import IconButton from "../../IconButton"
import IconText from "../../IconText"


interface IOwnProps {
  garment_code: string,
  user?: IUser,
  inCloset: boolean,
  video: string,
  setWatchVideo: (video: boolean) => void,
  addGarmentToCloset: () => void,
  zIndex: number,
}

const GarmentBasicInfoBottomSection: React.FC<IOwnProps> = (props) => {

  const { garment_code, user, inCloset, video, setWatchVideo, addGarmentToCloset, zIndex } = props

  return (
    <div
      className="garmentBottomSection"
      style={video ? { justifyContent: "space-between", zIndex } : { justifyContent: "flex-end", zIndex }}
    >

      {video &&
        <IconButton
          onClick={() => setWatchVideo(true)}
          additionalClassName="videoButton"
          imgClassName="icon32"
          imgSource="/images/icons/video.svg"
          additionalButtonLabelClassName={"videoButtonLabel"}
          buttonText={"WATCH VIDEO"}
        />
      }

      {inCloset && (
        <IconText
          additionalClassName="addToClosetButton"
          imgSource="/images/icons/ok-alt.svg"
          imgClassName=""
          text="IN CLOSET"
          additionalTextClassName="small quiet videoButtonLabel"
        />
      )}



      {! inCloset && (
        <GradientButton
          additionalClassName="addToClosetButton"
          buttonText="+ Add to closet"
          onClick={() => user ? addGarmentToCloset() : window.open(`/garment/${garment_code}/adt`, "_self")}
          gradientClassName="gradientGreen videoButtonLabel"
          sizeClassName="gradientButtonSmall"
          additionalButtonLabelClassName=""
        />
      )}
    </div>
  )
}

export default GarmentBasicInfoBottomSection
