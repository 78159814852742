import actionCreatorFactory from "typescript-fsa"
import { IGarment, ILocalFlags } from "./GarmentModels"

const actionCreator = actionCreatorFactory("Garment")

export const getGarment =
  actionCreator.async<{ garmentCode: string, localFlags: ILocalFlags },
                      { garment: IGarment[] },
                      void
                    >("GET_GARMENT")

export const getLabelImages =
  actionCreator.async<{ orderItemId: number, size: string | null },
                      { labelImages: string[] },
                      void
                    >("GET_LABEL_IMAGES")