import React from "react"

import "./styles/RegularButton.css"

interface IOwnProps {
  onClick: () => void,
  sizeClassName: string,
  buttonText: string,
  additionalClassName: string,
  lowerCase: boolean,
  additionalButtonLabelClassName: string,
}

const RegularButton: React.FC<IOwnProps> = (props) => {

  const { sizeClassName, onClick, additionalClassName, buttonText, lowerCase, additionalButtonLabelClassName } = props

  return (
    <div
      data-testid={buttonText}
      className={`button regularButton ${additionalClassName} ${sizeClassName}`}
      onClick={onClick}
    >
      <p
        className={`buttonLabel alt ${additionalButtonLabelClassName}`}
        style={{ textAlign: "center" }}
      >
        {lowerCase ? buttonText : buttonText.toUpperCase()}
      </p>
    </div>
  )
}
export default RegularButton
