import React, { useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router"

import "../styles/GarmentBasicInfo.css"

import { IUser } from "../../../reducers/UsersModels"
import { IGarment } from "../../../reducers/GarmentModels"

import BackButton from "../../BackButton"
import GarmentBasicInfoBottomSection from "./GarmentBasicInfoBottomSection"
import GarmentBasicInfoPreview from "./GarmentBasicInfoPreview"
import GarmentBasicInfoSwiper from "./GarmentBasicInfoSwiper"



interface IOwnProps {
  user?: IUser,
  garment: IGarment,
  action: string,
  history: any,
  toggleWashFlag: () => void,
  setWatchVideo: (video: boolean) => void,
  addGarmentToCloset: () => void,
  showBackButton: boolean,
}

const GarmentBasicInfo: React.FC<IOwnProps> = (props) => {

  const { user, action, history, garment, toggleWashFlag, setWatchVideo, addGarmentToCloset, showBackButton } = props

  const addGarmentToClosetCallback = useCallback(() => {
    if (action === "adt") {
      addGarmentToCloset()
    }
  }, [action, addGarmentToCloset])

  useEffect(() => {
    addGarmentToClosetCallback()
  }, [addGarmentToClosetCallback])

  const { garment_code, photos, localFlags, video } = garment

  let inCloset = false
  if (localFlags) {
    ({ inCloset } = localFlags)
  }


  const [swiper, setSwiper] = useState(false)

  return (
    <>
      {! swiper && (
        <>

          <div className="garmentBasicInfoContainer" data-testid="garmentBasicInfo">

            {showBackButton &&
              <BackButton
                goBackAction={history.goBack} additionalClassName="backButtonContainer"
              />
            }

            {! showBackButton && <div style={{height: "80px"}}></div>}
            <GarmentBasicInfoPreview
              garment={garment}
              toggleWashFlag={toggleWashFlag}
              setSwiper={setSwiper}
            />
          </div>

          <GarmentBasicInfoBottomSection
            garment_code={garment_code}
            user={user}
            video={video}
            inCloset={inCloset}
            setWatchVideo={setWatchVideo}
            addGarmentToCloset={addGarmentToCloset}
            zIndex={1}
          />

        </>
      )}

      {swiper && (

        <div className="swiperContainer">

          <BackButton goBackAction={() => setSwiper(false)} additionalClassName="backButtonContainer" />

          <div className="mainSwiperContainer">
            <GarmentBasicInfoSwiper photos={photos} />

            <div style={{ position: "fixed", bottom: "65px", left: "0", right: "0" }}>
              <GarmentBasicInfoBottomSection
                garment_code={garment_code}
                user={user}
                video={video}
                inCloset={inCloset}
                setWatchVideo={setWatchVideo}
                addGarmentToCloset={addGarmentToCloset}
                zIndex={7}
              />
            </div>
          </div>

        </div>
      )}

    </>
  )
}

export default withRouter(GarmentBasicInfo as any) as any
