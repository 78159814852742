import actionCreatorFactory from "typescript-fsa"

const actionCreator = actionCreatorFactory("Notification")


export const showNotification = actionCreator<{
                                              notificationTitle: string,
                                              notificationBody: string,
                                              }>("SHOW_NOTIFICATION")

export const clearNotification = actionCreator<void>("CLEAR_NOTIFICATION")

export const showAlert = actionCreator<{
                                      alertTitle: string,
                                      alertBody: string,
                                      deleteFlag: boolean,
                                      onAccept?: () => void,
                                      }>("SHOW_ALERT")

export const clearAlert = actionCreator<void>("CLEAR_ALERT")

export const clearAll = actionCreator<void>("CLEAR_ALL")
