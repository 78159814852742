import React from "react"

import "./styles/Loading.css"

interface IOwnProps {
  text?: string,
  small: boolean,
}

const Loading: React.SFC<IOwnProps> = (props) => {
  const { text, small } = props

  let spinnerSize = "80px"
  if (small) {
    spinnerSize = "30px"
  }

  return (
    <div className="loadingContainer" style={small ? { height: "100%" } : { height: window.innerHeight }} data-testid="loading">
      <img src="/images/icons/spinner.gif" alt="Loading" width={spinnerSize} />
      {text && <p className="small" style={{ paddingLeft: "10px" }}>{text}</p>}
    </div>
  )
}

export default Loading
