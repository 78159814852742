import React, { useEffect, useCallback } from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import { RootState } from "../../reducers"
import { ActionTypes } from "../../reducers/PassItOnReducers"
import { IGarmentShort, IRecycleDonateGarment } from "../../reducers/GarmentModels"
import { selectAppropriateGarments } from "../../reducers/PassItOnActions"

import BackButton from "../BackButton"
import PassItOnGarmentView from "../passItOn/PassItOnGarmentView"


interface IOwnProps {
  location: any,
  history: any,
}

interface IStateProps {
  recycleDonateGarments?: Array<IRecycleDonateGarment & IGarmentShort>,
}

interface IDispatchProps {
  onSelectAppropriateGarments: (recycleDonateType: number) => void
}

const SettingsRecycleDonateList: React.FC<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const { location, history, recycleDonateGarments, onSelectAppropriateGarments } = props

  const { state } = location

  const onSelectAppropriateGarmentsCallback = useCallback(() => {
    if (state) {
      onSelectAppropriateGarments(state.type)
    }
  }, [state, onSelectAppropriateGarments])

  useEffect(() => {
    onSelectAppropriateGarmentsCallback()
  }, [onSelectAppropriateGarmentsCallback])


  if (! state) {
    return null
  }

  const { type } = state

  return (
    <div style={{ flex: 1, justifyContent: "space-between" }}>
      <div style={{ flex: 1, flexDirection: "column" }}>
        <BackButton goBackAction={history.goBack} additionalClassName="backButtonContainer" />

        <p className="section-upcase quiet">
          {type === ActionTypes.recycle && "RECYCLING"}
          {type === ActionTypes.donate && "DONATING"}
        </p>

        {recycleDonateGarments && recycleDonateGarments.length > 0 && (
          recycleDonateGarments.map((garment) => {
            let item = "recycle"
            if (type !== ActionTypes.recycle) {
              item = "donate"
            }
            return (
              <div key={garment.garment_code}>
                <div className="button" onClick={() => history.push(`/passItOn/${item}`, { type, garment })}>
                  <PassItOnGarmentView
                    garment={garment}
                    passItOnPhoto={
                      type === ActionTypes.recycle ? "/images/icons/recycle.svg" : "/images/icons/donate.svg"
                    }
                    passItOnText={type === ActionTypes.recycle ? "recycling" : "donating"}
                    garmentStatus={garment.recycleDonateStatus}
                  />
                </div>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  recycleDonateGarments: state.passItOn.recycleDonateGarments,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onSelectAppropriateGarments:
      (recycleDonateType: number) => dispatch(selectAppropriateGarments({ recycleDonateType })),
  }
)

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SettingsRecycleDonateList) as any
