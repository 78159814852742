import React from "react"
import Swiper from "react-id-swiper"

import "../styles/GarmentBasicInfo.css"

import { IPhoto } from "../../../reducers/GarmentModels"

interface IOwnProps {
  photos: IPhoto[]
}

const GarmentBasicInfoSwiper: React.FC<IOwnProps> = (props) => {

  const { photos } = props

  // swiper parameters
  const params = {
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    containerClass: "swiper-container",
    slideClass: "swiper-slide",
    speed: 400,
  }

  return (
    <div data-testid="photoSwiper">
      {photos && photos.length > 0 &&
        <Swiper {...params}>
          {photos.map((photo, i) => {
            return (
              <div key={photo.url} style={{ height: "100%" }} data-testid={`garmentSwipePhoto${i}`}>
                <img className="swiperGarmentPhoto" src={photo.url} alt={photo.url} />
              </div>
            )
          },
          )}
        </Swiper>
      }
    </div>
  )
}

export default GarmentBasicInfoSwiper
