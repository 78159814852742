import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import "./styles/LabelImagesScreen.css"

import { RootState } from "../reducers"
import { getLabelImages } from "../reducers/GarmentActions"

import Loading from "../components/Loading"



interface IStateProps {
  labelImages?: string[],
  labelImagesLoading: boolean,
  labelImagesError: boolean,
}

interface IOwnProps {
  location: any,
  match: any,
  history: any,
}

interface IDispatchProps {
  onGetLabelImages: (orderItemId: number, size: string | null) => void,
}


const Garment: React.FC<IStateProps & IOwnProps & IDispatchProps> = (props) => {

  const {
    onGetLabelImages, location,
    labelImages, labelImagesLoading, labelImagesError,
  } = props



  useEffect(() => {
    window.scrollTo(0, 0)

    const size = new URLSearchParams(location.search).get('s')
    const { orderItemId } = props.match.params
    onGetLabelImages(orderItemId, size)
  }, [])


  if (labelImagesLoading) {
    return (
      <Loading text="Getting garment information" small={false} />
    )
  }


  if (! labelImages || labelImagesError) {
    return (
      <p>No labels found</p>
    )
  }

  


  return (
    <div className="mainContent">
      <div className="labelImages">
        {
          labelImages.map((image, index) => {
            return (
              <img key={index} src={image} alt="Label" className="labelImage" />
            )
          })
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  labelImages: state.garment.labelImages,
  labelImagesLoading: state.garment.labelImagesFetching,
  labelImagesError: state.garment.labelImagesError,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onGetLabelImages: (orderItemId: number, size: string | null) => dispatch(
      getLabelImages.started({ orderItemId, size }),
    ),
  }
)

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Garment) as any

