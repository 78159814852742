import React, { useState } from "react"

import "./styles/SliderQuestion.css"

import { IAnswerSettings } from "../../reducers/ReviewModels"

interface IOwnProps {
  answerSettings: IAnswerSettings,
  onAnswer: (question: string, answer: any) => void,
  question: string,
  dataId: number,
}

const SliderQuestion: React.FC<IOwnProps> = (props) => {

  const { answerSettings, onAnswer, question, dataId } = props

  const [minValue] = useState(answerSettings && answerSettings.minValue ? answerSettings.minValue : 1)
  const [maxValue] = useState(answerSettings && answerSettings.maxValue ? answerSettings.maxValue : 5)
  const [sliderValue, setSliderValue] = useState(Math.round(maxValue / 2))

  return (
    <div style={{ flex: 1, flexDirection: "column" }} data-testid={`sliderQuestion${dataId}`}>
      <div className="mainSliderContainer">
        <p className="H2">{sliderValue}</p>
        <input
          data-testid={`sliderQuestionInput${dataId}`}
          type="range"
          min={minValue}
          max={maxValue}
          value={sliderValue}
          className="sliderQuestion"
          onChange={(e) => setSliderValue(parseInt(e.target.value, 10))}
          onTouchEnd={() => onAnswer(question, sliderValue)}
          onMouseDown={() => onAnswer(question, sliderValue)}
        />
      </div>
      <div className="textSliderContainer">
        <p className="small">1 - not recommend</p>
        <p className="small">definitely recommend - 5</p>
      </div>
    </div>
  )
}

export default SliderQuestion
