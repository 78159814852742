import actionCreatorFactory from "typescript-fsa"

import { IUser, ICredentials, IUserAddress } from "./UsersModels"

const actionCreator = actionCreatorFactory("User")




export const getCurrentUserInfo =
    actionCreator.async<void,
                        { currentUser: IUser },
                        void
                    >("GET_CURRENT_USER_INFO")

export const login =
    actionCreator.async<{ payload: ICredentials },
                        { user: IUser },
                        { errorMessage: string }
                    >("LOGIN")

export const logout = actionCreator<void>("LOGOUT")

export const removeCurrentUserInfo =
    actionCreator.async<void,
                        void,
                        void
                        >("REMOVE_CURRENT_USER_INFO")

export const toggleSettingsState = actionCreator<{ name: string }>("TOGGLE_SETTINGS_STATE")

export const addAddress =
    actionCreator.async<{address: IUserAddress},
                        {addAddressResultMessage?: string, addressId: number},
                        void
                        >("ADD_ADDRESS")

export const selectAddress =
    actionCreator.async<{ addressId: number },
                        { addressId: number },
                        void
                        >("SELECT_ADDRESS")

export const deleteAddress =
    actionCreator.async<{addressId: number},
                        {deleteAddressResultMessage?: string},
                        void
                        >("DELETE_ADDRESS")

export const clearAddDeleteAddress = actionCreator<void>("CLEAR_ADD_DELETE_ADDRESS")





