import React from "react"

import "./styles/GarmentScreenTabButtons.css"

import RegularButton from "../RegularButton"

interface IOwnProps {
  activeTab: number,
  setActiveTab: (activeTab: number) => void
}

export const INFORMATION_TAB = 0
export const CARE_TAB = 1
export const SUPPLY_CHAIN_TAB = 2

const GarmentScreenTabButtons: React.FC<IOwnProps> = (props) => {

  const { activeTab, setActiveTab } = props

  return (
    <div className="tabButtons">

      <RegularButton
        onClick={() => setActiveTab(INFORMATION_TAB)}
        sizeClassName="regularButtonSmall"
        buttonText="Info"
        additionalClassName={activeTab === INFORMATION_TAB ? "regularButtonYellow" : ""}
        lowerCase={false}
        additionalButtonLabelClassName=""
      />

      <RegularButton
        onClick={() => setActiveTab(CARE_TAB)}
        sizeClassName="regularButtonSmall"
        buttonText="Care"
        additionalClassName={activeTab === CARE_TAB ? "regularButtonYellow" : ""}
        lowerCase={false}
        additionalButtonLabelClassName={"buttonLabel"}
      />

      <RegularButton
        onClick={() => setActiveTab(SUPPLY_CHAIN_TAB)}
        sizeClassName="regularButtonSmall"
        buttonText="Story"
        additionalClassName={activeTab === SUPPLY_CHAIN_TAB ? "regularButtonYellow" : ""}
        lowerCase={false}
        additionalButtonLabelClassName=""
      />

    </div>
  )
}

export default GarmentScreenTabButtons
