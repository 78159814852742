import React, { useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { Dispatch, Action } from "redux"

import { RootState } from "../../reducers"
import { selectAddress } from "../../reducers/UsersActions"

import "./styles/SettingsAddressesScreen.css"

import Empty from "../Empty"
import BackButton from "../BackButton"
import GradientButton from "../GradientButton"
import SettingsSelectAddressItem from "./SettingsSelectAddressItem"
import { IUserAddress } from "../../reducers/UsersModels"

interface IOwnProps {
  history: any,
}

interface IStateProps {
  addresses: any,
  activeAddress?: IUserAddress,
  activeAddressNameLoading: boolean,
}

interface IDispatchProps {
  onSelectAddress: (addressId: number) => void,
}

const SettingsAddressesScreen: React.FC<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const { history, addresses, onSelectAddress, activeAddress, activeAddressNameLoading } = props
  const [addressItemId, setAddressItemId] = useState(undefined) as any

  const onSelectActiveAddress = (id: number) => {
    onSelectAddress(id)
    setAddressItemId(id)
  }

  const onAddNewAddress = () => {
    history.push("/settings/addresses/addAddress")
  }

  const numberOfAddresses = Object.keys(addresses).length

  return (
    <div className="settingsAddressesMainContainer">
      <BackButton
        goBackAction={() => history.push("/settings")} additionalClassName="backButtonContainer withoutBottomPadding"
      />

      {numberOfAddresses === 0 && (
        <Empty
          image="/images/address-empty.svg"
          title="Add an address"
          text="Add at least one address to your Cacotec account, so you can order drycleaning."
          small={false}
          marginBottom={-32}
        />
      )}

      {numberOfAddresses > 0 && (

        <div style={{ flex: 1, flexDirection: "column" }}>
          <p className="section-upcase quiet" data-testid="selectAddressText">
            SELECT ADDRESS
          </p>

          {Object.values(addresses).map((address: any) => {
            const { id, name } = address
            return (
              <SettingsSelectAddressItem
                key={id}
                isActive={activeAddress && activeAddress.name === name}
                onSelectActiveAddress={() => onSelectActiveAddress(id)}
                onEditAddress={() => history.push(`/settings/addresses/${name}`, { address })}
                text={name}
                activeAddressNameLoading={activeAddressNameLoading && addressItemId === id}
              />
            )
          })}

        </div>
      )}

      <div className="settingsAddressAddNewButton">
        <GradientButton
          additionalClassName=""
          buttonText="ADD NEW ADDRESS"
          onClick={onAddNewAddress}
          gradientClassName="gradientGreen"
          sizeClassName="gradientButtonBig"
          additionalButtonLabelClassName="alt textAlignCenter"
        />
      </div>

    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  addresses: state.user.addresses,
  activeAddress: state.user.activeAddress,
  activeAddressNameLoading: state.user.activeAddressNameLoading,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onSelectAddress: (addressId: number) => dispatch(selectAddress.started({ addressId })),
  }
)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SettingsAddressesScreen) as any,
)
