import React from "react"

import "./styles/PassItOnButton.css"

interface IOwnProps {
  navigateToItemScreen: () => void,
  itemHeading: string,
  itemBody: string,
  imgSrc: string,
}

const PassItOnButton: React.FC<IOwnProps> = (props) => {

  const { navigateToItemScreen, itemHeading, itemBody, imgSrc } = props

  return (
    <div className="passItOnItem button" onClick={() => navigateToItemScreen()} data-testid={itemHeading}>
      <img className="passItOnItemPhoto" src={imgSrc} alt={itemHeading} />
      <div className="passItOnItemText">
        <p className="section-upcase text" style={{ padding: "8px", paddingLeft: 0 }}>{itemHeading.toUpperCase()}</p>
        <p className="small"> A little bit of information about {itemBody}.</p>
      </div>
    </div>
  )
}

export default PassItOnButton
