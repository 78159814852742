

export function getLanguageCode() {
  let systemLanguage = "en"

  if (navigator.language) {
    systemLanguage = navigator.language.substring(0, 2)
  }

  return systemLanguage
}

export function matchLocalAndServerInfo(localArray: any, serverArray: any) {

  const serverGarmentsIds = serverArray.map((garment: any) => garment.garment_code)
  const inBothArray = localArray.filter((garment: any) =>
    serverGarmentsIds.includes(garment.garment_code),
  )

  const localGarmentsIds = localArray.map((garment: any) => garment.garment_code)
  const notInLocalArray = serverArray.filter((garmentInfo: any) =>
  !localGarmentsIds.includes(garmentInfo.garment_code),
  )

  return [...inBothArray, ...notInLocalArray]
}
