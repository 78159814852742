import React, { useState } from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import "./styles/SearchScreen.css"
import { RootState } from "../reducers"

import SearchResults from "../components/search/SearchResults"
import SearchInput from "../components/search/SearchInput"
import { setShowSearchResults } from "../reducers/ClosetActions"

interface IOwnProps {
  history: any,
}

interface IStateProps {
  showSearchResults: boolean,
}

interface IDispatchProps {
  onSetShowSearchResult: (showSearchResults: boolean) => void,
}

const Search: React.FC<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const { history, showSearchResults, onSetShowSearchResult } = props

  const [currentText, setCurrentText] = useState("")

  return (
    <>
      {! showSearchResults && (
        <SearchInput
          history={history}
          currentText={currentText}
          setCurrentText={setCurrentText}
          setShowResult={onSetShowSearchResult}
        />
      )}

      {showSearchResults && (
        <SearchResults
          searchTerm={currentText}
          goBackOnSearch={() => onSetShowSearchResult(false)}
        />
      )}

    </>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  showSearchResults: state.closet.showSearchResults,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onSetShowSearchResult: (showSearchResults: boolean) => dispatch(setShowSearchResults({ showSearchResults })),
  }
)

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Search) as any
