import request, { generatePostOptionsForRequest } from "../utils/request"

import { call, put, takeLatest } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"

import { getReview, saveReview } from "../reducers/ReviewActions"


/* ************************************************************* */
function* getReviewInfo(action: any) {
  const { garmentId } = action.payload

  try {
    const result = yield call(request, `/reviews/review/${garmentId ? garmentId : -1}`)
    const { status, reviewToDo } = result
    if (! reviewToDo && status !== "ok") {
      throw new Error("Review not found")
    }

    yield put(getReview.done({ result, params: garmentId }))
  } catch (err) {
    console.log(err)
    yield put(getReview.failed({ error: err, params: garmentId }))
  }
}

export function* watchGetReview(): SagaIterator {
  yield takeLatest(getReview.started, getReviewInfo)
}
/* ************************************************************* */

/* ************************************************************* */
function* saveReviewInfo(action: any) {

  const { answersToSave } = action.payload

  try {
    const postOptions = generatePostOptionsForRequest(answersToSave)
    const result = yield call(request, "/reviews/review", postOptions)

    yield put(saveReview.done({ result, params: answersToSave }))
  } catch (err) {
    console.log(err)
    yield put(saveReview.failed({ error: err, params: answersToSave }))
  }
}

export function* watchSaveReview(): SagaIterator {
  yield takeLatest(saveReview.started, saveReviewInfo)
}
/* ************************************************************* */
