import React from "react"

import BackButton from "../BackButton"

interface IOwnProps {
  numOfItems: number,
  onBackClicked?: () => void,
  onOpenListClicked?: () => void,
  onCloseClicked: () => void
}

const WashingHeaderInfo: React.FC<IOwnProps> = (props) => {

  const { numOfItems, onBackClicked, onOpenListClicked, onCloseClicked } = props

  return (
    <div className="headerMenuContainer gradientBlue">

      {onOpenListClicked &&
        <div onClick={onOpenListClicked} className="button">
          <img src="/images/icons/wash.svg" alt="Wash" />
        </div>
      }

      {onBackClicked &&
        <BackButton goBackAction={onBackClicked} additionalClassName="" />
      }

      <div className="headerMenuContent">

        {onOpenListClicked && (
          <div className="button" onClick={onOpenListClicked}>
            <p className="H2" data-testid="washingHeaderText">
              {numOfItems} garment{numOfItems > 1 && "s"} added
          </p>
          </div>
        )}

        {onBackClicked && (
          <p className="H2">
            {numOfItems === 0 ? "No" : numOfItems} item{numOfItems !== 1 && "s"} in this wash
         </p>
        )}

        <div style={{ flexDirection: "row" }}>
          <div className="button" onClick={onCloseClicked}>
            <img data-testid="washingHeaderClose" src="/images/icons/trash.svg" alt="Close" />
          </div>
        </div>

      </div>

    </div>
  )
}
export default WashingHeaderInfo
