import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

import "./styles/ClosetItems.css"

import { IGarment, IGarmentShort } from "../../reducers/GarmentModels"

import ClosetListItem from "./ClosetListItem"
import ClosetGridItem from "./ClosetGridItem"

interface IOwnProps {
  history: any,
  data: Array<IGarment & IGarmentShort>,
  gridView: boolean,
  toggleWashFlag: (garment: IGarment & IGarmentShort) => void
}

interface IStateProps {
  gridView: boolean
}

const ClosetItems: React.FC<IOwnProps & IStateProps> = (props) => {

  const { data, gridView, toggleWashFlag, history } = props

  const goToGarmentScreen = (garment: IGarment & IGarmentShort) => {
    history.push("/garment", { garment })
  }

  return (
    <div className={gridView ? "closetItemsGridContainer" : "closetListItemsContainer"} data-testid="closetItems">

      {data.map((garment: IGarment & IGarmentShort) => {
        if (garment === null) {
          return null
        }

        if (gridView) {
          return (
            <ClosetGridItem
              garment={garment}
              key={garment.garment_code}
              toggleWashFlag={toggleWashFlag}
              goToGarmentScreen={() => goToGarmentScreen(garment)}
            />
          )
        } else {
          return (
            <ClosetListItem
              garment={garment}
              key={garment.garment_code}
              toggleWashFlag={toggleWashFlag}
              goToGarmentScreen={() => goToGarmentScreen(garment)}
            />
          )
        }
      })}

    </div>
  )
}

const wr = withRouter(ClosetItems as any)

export default
  connect(
    null,
    null,
  )(wr as any) as any

