import React from "react"
// import { NavLink } from "react-router-dom"
import ActiveNavLink from "./ActiveNavLink"

import "./TabNav.css"

interface IOwnProps {
  pathname: string,
}

const TabNav: React.FC<IOwnProps> = (props) => {

  // const activeGradientNavLink = (path: string, imageSrc: string) => {
  //   return (
  //     <NavLink to={path} className="tabNavItem" activeClassName="tabNavActive">
  //       <div className="tabNavItemActive">
  //         <img src={`/images/icons/${imageSrc}.svg`} alt={imageSrc} />
  //       </div>
  //     </NavLink>
  //   )
  // }

  return (
    <div className="tabNav">
      <ActiveNavLink path="/closet" imageSrc="closet" />
      <ActiveNavLink path="/search" imageSrc="search" />

      {/* <div className="centeredTabNavItems gradientGreen">
        <NavLink to="/scan" className="tabNavItem">
          <img src="/images/icons/wash.svg" alt="Wash" />
        </NavLink>
        <NavLink to="/washing" className="tabNavItem">
          <img src="/images/icons/target.svg" alt="Target" />
        </NavLink>
      </div> */}



      <ActiveNavLink path="/settings" imageSrc="user" />
      <ActiveNavLink path="/help" imageSrc="help" />

    </div>
  )
}

export default TabNav
