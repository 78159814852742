import React from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"


interface IOwnProps {
  numOfItems: number,
  fullSearchTerm: string,
  goBackOnSearch: () => void,
  numOfGarmentsInWash: number,
}

const SearchResultsInfo: React.FC<IOwnProps> = (props) => {

  const { goBackOnSearch, numOfItems, fullSearchTerm, numOfGarmentsInWash } = props

  return (
    <div className="headerView">
      <div
        className={`headerMenuContainer ${numOfGarmentsInWash > 0 ? "gradientBlue" : "gradientGreen"}`}
        data-testid={numOfGarmentsInWash > 0 ? "gradientBlueHeader" : "gradientGreenHeader"}
      >
        <div className="button" onClick={goBackOnSearch} data-testid="goBackOnSearch">
          <img src="/images/icons/arrow-left.svg" alt="Go back" />
        </div>
        <div className="headerMenuContent">
          <p className="H2" data-testid="searchHeader">Results for "{fullSearchTerm}"</p>
          <p className="small" style={{ padding: 8 }} data-testid="searchHeaderNumOfItems">
            {numOfItems} result{numOfItems > 1 && "s"}
          </p>
        </div>
      </div>
    </div>
  )
}

const wr = withRouter(SearchResultsInfo as any)

export default
  connect(
    null,
    null,
  )(wr as any) as any
