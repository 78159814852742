import React, { useState } from "react"

interface IOwnProps {
  onTextChanged: (text: string) => void,
  onCloseInput: () => void,
}

const ClosetHeaderInput: React.FC<IOwnProps> = (props) => {

  const [currentText, setCurrentText] = useState("")

  const { onTextChanged, onCloseInput } = props

  const textChanges = (text: any) => {
    setCurrentText(text)
    onTextChanged(text)
  }

  return (
    <div className="headerMenuContainer">
      <img src="/images/icons/filter.svg" alt="Filter" />
      <div className="headerMenuContent">
        <input
          data-testid="closetSearchInput"
          type="text"
          onChange={(e) => textChanges(e.target.value)}
          className="H2"
          style={{ flex: 1, border: "none", width: "100%" }}
          autoFocus={true}
          placeholder="Type to filter this bin"
          value={currentText}
        />
      </div>
      <div data-testid="closetHeaderInputCloseButton" className="button" style={{ padding: 0 }} onClick={onCloseInput}>
        <img src="/images/icons/close.svg" alt="Close" />
      </div>
    </div>
  )
}

export default ClosetHeaderInput
