import { Action } from "redux"
import { isType } from "typescript-fsa"

import {
  getCurrentUserInfo, login, removeCurrentUserInfo, toggleSettingsState, addAddress, selectAddress, deleteAddress,
  clearAddDeleteAddress, logout,
} from "./UsersActions"
import { getSync } from "./SyncActions"

import { IUser, IUserAddress } from "../reducers/UsersModels"

export const SETTINGS_ADDRESS_OBJECT = Object.freeze({
  name: { title: "Name", itemId: "name", nextItemId: "address1" },
  address1: { title: "Address 1", itemId: "address1", nextItemId: "address2" },
  address2: { title: "Address 2", itemId: "address2", nextItemId: "zip" },
  zip: { title: "ZIP", itemId: "zip", nextItemId: "city" },
  city: { title: "City", itemId: "city", nextItemId: "state" },
  state: { title: "State", itemId: "state", nextItemId: "country" },
  country: { title: "Country", itemId: "country", nextItemId: undefined },
})

export interface IUsersState {
  [key: string]: any,

  isLoadingCurrentUser: boolean,
  errorCurrentUser: boolean,

  isLoadingLogout: boolean,

  user?: IUser,

  removeCurrentUserFetching: boolean,
  removeCurrentUserInfoError: boolean,

  addAddressLoading: boolean,
  addAddressError: boolean,
  addAddressResultMessage?: string,
  addresses: { [key: string]: IUserAddress },

  activeAddress?: IUserAddress,
  activeAddressNameLoading: boolean,
  activeAddressNameError: boolean,

  deleteAddressLoading: boolean,
  deleteAddressError: boolean,
  deleteAddressResultMessage?: string,

  emailNotifications: boolean,
  pushNotifications: boolean,
  smsNotifications: boolean,
  shareInformation: boolean,
}


export const initialState: IUsersState = {
  isLoadingCurrentUser: true,
  errorCurrentUser: false,

  isLoadingLogout: false,

  user: undefined,

  removeCurrentUserFetching: false,
  removeCurrentUserInfoError: false,

  addAddressLoading: false,
  addAddressError: false,
  addAddressResultMessage: undefined,
  addresses: {},

  activeAddress: undefined,
  activeAddressNameLoading: false,
  activeAddressNameError: false,

  deleteAddressLoading: false,
  deleteAddressError: false,
  deleteAddressResultMessage: undefined,


  emailNotifications: true,
  pushNotifications: false,
  smsNotifications: false,
  shareInformation: false,
}

export default (state: IUsersState = initialState, action: Action): IUsersState => {

  if (isType(action, getSync.done as any)) {

    // @ts-ignore
    const addressesArray = action.payload.result.addresses
    const { addresses } = state
    addressesArray.map((address: any) => addresses[address.id] = address)

    // @ts-ignore
    const activeAddressId = action.payload.result.settings.active_address_id
    let { activeAddress } = state
    if (addresses[activeAddressId]) {
      activeAddress = addresses[activeAddressId]
    }

    return {
      ...state,
      addresses,
      activeAddress,
    }
  }

  if (isType(action, getCurrentUserInfo.started)) {
    return {
      ...state,
      isLoadingCurrentUser: true,
      errorCurrentUser: false,
      user: undefined,
    }
  }

  if (isType(action, getCurrentUserInfo.failed as any)) {
    return {
      ...state,
      isLoadingCurrentUser: false,
      errorCurrentUser: true,
      user: undefined,
    }
  }

  if (isType(action, getCurrentUserInfo.done as any)) {

    // TODO - remove ts-ignore
    // @ts-ignore
    const { user } = action.payload.result

    return {
      ...state,
      isLoadingCurrentUser: false,
      errorCurrentUser: false,
      user,
    }
  }

  if (isType(action, removeCurrentUserInfo.started)) {
    return {
      ...state,
      removeCurrentUserFetching: false,
      removeCurrentUserInfoError: false,
    }
  }

  if (isType(action, removeCurrentUserInfo.failed as any)) {
    return {
      ...state,
      removeCurrentUserFetching: false,
      removeCurrentUserInfoError: true,
    }
  }

  if (isType(action, removeCurrentUserInfo.done as any)) {

    return {
      removeCurrentUserFetching: false,
      removeCurrentUserInfoError: false,
      ...initialState,
      user: undefined,
    }
  }

  if (isType(action, login.started)) {
    return {
      ...state,
      isLoadingLogin: true,
      errorLogin: undefined,
      user: undefined,
    }
  }

  if (isType(action, login.done)) {
    const { user } = action.payload.result
    return {
      ...state,
      isLoadingLogin: false,
      errorLogin: undefined,
      user,
    }
  }

  if (isType(action, login.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingLogin: false,
      errorLogin: errorMessage,
      user: undefined,
    }
  }

  if (isType(action, toggleSettingsState)) {

    const { name } = action.payload

    let key = null
    switch (name) {
      case "email":
        key = "emailNotifications"; break
      case "push":
        key = "pushNotifications"; break
      case "sms":
        key = "smsNotifications"; break
      case "shareInfo":
        key = "shareInformation"; break
    }

    if (key !== null) {
      // const { [key]: currentState } = state
      return {
        ...state,
      }
    }
  }

  if (isType(action, addAddress.started)) {
    return {
      ...state,
      addAddressLoading: true,
      addAddressError: false,
    }
  }

  if (isType(action, addAddress.failed)) {
    return {
      ...state,
      addAddressLoading: false,
      addAddressError: true,
    }
  }

  if (isType(action, addAddress.done)) {
    const { result, params } = action.payload
    const { address } = params
    const { addAddressResultMessage, addressId } = result

    const { addresses } = state
    let { activeAddress } = state

    const editedAddress = addresses[addressId]

    if (editedAddress) {
      if (activeAddress &&  activeAddress === editedAddress) {
        activeAddress = address
      }
    }

    return {
      ...state,
      addAddressLoading: false,
      addAddressError: false,
      addAddressResultMessage,
      addresses: { ...addresses, [addressId]: {...address, id: addressId} },
      activeAddress,
    }
  }

  if (isType(action, selectAddress.started)) {

    return {
      ...state,
      activeAddressNameLoading: true,
      activeAddressNameError: false,
    }
  }

  if (isType(action, selectAddress.failed)) {
    return {
      ...state,
      activeAddressNameLoading: false,
      activeAddressNameError: true,
    }
  }

  if (isType(action, selectAddress.done)) {
    const { params } = action.payload
    const { addressId } = params
    const { addresses } = state
    return {
      ...state,
      activeAddress: addresses[addressId],
      activeAddressNameLoading: false,
      activeAddressNameError: false,
    }
  }

  if (isType(action, deleteAddress.started)) {
    return {
      ...state,
      deleteAddressLoading: true,
      deleteAddressError: false,
    }
  }

  if (isType(action, deleteAddress.done)) {
    const { result, params } = action.payload
    const { addressId } = params
    const { addresses } = state
    const { deleteAddressResultMessage } = result

    let { activeAddress } = state

    if (addresses[addressId] && addresses[addressId] === activeAddress) {
      activeAddress = undefined
    }

    delete addresses[addressId]
    return {
      ...state,
      deleteAddressLoading: false,
      deleteAddressError: false,
      deleteAddressResultMessage,
      addresses,
      activeAddress,
    }
  }

  if (isType(action, deleteAddress.failed)) {
    return {
      ...state,
      deleteAddressLoading: false,
      deleteAddressError: true,
    }
  }

  if (isType(action, clearAddDeleteAddress)) {
    return {
      ...state,
      addAddressResultMessage: undefined,
      deleteAddressResultMessage: undefined,
    }
  }

  if (isType(action, logout)) {

    return {
      ...state,
      isLoadingLogout: true,
    }
  }

  return state
}
