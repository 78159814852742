import { useState } from "react"

export default function useSwipe(callback: (swipeDirection: any, distX: any, opacity: any) => void) {

  const [startTime, setStartTime] = useState(0)
  const [startX, setStartX] = useState(0)
  const [distX, setDistX] = useState(0)
  const [swipeDirection, setSwipeDirection] = useState("none")

  const minSwipeDistance = window.innerWidth / 2.5
  const allowedTime = 500

  const touchStart = (e: any) =>  {
    setStartX(e.changedTouches[0].pageX)
    setStartTime(new Date().getTime()) // record time when finger first makes contact with surface
  }

  const touchMove = (e: any) => {
    const currentX = e.changedTouches[0].pageX
    const currentDistance = Math.abs(currentX - startX)
    const elapsedTime = new Date().getTime() - startTime // get time elapsed
    const opacity = 1 -  (currentDistance / window.innerWidth)

    if (currentDistance <= window.innerWidth) {
      setDistX(currentX - startX)
    }

    if (elapsedTime <= allowedTime && currentDistance >= minSwipeDistance ) {
      if (distX < 0) {
        setSwipeDirection("left")
      } else {
        setSwipeDirection("right")
      }
    }

    callback(swipeDirection, distX, opacity)
  }

  const touchEnd = (e: any) => {
    setSwipeDirection("none")
    callback(swipeDirection, -1, -1)
  }

  return {
    onTouchStart: (e: any) => touchStart(e),
    onTouchMove: (e: any) => touchMove(e),
    onTouchEnd: (e: any) => touchEnd(e),
  }
}
