import React, { useState, useRef, useEffect, useCallback } from "react"

import { IQuestion } from "../../reducers/ReviewModels"
import SliderQuestion from "./SliderQuestion"
import TextQuestion from "./TextQuestion"

interface IOwnProps {
  getQuestionHeightCallback: (questionId: number, height: number) => void,
  onAnswerReceived: (question: string, answer: any) => void,
  question: IQuestion,
  dataId: number,
}

const ReviewItem: React.FC<IOwnProps> = (props) => {

  const { getQuestionHeightCallback, onAnswerReceived, dataId } = props

  const { id, question, answerType, answerSettings } = props.question

  const [answered, setAnswered] = useState(false)

  const ref = useRef(null) as any

  const onGetQuestionHeightCallback = useCallback(() => {
    getQuestionHeightCallback(id, ref.current.clientHeight)
  }, [ref, id])

  useEffect(() => {
    onGetQuestionHeightCallback()
  }, [onGetQuestionHeightCallback])

  const onAnswer = (currentQuestion: string, answer: any) => {
    setAnswered(true)
    onAnswerReceived(currentQuestion, answer)
  }

  return (
    <div ref={ref} style={answered ? { opacity: 0.4 } : {}} data-testid="reviewItem">
      <div className="reviewQuestionContainer">
        <p className="H2">{question}</p>
        {answerType === "slider" && (
          <SliderQuestion answerSettings={answerSettings} onAnswer={onAnswer} question={question} dataId={dataId} />
        )}
        {answerType === "text" && (
          <TextQuestion answerSettings={answerSettings} onAnswer={onAnswer} question={question} dataId={dataId} />
        )}
      </div>
    </div>
  )
}

export default ReviewItem
