import React from "react"

import "./styles/ReviewChooseReward.css"

import { IReward } from "../../reducers/ReviewModels"

import GradientButton from "../GradientButton"
import Loading from "../Loading"
import MultipleChoice from "./MultipleChoice"

interface IOwnProps {
  rewards: IReward[],
  chooseReward: (rewardId: number) => void,
  onSubmitAnswer: () => void,
  loading: boolean,
  chosenRewardId: number
  clearReview: () => void,
}

const ReviewChooseReward: React.FC<IOwnProps> = (props) => {

  const { rewards, chooseReward, onSubmitAnswer, loading, chosenRewardId, clearReview } = props

  return (
    <div
      className="reviewContainer"
      style={{ backgroundColor: "white", alignItems: "flex-start" }}
      data-testid="reviewChooseReward"
    >
      <div className="chooseRewardContent">

        <div className="chooseRewardHeader">
          <p className="H2" style={{ paddingTop: "20px" }}>Choose your reward</p>
          <div className="button closetHeaderInputClose" onClick={clearReview} data-testid="clearReviewReward">
            <img src="/images/icons/close-quiet.svg" alt="close" />
          </div>
        </div>

        <p className="small" style={{ margin: "25px 0" }}>
          Thank you for taking the time to complete the survey. Please choose your reward.
        </p>

        <MultipleChoice items={rewards} chooseItem={chooseReward} />

        <div className="chooseRewardButtonContainer">
          {chosenRewardId === 0 && (
            <p className=" small noChosenRewardText" data-testid="reviewChooseRewardWarning">
              Please choose reward
            </p>
          )}

          {! loading && (
            <GradientButton
              additionalClassName=""
              buttonText="SELECT REWARD AND COMPLETE"
              onClick={onSubmitAnswer}
              gradientClassName="gradientGreen"
              sizeClassName="gradientButtonBig"
              additionalButtonLabelClassName="alt"
            />
          )}

          {loading && (
            <Loading text="Saving your answers & reward..." small={false} />
          )
          }

        </div>
      </div>
    </div>
  )
}

export default ReviewChooseReward
