import React from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import "./styles/WashingListScreen.css"

import { RootState } from "../reducers"
import { clearWash, toggleWash } from "../reducers/ClosetActions"
import { cancelWash, removeFromWash } from "../reducers/WashingActions"
import { showAlert, showNotification } from "../reducers/NotificationActions"
import { IGarment, IGarmentShort } from "../reducers/GarmentModels"

import WashingHeaderInfo from "../components/washing/WashingHeaderInfo"
import Empty from "../components/Empty"
import WashingListItem from "../components/washing/WashingListItem"
import IconButton from "../components/IconButton"

interface IOwnProps {
  history: any,
}

interface IStateProps {
  garmentsForWashing: Array<IGarment & IGarmentShort>,
}

interface IDispatchProps {
  onClearWash: () => void,
  onCancelWash: () => void,
  onShowAlert: (alertTitle: string, alertBody: string, deleteFlag: boolean, onAccept?: () => void) => void,
  onRemoveFromWash: (garment: IGarment & IGarmentShort) => void,
  onToggleWash: (garmentCode: string, added: boolean) => void,
  onShowNotification: (notificationTitle: string, notificationBody: string) => void,
}

const WashingList: React.FC<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const {
    history, garmentsForWashing,
    onClearWash, onCancelWash, onShowAlert, onRemoveFromWash, onToggleWash, onShowNotification,
  } = props


  const onWashCancel = () => {
    onClearWash()
    onCancelWash()
  }

  const onGarmentClicked = (garment: IGarment & IGarmentShort) => {
    history.push("/garment", { garment: { ...garment, addedToWash: true } })
  }

  const onRemoveGarmentFromWash = (garment: IGarment & IGarmentShort) => {
    onRemoveFromWash(garment)
    const { garment_code, name } = garment
    onToggleWash(garment_code, true)
    onShowNotification(name, "Removed from wash")
  }

  const onFinishWash = () => {
    onWashCancel()
    history.push("/closet")
  }

  return (
    <div className="washingListMainContainer">

      <div className="headerView">
        <WashingHeaderInfo
          numOfItems={garmentsForWashing.length}
          onBackClicked={history.goBack}
          onOpenListClicked={undefined}
          onCloseClicked={() => onShowAlert(
            "Clear washing list",
            "Are you sure you want to clear the washing list?",
            false,
            onWashCancel,
          )}
        />
      </div>

      {garmentsForWashing.length === 0 && (
        <Empty
          image="/images/wash-empty.svg"
          title="No garments in wash."
          text="Use the in-app scanner, scan the garment labels, and add them to the wash."
          small={false}
          marginBottom={100}
        />
      )}

      {garmentsForWashing.length > 0 && (
        <div className="garmentListContainer">
          {garmentsForWashing.map((garment) => {
            return (
              <WashingListItem
                key={garment.garment_code}
                garment={garment}
                onGarmentClicked={onGarmentClicked}
                onRemoveGarmentFromWash={onRemoveGarmentFromWash}
              />
            )
          })}
        </div>
      )}

      <div className="washingListButtons">


        {/* <div className="gradientBlue washingListButton">
          <IconButton
            onClick={() => { }}
            additionalClassName=""
            additionalButtonLabelClassName="buttonLabel washingListButtonText"
            imgSource="/images/icons/target.svg"
            imgClassName=""
            buttonText="SCAN"
          />
        </div> */}


        {garmentsForWashing.length > 0 && (
          <div className="gradientBlue washingListButton">
            <IconButton
              onClick={onFinishWash}
              additionalClassName=""
              additionalButtonLabelClassName="buttonLabel washingListButtonText"
              imgSource="/images/icons/ok-alt.svg"
              imgClassName=""
              buttonText="FINISHED"
            />
          </div>
        )}

      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  garmentsForWashing: state.washing.garmentsForWashing,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onClearWash: () => dispatch(clearWash()),
    onCancelWash: () => dispatch(cancelWash()),
    onShowAlert: (alertTitle: string, alertBody: string, deleteFlag: boolean, onAccept?: () => void) => dispatch(
      showAlert({ alertTitle, alertBody, deleteFlag, onAccept }),
    ),
    onRemoveFromWash: (garment: IGarment & IGarmentShort) => dispatch(removeFromWash({ garment })),
    onToggleWash: (garmentCode: string, added: boolean) => dispatch(toggleWash({ garment_code: garmentCode, added })),
    onShowNotification: (notificationTitle: string, notificationBody: string) => dispatch(
      showNotification({ notificationTitle, notificationBody }),
    ),
  }
)

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WashingList) as any

