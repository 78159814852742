import React from "react"

interface IOwnProps {
  goBackAction: () => void,
  additionalClassName: string,
}

const BackButton: React.FC<IOwnProps> = (props) => {

  const { additionalClassName, goBackAction } = props

  return (
    <div className={`${additionalClassName} button`} onClick={goBackAction} data-testid="backButton">
        <img src="/images/icons/arrow-left.svg" alt="Go back" />
    </div>
  )
}

export default BackButton
