import React, { useEffect, useRef, useCallback } from "react"

import "./styles/ReviewGarmentPreview.css"

import { IGarment } from "../../reducers/GarmentModels"
import GarmentPhoto from "../GarmentPhoto"

interface IOwnProps {
  garment: IGarment,
  clearReview: () => void,
  getGarmentPreviewHeight: (height: number) => void,
}

const ReviewGarmentPreview: React.FC<IOwnProps> = (props) => {

  const { garment, clearReview, getGarmentPreviewHeight } = props

  const ref = useRef(null) as any

  const getGarmentPreviewHeightCallback = useCallback(() => {
    if (garment) {
      getGarmentPreviewHeight(ref.current.clientHeight)
    }
  }, [garment, ref])

  useEffect(() => {
    getGarmentPreviewHeightCallback()
  }, [getGarmentPreviewHeightCallback])

  if (! garment) {
    return null
  }
  const { name, photos, base_photo } = garment

  return (
    <div className="garmentPreviewContent" ref={ref} data-testid="reviewGarmentPreview">
      <GarmentPhoto
        onClick={undefined}
        photos={photos}
        photo={base_photo}
        additionalClassName=""
        additionalPhotoClassName="garmentPreviewImage"
      />
      <div className="small quiet garmentPreviewText">
        <p className="H2 black" style={{ paddingBottom: "4px" }} data-testid="reviewGarmentName">Review {name} </p>
        <p className="small quiet" data-testid="reviewGarmentMessage">Give us your feedback and earn our rewards.</p>
      </div>
      <div>
        <img data-testid="clearReview" src="/images/icons/close-quiet.svg" alt="Close" onClick={clearReview} />
      </div>
    </div>
  )
}

export default ReviewGarmentPreview
