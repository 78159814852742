import React, { useRef, useEffect, useCallback } from "react"

import "./styles/GarmentInformation.css"

import { IUser } from "../../reducers/UsersModels"
import { IGarment } from "../../reducers/GarmentModels"

import GradientButton from "../GradientButton"
import GarmentInformationReview from "./GarmentInformationReview"

interface IOwnProps {
  user?: IUser,
  garment: IGarment,
  action: string,
  iWantIt: () => void,
  onSetTabsHeight: (height: number) => void,
  onGetReview: (garmentId: number) => void,
  getReviewFetching: boolean,
  getReviewError: boolean,
  history: any,
}

export const INFORMATION_TAB = 0
export const CARE_TAB = 1
export const SUPPLY_CHAIN_TAB = 2

const GarmentInformation: React.FC<IOwnProps> = (props) => {

  const {
    user, garment, iWantIt, action,
    onSetTabsHeight, onGetReview, getReviewFetching, getReviewError,
    history,
  } = props

  const ref = useRef(null) as any

  const onSetTabsHeightCallback = useCallback(() => {
    onSetTabsHeight(ref.current.clientHeight)
  }, [ref, onSetTabsHeight])

  const onGetReviewCallback = useCallback(() => {
    if (action === "r") {
      onGetReview(garment.id)
    }
  }, [action, onGetReview, garment])

  useEffect(() => {
    onSetTabsHeightCallback()
  }, [onSetTabsHeightCallback])

  useEffect(() => {
    onGetReviewCallback()
  }, [onGetReviewCallback])

  const {
    id, garment_code, name, brand_name, brand_logo, description, type, color, size, made_in, style_number,
    upc, fabric_content, url, iWantItClicked,
  } = garment

  const passItOn = () => {
    history.push("/passItOn", { garment })
  }

  return (
    <div style={{ flexDirection: "column" }} ref={ref}>

      <div style={{ justifyContent: "center", backgroundColor: "white" }}>
        <img className="brandLogo" src={brand_logo} alt="brand-logo" data-testid="garmentInfoBrandLogo" />
      </div>

      <div className="garmentInformationSection column">
        <p className="H2" data-testid="garmentInfoName">{name}</p>
        <p className="small quiet" style={{ marginBottom: "8px" }} data-testid="garmentInfoBrandName">{brand_name}</p>
        <p className="body" style={{ flexDirection: "column" }}>
          {
            description && description.split("\n").map((item, key) => {
              return <span key={key} data-testid={`garmentInfoDescription${key}`}>{item}<br/></span>
            })
          }
        </p>

      </div>

      <div className="garmentInformationSection">
        <div className="sectionInRow">
          <p className="small quiet">Type</p>
          <p className="body" data-testid="garmentInfoType">{type}</p>
        </div>
        <div className="sectionInRow">
          <p className="small quiet">Color</p>
          <p className="body" data-testid="garmentInfoColor">{color}</p>
        </div>
      </div>

      <div className="garmentInformationSection">
        <div className="sectionInRow">
          <p className="small quiet">Size</p>
          <p className="body" data-testid="garmentInfoSize">{size}</p>
        </div>
        <div className="sectionInRow">
          <p className="small quiet">Made in</p>
          <p className="body" data-testid="garmentInfoMadeIn">{made_in}</p>
        </div>
      </div>
      <div className="garmentInformationSection">
        <div className="sectionInRow">
          <p className="small quiet">Style number</p>
          <p className="body" data-testid="garmentInfoStyleNumber">{style_number}</p>
        </div>
        <div className="sectionInRow">
          <p className="small quiet">UPC</p>
          <p className="body" data-testid="garmentInfoUpc">{upc}</p>
        </div>
      </div>
      <div className="garmentInformationSection column">
        <p className="small quiet">Fabric content</p>
        <p className="body" data-testid="garmentInfoFabricContent">{fabric_content}</p>
      </div>

      <GarmentInformationReview
        user={user}
        garmentId={id}
        garmentCode={garment_code}
        onGetReview={onGetReview}
        getReviewFetching={getReviewFetching}
        getReviewError={getReviewError}
      />

      {url && (
        <div className="garmentInfoBottom" style={{ marginTop: "3px" }}>
          <p className="small buttonText">Order this product from the brand's website.</p>
          <GradientButton
            buttonText="BUY IT"
            onClick={() => window.open(url, "_blank")}
            additionalClassName=""
            gradientClassName="gradientGreen"
            sizeClassName="gradientButtonSmall"
            additionalButtonLabelClassName="alt"
          />
        </div>
      )}

      {! url && ! iWantItClicked && (
        <div className="garmentInfoBottom" style={{ marginTop: "3px" }}>
          <p className="small buttonText">
            This garment has been discontinued. Please let us know if you still want it!
          </p>
          <GradientButton
            buttonText="I WANT IT"
            onClick={() => user ? iWantIt() : window.open("/login", "_self")}
            additionalClassName=""
            gradientClassName="gradientGreen"
            sizeClassName="gradientButtonSmall"
            additionalButtonLabelClassName="alt"
          />
        </div>
      )}

      {iWantItClicked && (
        <div className="garmentInfoBottom" style={{ marginTop: "3px" }} data-testid="iWantItClicked">
          <p className="small buttonText" data-testid="iWantItClickedMessage">
            Thank you for showing your interest in this item!
            Our customer service will get back you as soon as possible.
          </p>
          <div>
            <img src="/images/icons/ok-tertiary.svg" alt="I want it clicked" />
          </div>
        </div>
      )}

      <div className="garmentInfoBottom" style={{ marginTop: 3 }}>
        <div style={{ flex: 3, flexDirection: "column" }}>
          <p className="small quiet">
            Extend the life of this garment
          </p>
          <p className="small">
            Resell, recycle or donate, and earn a coupon.
          </p>
        </div>
        <GradientButton
          buttonText="PASS IT ON"
          onClick={passItOn}
          additionalClassName=""
          gradientClassName="gradientGreen"
          sizeClassName="gradientButtonSmall"
          additionalButtonLabelClassName="alt"
        />
      </div>
    </div>
  )
}

export default GarmentInformation
